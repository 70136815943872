
import {NHVirtualForm} from '../../../../../utils/NHVirtualForm.js';
import {message} from 'antd';
let md5 = require("md5");

/**
 * 网上支付办理接口
 * 请自行在此处判断需要的参数是否符合要求，例如判断身份证件号是否为空，Toast.hide()，可以使用改方法关闭准备跳转支付界面提示
 *
 * @param {*} ddh 订单号
 * @param {*} csxx 配置参数信息，包含：pczflj(PC端支付路径),ydzflj(移动端支付路径)，zfts(支付提示),所有的自定义配置的参数信息
 * @param {*} xsxx 学生信息，包含：XSID(学生ID),XH(学号),XM(姓名),XBM(性别码),SFZJH(身份证件号),RXNF(入学年份),CSRQ(出生日期)
 * @param {*} jfxm 缴费信息，LIst<Map<String,Object>>对象,包含：sfxmid(收费项目ID),sfxmdm(收费项目代码),sfxmmc(收费项目名称)，je(金额)
 * @param {*} jyje 交易金额
 * @param {*} yjje 应缴金额
 * @param {*} ysje 已缴金额
 * @param {*} hjje 缓缴金额
 */
export const handle = (ddh,csxx,xsxx,jfxm,jyje,yjje,ysje,hjje) => {
  
  let xh=xsxx.XH;//学号
  
  let key=csxx.key;//签名
  let tm=getNowFormatDate();
  let url=csxx.dddlljdz;//单点登录连接地址
 
  if(!url){
    Toast.hide();
    Toast.info("支付路径不能为空，请联系管理员！");
    return;
  }
  if(!xh){
    Toast.hide();
    Toast.info("学号不能为空，请联系管理员！");
    return;
  }
  if(!key){
    Toast.hide();
    Toast.info("参数不能为空，请联系管理员！");
    return;
  }
  let sign=md5(xh+getNowFormatDate()+key);
  //虚拟表单提交
  let map={

    xh:xh,
    tm:tm,
    sign:sign
  }
  
  NHVirtualForm(url,"_blank",map);


}

/**
 * 点击同步按钮触发时的操作
 * @param {*} ddh 订单号
 */
export const syscBtnFunc = (ddh) => {

}


//获取当前日志，格式yyyyMMdd
function getNowFormatDate() {
  var date = new Date();
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var hours = date.getHours(); //获取系统时，
var minutes = date.getMinutes(); //分
var seconds = date.getSeconds(); //秒
  var strDate = date.getDate();

  if (month >= 1 && month <= 9) {
      month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
      strDate = "0" + strDate;
  }
  if (hours >= 0 && hours <= 9) {
      hours = "0" + hours;
  }
  if (minutes >= 0 && minutes <= 9) {
      minutes = "0" + minutes;
  }
  if (seconds >= 0 && seconds <= 9) {
      seconds = "0" + seconds;
  }
  var currentdate = year  + month  + strDate + hours + minutes + seconds;;
  return currentdate;
}
