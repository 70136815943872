
import React from 'react';
import {message, Modal} from 'antd';
import NHFetch from "../../../../../utils/NHFetch";

/**
 * 网上支付办理接口
 * 请自行在此处判断需要的参数是否符合要求，例如判断身份证件号是否为空，Toast.hide()，可以使用改方法关闭准备跳转支付界面提示
 *
 * @param {*} ddh 订单号
 * @param {*} csxx 配置参数信息，包含：pczflj(PC端支付路径),ydzflj(移动端支付路径)，zfts(支付提示),所有的自定义配置的参数信息
 * @param {*} xsxx 学生信息，包含：XSID(学生ID),XH(学号),XM(姓名),XBM(性别码),SFZJH(身份证件号),RXNF(入学年份),CSRQ(出生日期)
 * @param {*} jfxm 缴费信息，LIst<Map<String,Object>>对象,包含：sfxmid(收费项目ID),sfxmdm(收费项目代码),sfxmmc(收费项目名称)，je(金额)
 * @param {*} jyje 交易金额
 * @param {*} yjje 应缴金额
 * @param {*} ysje 已缴金额
 * @param {*} hjje 缓缴金额
 */
export const handle = (csxx,xsxx,jfxm,jyje,yjje,ysje,hjje) => {
  let url=csxx.pczflj;//跳转URL
  let appid = csxx.appid;
  let appkey = csxx.appkey;
  let version = csxx.version;
  let fiscal = csxx.fiscal;
  let xm = xsxx.XM;
  let sfzjh = xsxx.SFZJH;
  if(!url){
    message.warning("支付路径不能为空，请联系管理员！");
    return;
  }
  if(!sfzjh){
    message.warning("身份证件号不能为空，请联系管理员！");
    return;
  }
  if(!xm){
    message.warning("姓名不能为空，请联系管理员！");
    return;
  }
  let map = {
    appid:appid,
    appkey:appkey,
    version:version,
    sfzjh: sfzjh,
    xm: xm, 
    fiscal: fiscal,
    url: url
  };
  NHFetch("/openapi/yxwz/getJfUrl", "POST", map).then(res => {
    if(res) {
      if(res && res.data != null) {
        // window.open(res.data, "_blank");
        NHFetch("/openapi/yxwz/generateQrcode", "GET", {"url": res.data}).then(res => {
          if(res) {
            if(res && res.data != null) {
              let qrcode = "data:image/jpg;base64," + res.data;
              console.log("qrcode===" + qrcode);
              Modal.info({
                title: "请扫描二维码进行缴费",
                content:(
                  <div>
                    <img src={qrcode} width={300} height={300} />
                  </div>
                ),
              })
            }else {
              message.warning("生成缴费二维码失败！");
            }
          }
        })
      }else {
        message.warning("获取缴费路径失败！");
      }
    }
  })
}
