import React from 'react';
import { Result } from 'antd';
import NHFetch from "@utils/NHFetch";

export default class QrCodeIndex extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            qrcode:'',
        }
    }

    componentDidMount(){
        this.getDetailed();
    }

    getDetailed =() =>{
        const { jccsData } = this.props;
        NHFetch('api/zhxg-yxwz/dynamic/'+jccsData.qrcode+'/getDetailed','GET').then(res =>{
            if (res && res.data){
                this.setState({
                    qrcode: res.data[0].uuid
                })
            } else {
                message.error("获取二维码失败");
            }
        })
    }

    render() {
        const { qrcode } = this.state;
        return (
            <div style={{display:'flex',justifyContent:'center'}}>
                <div className="result-example">
                    {
                        qrcode ?
                            <Result
                                icon={<img src={"/anonymity/docrepo/download/file?attachmentId="+qrcode} className="spe" style={{ fill: '#1F90E6' }} />}
                                title={"请扫描二维码，登录收费系统进行缴费"}
                            />
                            :
                            null
                    }
                </div>
                <style jsx="true" global="true">
                    {`
                          .result-example .spe {
                            width: 160px;
                            height: 160px;
                          }
                         .am-result .am-result-pic{
                            width: 160px;
                            height: 160px;
                         }
                    `}
                </style>
            </div>
        );
    }
}
