import React from 'react'
import { Link } from 'react-router-dom'
import { Flex } from '@components'
import { Form, DatePicker, Input, InputNumber, Button, Select, Table, Icon, TimePicker } from 'antd'
import SiderMenu from '@pages/Assembly/siderMenu'
import Breadcrumb from '@pages/Assembly/Breadcrumb'
import TitleTop from '@pages/Assembly/titleTop'
import NHFetch from '@utils/NHFetch'
import { message } from 'antd/lib/index'
import moment from 'moment'
import NHSelect from '@components/common/NHSelect'
import { createUuid, getLoginUser } from '@utils/NHCore'
import NextButton from '@pages/SelfHelp/common/nextIndex'
// import 'moment/src/locale/zh-cn';
import createHistory from 'history/createHashHistory'
import CommonTip from '../common'
import { mkbz } from '@pages/Assembly/siderMenu/menuObj'
import Hint from '@pages/SelfHelp/common/hint'
const history = createHistory()
const format = 'HH:mm'

const basicUrl = '/zzbd'
const commonUrl = '/zzbdCommon'

const FormItem = Form.Item
const Option = Select.Option

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}

@Form.create()
export default class Report extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mkbz: 'GD004_3',
      hjmc: '',
      editable: true,
      update: false,
      initPOvalue: {
        nd: '',
        xsid: '',
        sfasbd: '',
        basbdyy: '',
        jtgj: '',
        ddz: '',
        dxsj: '',
        bc: '',
        ptrs: 0
      },
      value: null,
      lxjtgjmcList: {},
      lxjtgjmc: '',
      ddz: {},
      options: [],
      dqddz: '',
      nextUrl: '', //左侧菜单加载完后传进来
      search: '',
      operable: true, //是否显示环节
      nonOperationalData: {}, //不可操作的数据
      isHide: true,
      isHideDetail: false,
      wbdyy: '', //未报到原因
      wbdyyList: {},
      jzqkList: {},
      isHideReason: false
    }
  }

  componentWillMount() {
    this.getDmkMc('YXWZ_LXJTGJ')
    this.getDmkMc('YXXT_BDQK_XGYMJZQK')
    this.getDmkMc('YXXT_BDQK_WBDYY')
  }
  //渲染前获取信息
  componentDidMount() {
    this.queryQztjAndYlhj()
    this.getDdz()
    this.getQkpo()
  }

  //查询前置条件和依赖环节
  queryQztjAndYlhj = () => {
    let xsid = getLoginUser() ? getLoginUser().userId : ''
    if (!xsid) {
      this.setState({
        nonOperationalData: { nologin: true },
        operable: false
      })
      return
    }
    let zzbdhjid = this.getQueryString('pkid')
    NHFetch('/zzbd/queryQztjAndYlhj', 'GET', { xsid: xsid, zzbdhjid: zzbdhjid }).then(res => {
      if (res && res.data) {
        let kfsj = res.data['kfsj']
        let ylhj = res.data['ylhj']
        let qzhj = res.data['qzhj']
        let nohj = res.data['nohj']
        this.setState({ nonOperationalData: res.data })
        if (kfsj && kfsj['YXSJ'] != '1') {
          this.setState({ operable: false })
        }

        if (ylhj && ylhj.length > 0) {
          this.setState({ operable: false })
        }

        if (qzhj && qzhj.length > 0) {
          this.setState({ operable: false })
        }
        if (nohj) {
          this.setState({ operable: false })
        }
      }
    })
  }

  getQueryString = name => {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
  }

  setMenuList = menuList => {
    let nextCount = 0
    let bz = this.state.mkbz
    menuList.map((elem, index) => {
      if (elem.HJDM == bz) {
        let hjmc = elem.HJMC
        this.setState({ hjmc: hjmc })
        nextCount = index + 1
        return
      }
    })
    let menu = menuList[nextCount]
    if (menu) {
      let hjdm = menu.HJDM
      if (hjdm.indexOf('GD') == 0) {
        let gdUrl = mkbz[hjdm]
        this.setState({
          nextUrl: gdUrl,
          search: 'pkid=' + menu.PKID
        })
      } else if (hjdm.indexOf('ZDY') == 0) {
        let zdyUrl = '/customLink'
        this.setState({
          nextUrl: zdyUrl,
          search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX
        })
      }
    } else {
      this.setState({
        nextUrl: '',
        search: ''
      })
    }
  }

  getQkpo() {
    const xsid = getLoginUser().userId
    let lxjtgjmc = ''
    NHFetch(basicUrl + '/getQkpoByXsidAndNd', 'GET', { xsid, xsid }).then(res => {
      if (res) {
        if (res.data) {
          console.log(res.data)
          lxjtgjmc = res.data.jtgjmc
          this.setState({ initPOvalue: res.data, editable: false, lxjtgjmc: lxjtgjmc })
          let dqddz = this.state.initPOvalue.ddz
          this.onChange(this.state.initPOvalue.jtgj)
          this.setState({ dqddz: dqddz, isHide: res.data.sfasbd == 1 ? false : true, isHideReason: res.data.sffhrxbdtj == 1 ? false : true })
          if (res.data.sfasbd == 0 && '延迟报到' == res.data.wbdyy) {
            this.showDetail('ycbd')
          }
        }
      }
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return
      }
      let xgymjzqk = values.xgymjzqk
      let jzqkList = this.state.jzqkList
      for (let i = 0; i < jzqkList.length; i++) {
        if (xgymjzqk == jzqkList[i].MC) {
          values.xgymjzqk = jzqkList[i].DM
        }
      }
      let wbdyy = values.wbdyy
      let wbdyyList = this.state.wbdyyList
      for (let i = 0; i < wbdyyList.length; i++) {
        if (wbdyy == wbdyyList[i].MC) {
          values.wbdyy = wbdyyList[i].DM
        }
      }

      let sUrl = '/saveBdqkData'
      if (this.state.update) {
        sUrl = '/updateBdqkData'
      }
      //修改 可编辑状态，并可以update数据
      if (!this.state.editable) {
        this.setState({ update: true, editable: true })
        return
      }
      const { dxsj } = values
      let dxsjVal = moment(dxsj).format('YYYY-MM-DD HH:mm')
      values.dxsj = dxsjVal
      const xsid = getLoginUser().userId
      const zzbdhjid = this.getUrlParams('pkid')
      let params = { xsid: xsid, zzbdhjid: zzbdhjid, formData: values }

      console.log(params)
      // 请求保存或修改数据
      NHFetch(basicUrl + sUrl, 'POST', params).then(res => {
        if (res) {
          if (res.data == '1') {
            message.success('信息保存成功！')
            this.reloadMenu()
            this.getQkpo()
          } else {
            message.error('信息保存失败，请重试！')
          }
        }
      })
    })
  }

  //获取url中传递的参数
  getUrlParams = param => {
    return decodeURIComponent((new RegExp('[?|&]' + param + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
  }

  //获取下拉列表value对应的名称
  getDmkMc = dmbz => {
    NHFetch(basicUrl + '/getDmkMc', 'GET', { dmbz, dmbz }).then(res => {
      if (res) {
        if (res.data) {
          if (dmbz === 'YXWZ_LXJTGJ') {
            this.setState({ lxjtgjmcList: res.data })
          }
          if (dmbz === 'YXXT_BDQK_WBDYY') {
            this.setState({ wbdyyList: res.data })
          }
          if (dmbz === 'YXXT_BDQK_XGYMJZQK') {
            this.setState({ jzqkList: res.data })
          }
        }
      }
    })
  }
  //获取到达站下拉列表的名称
  getDdz = () => {
    NHFetch(basicUrl + '/getDdz', 'GET').then(res => {
      if (res) {
        if (res.data) {
          this.setState({ ddz: res.data })
        }
      }
    })
  }

  //到达站级联
  DdzSeleOption = value => {
    let dqddz = ''
    let coptions = ''
    if (this.state.ddz.length > 0) {
      coptions = this.state.ddz.map((item, key) => {
        if (value === item.ZTLX) {
          dqddz = item.ZTMC
          return <Option key={item.ZTMC}>{item.ZTMC}</Option>
        } else {
          return null
        }
      })
    }
    this.props.form.setFieldsValue({ ddz: dqddz })
    this.setState({ options: coptions, dqddz: dqddz })
  }

  //下拉框发生变化检测
  onChange = value => {
    this.DdzSeleOption(value)
  }

  showDetail = value => {
    if (value === 'ycbd') {
      this.setState({
        isHideDetail: true
      })
    } else {
      this.setState({
        isHideDetail: false
      })
    }
  }

  //动态显示报到情况填写
  onSfasbdChange = value => {
    this.setState({
      isHide: value == '1' ? false : true,
      isHideDetail: value == '1' ? false : true
    })
  }

  showReason = v => {
    if (v == 0) {
      this.setState({
        isHideReason: true
      })
    } else {
      this.setState({ isHideReason: false })
    }
  }

  //重新加载菜单
  reloadMenu = () => {
    this.refs.siderMenu.reloadMenuList()
  }

  //陪同人数不符合时提示
  numberChange = value => {
    if (value > 10 || value < 0) {
      message.warning('陪同人数请填写0-10之内的整数！')
    }
  }

  //disabledStartDate设置到校日期的时间选择器的可选日期区域
  disabledStartDate = startValue => {
    return startValue && startValue < moment().subtract(1, 'days')
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { editable, initPOvalue, lxjtgjmc, options, dqddz, isHide, isHideDetail, wbdyyList, jzqkList, isHideReason } = this.state
    var wbdyy = ''
    var jzqk = ''
    for (let i = 0; i < wbdyyList.length; i++) {
      if (wbdyyList[i].DM == initPOvalue.wbdyy) {
        wbdyy = wbdyyList[i].MC
      }
    }
    for (let i = 0; i < jzqkList.length; i++) {
      if (jzqkList[i].DM == initPOvalue.xgymjzqk) {
        jzqk = jzqkList[i].MC
      }
    }

    return (
      <div>
        {this.state.operable ? (
          <Flex className="w1180 mt-20" align="start">
            <SiderMenu
              ref={'siderMenu'}
              setMenuList={this.setMenuList}
              title="自助报到"
              /* active={3}*/
              mkbz={this.state.mkbz}
              menuType="自助报到"
            />
            <Flex.Item className="page_con_r" styleName="">
              <Breadcrumb noLine list={[{ name: '首页', url: '/index' }, { name: '自助报到', url: '/register' }, { name: this.state.hjmc }]} />
              <div className="box">
                <Hint />
                <TitleTop title={this.state.hjmc} extra={false} />
                <Form onSubmit={this.handleSubmit}>
                  <div className="p-sw">
                    <Flex align="start" wrap="wrap">
                      <div style={{ display: 'inline-block', verticalAlign: 'top', width: '40%' }}>
                        <FormItem {...formItemLayout} label="是否按时报到">
                          {getFieldDecorator('sfasbd', {
                            initialValue: initPOvalue.sfasbd,
                            placeholder: '请选择',
                            rules: [
                              {
                                required: true,
                                message: '请选择是否按时报到'
                              }
                            ]
                          })(
                            editable ? (
                              <Select placeholder="请选择" onChange={this.onSfasbdChange}>
                                <Option value="1">是</Option>
                                <Option value="0">否</Option>
                              </Select>
                            ) : (
                              <span>{initPOvalue.sfasbd == 1 ? '是' : '否'}</span>
                            )
                          )}
                        </FormItem>
                      </div>

                      <div style={{ display: 'inline-block', verticalAlign: 'top', width: '60%' }}>
                        <FormItem {...formItemLayout} label="是否符合入学报到条件">
                          {getFieldDecorator('sffhrxbdtj', {
                            initialValue: initPOvalue.sffhrxbdtj,
                            placeholder: '请选择',
                            rules: [
                              {
                                required: true,
                                message: '请选择是否'
                              }
                            ]
                          })(
                            editable ? (
                              <Select placeholder="请选择" onChange={v => this.showReason(v)}>
                                <Option value="1">是</Option>
                                <Option value="0">否</Option>
                              </Select>
                            ) : (
                              <span>{initPOvalue.sffhrxbdtj == 1 ? '是' : '否'}</span>
                            )
                          )}
                        </FormItem>
                      </div>

                      {isHideReason && (
                        <div className="form_item">
                          <FormItem {...formItemLayout} label="不符合入学报到原因">
                            {getFieldDecorator('bfhrxbdyy', { initialValue: initPOvalue.bfhrxbdyy })(
                              editable ? <Input placeholder="请填写原因" /> : <span>{initPOvalue.bfhrxbdyy}</span>
                            )}
                          </FormItem>
                        </div>
                      )}

                      <div className="form_item">
                        <FormItem {...formItemLayout} label="是否接种新冠疫苗">
                          {getFieldDecorator('xgymjzqk', {
                            initialValue: jzqk,
                            placeholder: '请选择',
                            rules: [
                              {
                                required: true,
                                message: '请选择是否接种新冠疫苗'
                              }
                            ]
                          })(editable ? <NHSelect sign={'DMK_YXXT_BDQK_XGYMJZQK'} /> : <span>{jzqk}</span>)}
                        </FormItem>
                      </div>

                      {isHide && (
                        <div className="form_item">
                          <FormItem {...formItemLayout} label="未报到原因">
                            {getFieldDecorator('wbdyy', { initialValue: wbdyy })(
                              editable ? <NHSelect sign={'DMK_YXXT_BDQK_WBDYY'} onChange={v => this.showDetail(v)} /> : <span>{wbdyy}</span>
                            )}
                          </FormItem>
                        </div>
                      )}

                      {isHideDetail && (
                        <div className="form_item">
                          <FormItem {...formItemLayout} label="不按时报到原因">
                            {getFieldDecorator('basbdyy', { initialValue: initPOvalue.basbdyy })(
                              editable ? <Input placeholder="请填写原因" /> : <span>{initPOvalue.basbdyy}</span>
                            )}
                          </FormItem>
                        </div>
                      )}
                      {isHideDetail && (
                        <div className="form_item">
                          <FormItem {...formItemLayout} label="延迟时间(天)">
                            {getFieldDecorator('ycsj', { initialValue: initPOvalue.ycsj })(editable ? <Input placeholder="请填写延迟天数" /> : <span>{initPOvalue.ycsj}</span>)}
                          </FormItem>
                        </div>
                      )}
                      {isHideDetail && (
                        <div className="form_item">
                          <FormItem {...formItemLayout} label="是否符合请假手续">
                            {getFieldDecorator('sffhqjsx', { initialValue: initPOvalue.sffhqjsx })(
                              editable ? (
                                <Select placeholder="请选择">
                                  <Option value="1">是</Option>
                                  <Option value="0">否</Option>
                                </Select>
                              ) : (
                                <span>{initPOvalue.sffhqjsx == 1 ? '是' : '否'}</span>
                              )
                            )}
                          </FormItem>
                        </div>
                      )}

                      {!isHide && (
                        <div className="form_item">
                          <FormItem {...formItemLayout} label="来校交通工具">
                            {getFieldDecorator('jtgj', {
                              initialValue: initPOvalue.jtgj,
                              rules: [
                                {
                                  required: true,
                                  message: '请选择来校交通工具'
                                }
                              ]
                            })(
                              editable ? (
                                <NHSelect
                                  sign={'zhxg_yxwz_lxjtgj'}
                                  onChange={v => {
                                    this.onChange(v)
                                  }}
                                />
                              ) : (
                                <span>{lxjtgjmc}</span>
                              )
                            )}
                          </FormItem>
                        </div>
                      )}
                      {!isHide && (
                        <div className="form_item">
                          <FormItem {...formItemLayout} label="到达站">
                            {getFieldDecorator('ddz', {
                              initialValue: dqddz,
                              rules: [
                                {
                                  message: '请选择到达站'
                                }
                              ]
                            })(editable ? <Select>{options}</Select> : <span>{dqddz}</span>)}
                          </FormItem>
                        </div>
                      )}
                      {!isHide && (
                        <div className="form_item">
                          <FormItem {...formItemLayout} label="到校日期">
                            {getFieldDecorator('dxsj', {
                              initialValue: initPOvalue.dxsj == '' ? null : moment(initPOvalue.dxsj, 'YYYY-MM-DD HH:mm'),
                              rules: [
                                {
                                  // required: true,
                                  required: editable,
                                  message: '请选择到校日期'
                                }
                              ]
                            })(
                              editable ? (
                                <DatePicker
                                  style={{ width: '100%' }}
                                  showTime
                                  format="YYYY-MM-DD HH:mm"
                                  disabledDate={this.disabledStartDate}
                                  // defaultValue={initPOvalue.dxsj==''?null:moment(initPOvalue.dxsj, 'YYYY-MM-DD HH:mm')} //如果时间发生错误，请删除此句defaultValue
                                />
                              ) : (
                                <span>{initPOvalue.dxsj}</span>
                              )
                            )}
                          </FormItem>
                        </div>
                      )}
                      {!isHide && (
                        <div className="form_item">
                          <FormItem {...formItemLayout} label="班次">
                            {getFieldDecorator('bc', { initialValue: initPOvalue.bc })(editable ? <Input /> : <span>{initPOvalue.bc}</span>)}
                          </FormItem>
                        </div>
                      )}
                      {!isHide && (
                        <div className="form_item">
                          <FormItem {...formItemLayout} label="陪同人数">
                            {getFieldDecorator('ptrs', { initialValue: initPOvalue.ptrs })(
                              editable ? <InputNumber precision={0} min={0} max={10} onChange={this.numberChange} /> : <span>{initPOvalue.ptrs}</span>
                            )}
                            &nbsp;&nbsp;<span> (人) </span>
                          </FormItem>
                        </div>
                      )}
                    </Flex>
                    <div className="text-center mt-50">
                      <Button type="primary" htmlType="submit">
                        {editable ? '保存' : '修改'}
                      </Button>
                      <NextButton nextUrl={this.state.nextUrl} search={this.state.search} />
                    </div>
                  </div>
                </Form>
              </div>
            </Flex.Item>
          </Flex>
        ) : (
          <CommonTip hjmc={'报到情况填写'} key={'GD004_3'} mkbz="GD004_3" nonOperationalData={this.state.nonOperationalData} />
        )}
      </div>
    )
  }
}
