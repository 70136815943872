import React from 'react'
import { Flex } from '@components'
import { Form, Upload, message } from 'antd'
import SiderMenu from '@pages/Assembly/siderMenu'
import Breadcrumb from '@pages/Assembly/Breadcrumb'
import TitleTop from '@pages/Assembly/titleTop'
import NHFetch from '../../../../utils/NHFetch'
import './index.css'
import { getLoginUser } from '@utils/NHCore'
import CommonTip from '../common'
import { mkbz } from '@pages/Assembly/siderMenu/menuObj'
import Hint from '@pages/SelfHelp/common/hint'
import NextButton from '@pages/SelfHelp/common/nextIndex'
import defaultImg from '../../../../../assets/images/default.png'

const uploadUrl = 'api/zhxg-yxwz/newStudent/cacheStudentPhoto'

class SinglePhotoForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      imageUrl: defaultImg,
      mkbz: 'GD015_3',
      hjmc: '',
      nonOperationalData: {}, // 不可操作的数据
      operable: true, // 是否显示环节
      nextUrl: '', // 左侧菜单加载完后传进来
      search: '',
      shzt: null, //照片的审核状态
      shyj: '',
      shztMap: {
        '-1': '审核不通过',
        '0': '等待审核',
        '1': '审核通过',
        '9': '驳回',
      }
    }
  }
  componentDidMount() {
    this.checkIsZzbdHjLinked()
    this.queryQztjAndYlhj()
    this.getStudentPhotoBase64()
  }

  // 查询前置条件和依赖环节
  queryQztjAndYlhj = () => {
    let xsid = getLoginUser() ? getLoginUser().userId : ''
    if (!xsid) {
      this.setState({
        nonOperationalData: { nologin: true },
        operable: false
      })
      return
    }
    let zzbdhjid = this.getQueryString('pkid')
    NHFetch('/zzbd/queryQztjAndYlhj', 'GET', { xsid: xsid, zzbdhjid: zzbdhjid }).then(res => {
      if (res && res.data) {
        let kfsj = res.data['kfsj']
        let ylhj = res.data['ylhj']
        let qzhj = res.data['qzhj']
        let nohj = res.data['nohj']
        this.setState({ nonOperationalData: res.data })
        if (kfsj && kfsj['YXSJ'] !== '1') {
          this.setState({ operable: false })
        }
        if (ylhj && ylhj.length > 0) {
          this.setState({ operable: false })
        }
        if (qzhj && qzhj.length > 0) {
          this.setState({ operable: false })
        }
        if (nohj) {
          this.setState({ operable: false })
        }
      }
    })
  }

  // 环节pkid
  getQueryString = name => {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
  }

  // 检查该环节是否已经办理
  checkIsZzbdHjLinked = () => {
    let xsid = getLoginUser() ? getLoginUser().userId : ''
    let zzbdhjid = this.getQueryString('pkid')

    NHFetch('/zzbdCommon/checkIsZzbdHjLinked', 'GET', { xsid: xsid, zzbdhjid: zzbdhjid }).then(res => {
      if (res) {
        if (res.data) {
          this.setState({
            editable: false
          })
        } else {
          this.setState({
            editable: true
          })
        }
      } else {
        this.setState({
          editable: true
        })
      }
    })
  }

  // 设置左侧菜单
  setMenuList = menuList => {
    let nextCount = 0
    let bz = this.state.mkbz
    menuList.map((elem, index) => {
      if (elem.HJDM == bz) {
        let hjmc = elem.HJMC
        this.setState({ hjmc: hjmc })
        nextCount = index + 1
        return
      }
    })
    let menu = menuList[nextCount]
    if (menu) {
      let hjdm = menu.HJDM
      if (hjdm.indexOf('GD') == 0) {
        let gdUrl = mkbz[hjdm]
        this.setState({
          nextUrl: gdUrl,
          search: 'pkid=' + menu.PKID
        })
      } else if (hjdm.indexOf('ZDY') == 0) {
        let zdyUrl = '/customLink'
        this.setState({
          nextUrl: zdyUrl,
          search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX
        })
      }
    } else {
      this.setState({
        nextUrl: '',
        search: ''
      })
    }
  }

  // 获取url中传递的参数
  getUrlParams = param => {
    return decodeURIComponent((new RegExp('[?|&]' + param + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
  }

  // 重新加载菜单
  reloadMenu = () => {
    this.refs.siderMenu.reloadMenuList()
  }

  // 获取学生头像
  getStudentPhotoBase64 = () => {
    let userId = getLoginUser() ? getLoginUser().userId : undefined
    if (userId) {
      NHFetch('/gxstgc/zpsc/getZpInfoByXsid', 'get', { loginUserId: userId }).then(res => {
        if(res.data) {
            this.setState({
                xsid: userId,
                imageUrl: `data:image/jpeg;base64,${res.data?.zp}`,
                shzt: res.data?.shzt,
                shyj: res.data?.shyj
              })
      
              if(res.data.shzt === '9') {
                  this.setState({editable: true})
              }
            }
      })
    }
  }

  beforeUpload = file => {
    const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJPG) {
      message.error('只能上传.png,.jpg,.jpeg格式的文件')
    }
    const isLt2M = file.size / 1024 / 1024 < 1
    if (!isLt2M) {
      message.error('上传的图像必须小于1MB')
    }
    return isJPG && isLt2M
  }

  getBase64(img, callback) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  // 上传图片
  handleUploadChange = info => {
    if (info && info['file'] && info['file'].status === 'done') {
      let photoUUID = info['file'].response.data
      if (photoUUID) {
        this.setState({
          photoUUID
        })
      }
    }
    this.getBase64(info.file.originFileObj, imageUrl => this.setState({ imageUrl }))
  }

  onSaveTxscFormData = (callback) => {
    let userId = getLoginUser() ? getLoginUser().userId : undefined
    let photoUUID = this.state.photoUUID
    if (photoUUID) {
      let url = '/gxstgc/zpsc/saveZp?xsid=' + userId + '&photoUUID=' + photoUUID 
      NHFetch(url, 'POST').then(res => {
        if (res && res.data) {
          this.setState({
            uploadImg: false
          })
          this.handleConfirmSubmit(callback)
        }
      })
    }
  }

  handleConfirmSubmit = (callback) => {
    let xsid = getLoginUser() ? getLoginUser().userId : ''
    let zzbdhjid = this.getUrlParams('pkid')
    let params = { xsid: xsid }
    NHFetch('/gxstgc/zpsc/insertOrUpdateZpInfo', 'POST', params).then(res => {
      if (res) {
        if (res.data === 1) {
          callback(true)
          message.success('信息保存成功！');
          this.getStudentPhotoBase64()
          this.reloadMenu();
        }
      } else {
        message.error('环节办理失败，请稍后重试！')
      }
    })
  }

  render() {
    const { editable, imageUrl, shzt, shyj, shztMap, uploadImg } = this.state
    const userId = getLoginUser().userId
    
    return (
      <div styleName="page_buy">
        {this.state.operable ? (
          <Flex className="w1180 mt-20" align="start">
            <SiderMenu ref={'siderMenu'} setMenuList={this.setMenuList} title="自助报到" mkbz={this.state.mkbz} menuType="自助报到" />
            <Flex.Item className="page_con_r">
              <Breadcrumb noLine list={[{ name: '首页', url: '/index' }, { name: '自助报到', url: '/register' }, { name: this.state.hjmc }]} />
              <div className="box">
                <Hint />
                <TitleTop
                  title={this.state.hjmc}
                  extra={editable || shzt == '0'}
                  onEdit={type => {
                    this.setState({ 
                      uploadImg: true
                    })
                  }}
                  onSave={(callback) => {
                    this.onSaveTxscFormData(callback)
                  }}
                  onClose={() => {
                    this.setState({
                      imageUrl: this.state.imageUrl,
                      uploadImg: false
                    })
                  }}
                />
                <Form layout="inline">
                  <div styleName="txsc" className="_bg">
                    {(editable ||shzt == '0') && uploadImg ? (
                      <Upload name="photo" accept='image/png,image/jpeg,image/jpg,image/bmp' showUploadList={false} action={uploadUrl + '?xsid=' + userId} beforeUpload={this.beforeUpload} onChange={this.handleUploadChange}>
                        {imageUrl ? (
                          <img src={imageUrl} alt="" className="avatar" style={{cursor: 'pointer'}}/>
                        ) : (
                          <div styleName="upload-box" style={{cursor: 'pointer'}}>
                            <div styleName="upload-title">上传图片</div>
                          </div>
                        )}
                      </Upload>
                    ) : (
                      <img src={imageUrl} alt="" className="avatar" />
                    )}
                  </div>
                </Form>
                <div style={{padding: '0 24px'}}>
                    <p>上传照片的要求：</p>
                    <p>1、文件格式只能为jpg、jpeg、bmp、png。</p>
                    <p>2、要求大小1M以下，规格为小2寸，符合证件照片的高宽比例（1:1.2~1.6）。</p>
                    <p>3、拍照时穿着有衣领有袖子的衣服，可以画淡妆、打领带、不准带耳环、项链等饰品，不能染彩色头发，头发不能盖眼眉，必须在照相前整理好着装。</p>
                    <p>4、如不按要求上传照片，后台审核不通过，导致打印的报到单无法正常办理学籍，造成后果自负。</p>
                    {shzt && <h3 style={{fontWeight: 'bold',marginTop: 24}}>审核状态：{shztMap[shzt]}</h3>}
                    {shzt && shzt!=='0' && <h3 style={{fontWeight: 'bold',marginTop: 6}}>审核意见：{shyj || '无'}</h3>}
                </div>

              </div>
              <div className="text-center mt-50">
                <NextButton nextUrl={this.state.nextUrl} search={this.state.search} />
              </div>
            </Flex.Item>
          </Flex>
        ) : (
          <CommonTip hjmc={'照片上传'} key={'GD015_3'} mkbz="GD015_3" nonOperationalData={this.state.nonOperationalData} />
        )}
      </div>
    )
  }
}
export default Form.create()(SinglePhotoForm)
