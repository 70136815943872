import {handle as nxmzzyjsxyHandle,syscBtnFunc as nxmzzyjsxyHandleSyscBtnFunc} from './nxmzzyjsxy.js';
import {handle as gdsxzyjsxyHandle,syscBtnFunc as gdsxzyjsxyHandleSyscBtnFunc} from './gdsxzyjsxy.js';

/**
 * 网上支付办理接口
 * @param {*} ddh 订单号
 * @param {*} csxx 配置参数信息，包含：pczflj(PC端支付路径),ydzflj(移动端支付路径)，zfts(支付提示),所有的自定义配置的参数信息
 * @param {*} xsxx 学生信息，包含：XSID(学生ID),XH(学号),XM(姓名),XBM(性别码),SFZJH(身份证件号),RXNF(入学年份),CSRQ(出生日期)
 * @param {*} jfxm 缴费信息，LIst<Map<String,Object>>对象,包含：sfxmid(收费项目ID),sfxmdm(收费项目代码),sfxmmc(收费项目名称)，je(金额)
 * @param {*} jyje 交易金额
 * @param {*} yjje 应缴金额
 * @param {*} ysje 已缴金额
 * @param {*} hjje 缓缴金额
 */
export const handle = (type,ddh,csxx,xsxx,jfxm,jyje,yjje,ysje,hjje) => {
  if(type==='nxmzzyjsxy'){//宁夏民族职业技术学院
    nxmzzyjsxyHandle(ddh,csxx,xsxx,jfxm,jyje,yjje,ysje,hjje);
  }else if(type==='gdsxzyjsxy'){//广东松山技术学院
    gdsxzyjsxyHandle(ddh,csxx,xsxx,jfxm,jyje,yjje,ysje,hjje);
  }
}

/**
 * 点击同步按钮触发时的操作
 * @param {*} ddh 订单号
 */
export const syscBtnFunc = (type,ddh) => {
  if(type==='nxmzzyjsxy'){//宁夏民族职业技术学院
    nxmzzyjsxyHandleSyscBtnFunc(ddh);
  }
}
