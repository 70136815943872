import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Flex} from '@components';
import {Tabs} from 'antd';
import './index.css';
import {message} from 'antd';
import {Scrollbars} from 'react-custom-scrollbars';

const TabPane = Tabs.TabPane;

/**
 * @author weishihuai
 * @date 2018/7/6
 * @time 14:42
 * @Description: 迎新网站-自助报到-宿舍分配-宿舍床位信息
 */
export default class SelectBox extends React.Component {

    static propTypes = {
        className: PropTypes.string,
        xzId: PropTypes.any,
        showConfirm: PropTypes.func,
        onChange: PropTypes.func,
        autoSelectCwListener: PropTypes.func,
        tabChangeListener: PropTypes.func
    };

    static defaultProps = {
        xzId: '',
        showConfirm: Function.prototype,
        onChange: Function.prototype,
        autoSelectCwListener: Function.prototype,
        tabChangeListener: Function.prototype
    };

    constructor(props) {
        super(props);
    }

    //床位信息
    getListItem = (Data = []) => {
        const {className, showConfirm, xzId, onChange, autoSelectCwListener} = this.props;
        return <Flex align="top" wrap="wrap" className="mt-20">
            {Data.length > 0 ?
                Data.map((item, idx) => {
                    return (
                        <div key={idx} styleName="item">
                            <div styleName="item_con">
                                <Flex justify="between">
                                    <span>
                                        <span styleName="round"/>
                                        {item.name}
                                    </span>
                                    <span>
                                        <span className="text-blue">
                                            {item.yxNum}
                                        </span>/{item.allNum}
                                    </span>
                                </Flex>
                                <Flex
                                    wrap="wrap"
                                    align="center"
                                    justify="center"
                                    styleName="select_box"
                                >
                                    <div styleName="select_list">
                                        {item.bedList[0] &&
                                        item.bedList.map(
                                            (el, index) => {
                                                return (
                                                    <div key={index}>
                                                        {el.map((cwxx, index) => {
                                                                return (
                                                                    <div
                                                                        key={index}
                                                                        styleName={classNames(
                                                                            'select_item',
                                                                            {
                                                                                select_item_b:
                                                                                cwxx.xz === '1',
                                                                                select_item_y: (xzId === cwxx.id && cwxx.xz === '0') || cwxx.currentXsSelected
                                                                            }
                                                                        )}
                                                                        onClick={() => {
                                                                            if (cwxx.xz === "0") {
                                                                                showConfirm(item, cwxx.id, cwxx.cwh);
                                                                                onChange(cwxx);
                                                                            } else {
                                                                                message.warning(
                                                                                    '抱歉，该床铺已选择！'
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                </Flex>
                            </div>
                            <div styleName="item_btn_box">
                                <div
                                    styleName="item_btn"
                                    onClick={() =>
                                        autoSelectCwListener(item)
                                    }
                                >
                                    自动入住
                                </div>
                                <div
                                    styleName="item_btn"
                                    onClick={() =>
                                        showConfirm(item)
                                    }
                                >
                                    选择床位
                                </div>
                            </div>
                        </div>
                    );
                }) : <div><span style={{color: "#f00"}}>暂无匹配的宿舍信息！</span></div>}
        </Flex>
    };

    render() {
        const {className, Data, scrollHeight, tabChangeListener, xqList, currentXqid} = this.props;
        let activeKey = currentXqid || (xqList[0] && xqList[0].XQID);

        return (
            <Tabs defaultActiveKey="0" activeKey={activeKey} tabPosition="top"
                  onChange={(activeKey) => tabChangeListener(activeKey)}>
                {xqList.map((xq) => {
                    return (
                        <TabPane tab={xq.XQMC} key={xq.XQID}>
                            <Flex styleName="tip" justify="center">
                                <img
                                    src={require('@images/bed_g.jpg')}
                                    alt=""
                                />
                                <span>可选择</span>
                                <img
                                    className="ml-10"
                                    src={require('@images/bed_b.jpg')}
                                    alt=""
                                />
                                <span>不可选</span>
                                <img
                                    className="ml-10"
                                    src={require('@images/bed_y.jpg')}
                                    alt=""
                                />
                                <span>已选择</span>
                            </Flex>
                            {Data.length > 16 ?
                                <Scrollbars autoHide
                                            style={{width: '100%', height: scrollHeight}}>
                                    {this.getListItem(Data)}
                                </Scrollbars> :
                                this.getListItem(Data)
                            }
                        </TabPane>
                    );
                })}
            </Tabs>
        );
    }
}
