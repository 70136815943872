import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Flex } from '@components';
import {
    Form, Input, Checkbox, Row, Col, Button, Modal,
    Upload, Icon, message, Spin, InputNumber
} from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import { createUuid, getLoginUser } from '@utils/NHCore';
import NHFetch from "@utils/NHFetch";
import View from "@pages/SelfHelp/sszychannel/view";
const Dragger = Upload.Dragger;
import createHistory from 'history/createHashHistory';
import CommonTip from "@pages/SelfHelp/common";
import NHSelect from "@components/common/NHSelect";
import NextButton from '@pages/SelfHelp/common/nextIndex'
import { mkbz } from "@pages/Assembly/siderMenu/menuObj";
import Hint from "@pages/SelfHelp/common/hint";
import NHFormItem from "@components/common/NHFormItem";
import {NHConfirm} from "@components/common/NHModal";
const history = createHistory();
const { TextArea } = Input;
const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 }
};

@Form.create()
export default class SszyChannel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mkbz: 'GD010',
            hjmc: '',
            uploading: false,
            loading: false,
            previewVisible: false,
            fileList: [],
            checkGroup: [],//绿色通道办理原因
            data: {},//绿色通道申请信息
            nextUrl: '',
            search: '',
            operable: true,//是否显示环节
            nonOperationalData: {},//不可操作的数据
            sqlyFlag: false, //申请理由flag
            jymFlag: false,  //6位数回执校验码flag
            judgeImgList: ['.png', '.PNG', '.jpg', '.JPG', '.bpm', '.BPM', '.jpeg', '.JPEG'],   //可以导入的图片格式
            judgeFileList: ['.pdf', '.PDF', '.doc', '.DOC', '.zip', '.ZIP', '.txt', '.TXT', '.xls', '.XLS', '.rar', '.RAR'],  //可以导入的非图片格式
        };
    }

    componentDidMount() {
        this.queryQztjAndYlhj();
        this.loadLstdInfo();
        this.loadLstdBlyy();
    }

    //查询前置条件和依赖环节
    queryQztjAndYlhj = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (!xsid) {
            this.setState({
                nonOperationalData: { nologin: true },
                operable: false,
            });
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', { xsid: xsid, zzbdhjid: zzbdhjid })
            .then(res => {
                if (res && res.data) {
                    let kfsj = res.data['kfsj'];
                    let ylhj = res.data['ylhj'];
                    let qzhj = res.data['qzhj'];
                    let nohj = res.data['nohj']; 
                    this.setState({ nonOperationalData: res.data });
                    if (kfsj && kfsj['YXSJ'] != '1') {
                        this.setState({ operable: false });
                    }

                    if (ylhj && ylhj.length > 0) {
                        this.setState({ operable: false });
                    }

                    if (qzhj && qzhj.length > 0) {
                        this.setState({ operable: false });
                    } 
                    if (nohj) {
                        this.setState({ operable: false });
                    }
                }
            });
    }

    getQueryString = (name) => {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    }

    setMenuList = (menuList) => {
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem, index) => {
            if (elem.HJDM == bz) {
                let hjmc = elem.HJMC;
                this.setState({ hjmc: hjmc })
                nextCount = index + 1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if (menu) {
            let hjdm = menu.HJDM;
            if (hjdm.indexOf('GD') == 0) {
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl: gdUrl,
                    search: 'pkid=' + menu.PKID,
                })
            } else if (hjdm.indexOf('ZDY') == 0) {
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl: zdyUrl,
                    search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
                })
            }
        } else {
            this.setState({
                nextUrl: '',
                search: '',
            })
        }
    }

    loadLstdBlyy = () => {
        NHFetch('front/zhxg-unauth/yxwz/proData/selectDataList', 'get', 'sign=dmk_YXXT_LSTD_BLYY').then(res => {
            if (res) {
                let col = [];
                res.data.map(item => {
                    col.push(
                        <Col span={6}>
                            <Checkbox value={item.VALUE}>
                                {item.LABEL}
                            </Checkbox>
                        </Col>
                    );
                });
                let html = <Row>{col}</Row>
                this.setState({ checkGroup: html });
            }
        });
    }

    //读取学生的绿色通道信息
    loadLstdInfo = () => {
        NHFetch('/lstd/getLstdInfoByXsid', 'GET').then(resData => {
            if (resData && resData.data) {
                this.setState({ data: resData.data });
                let fileList = [];
                if (resData.data && resData.data['fj']) {
                    resData.data['fj'].map((elem, index) => {
                        //格式化对于附件取值
                        let file = {
                            uid: elem.fjid,
                            name: elem.fjmc,
                            response: {
                                data: {
                                    ssbh: elem.fjid,
                                    fjmc: elem.fjmc,
                                }
                            },
                            status: 'done'
                        }
                        fileList.push(file);
                    })
                }
                this.setState({ fileList: fileList });
                this.props.form.setFieldsValue({ fj: { fileList: this.state.fileList } });
            } else {
                this.setState({ data: {} });
                this.setState({ fileList: [] });
                this.props.form.resetFields();
            }
        });
    }

    // 转化base64
    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    // 点击文件链接或预览图标时的回调
    handlePreview = file => {
        const isImage = /^image\/.*$/i.test(file.type);
        if (isImage) {
            if (file) {
                this.getBase64(file, imageUrl => {
                    this.setState({
                        previewImage: imageUrl,
                        previewVisible: true
                    });
                });
            } else {
                this.setState({
                    previewImage: file.url || file.thumbUrl,
                    previewVisible: true
                });
            }
        } else if (file && file['response'] && file['response']['data']) {//其他情况
            let isImage = true;
            let fjmc = file['response']['data']['fjmc'];
            let ssbh = file['response']['data']['ssbh'];
            if (
                !this.setJudge(fjmc, this.state.judgeImgList)
            ) {
                isImage = false;
            }

            if (isImage) {
                this.showPreview(`anonymity/docrepo/download/file?attachmentId=${ssbh}`);
            } else {
                window.open(`anonymity/docrepo/download/file?attachmentId=${ssbh}`);
            }
        }
    };

    showPreview = (url) => {
        this.setState({
            previewImage: url,
            previewVisible: true
        });
    }



    // 关闭图片弹窗
    handleCancel = () => this.setState({ previewVisible: false });

    // 提交
    handleSubmit = e => {

        this.setState({ loading: true });
        e.preventDefault();
        let zzbdhjid = this.getQueryString('pkid');
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                this.setState({ loading: false });
                return false;
            }
            const fj = values['fj'];

            let isEmpty = false;
            if (fj) {
                if (fj['fileList'] && fj['fileList'].length > 0) {
                    isEmpty = false;
                } else {
                    isEmpty = true;
                }
            } else {
                isEmpty = true;
            }

            if (isEmpty) {
                this.props.form.setFields({
                    fj: {
                        value: undefined,
                        // errors:[new Error('请上传材料证明')],
                    }
                });
                this.setState({ loading: false });
                // return false;
            }
            let size = null;
            let file = null;
            let fileList = null;
            let fjlist = [];

            if (fj) {
                size = fj['fileList'].length;
                file = fj['fileList'].filter((file) => {
                    if (file.status === 'error') {
                        return false;
                    }
                    return true;
                });
                if (file.length != size) {
                    this.props.form.setFields({
                        fj: {
                            value: fj,
                            errors: [new Error('材料上传出错，请重新上传.')],
                        }
                    });
                    this.setState({ loading: false });
                    return false;
                }
                fileList = fj['fileList'];
                for (let i = 0; i < fileList.length; i++) {
                    let fjid = fileList[i].response.data.ssbh;
                    let fjmc = fileList[i].name;
                    fjlist.push({
                        fjid: fjid,
                        fjmc: fjmc
                    });
                }
            }

            let sqsmFlag = false;
            let msg = '';
            let blyy = values['blyy'];
            let sqsm = values['sqsm'];
            let dkyh = values['dkyh'];
            let hzjym = values['hzjym'];
            let pkid = values['pkid'];
            blyy.map(item => {
                if (item == '99') {
                    if (typeof (sqsm) == "undefined" || sqsm.trim().length == 0) {
                        sqsmFlag = true;
                        msg = "其它原因，请填写申请理由"
                    }
                }
            })

            if (dkyh == '01') {
                if (typeof (hzjym) == "undefined" || hzjym.trim().length != 6) {
                    sqsmFlag = true;
                    msg = "国家开发银行贷款，请填写6位数回执校验码"
                }
            }
            if (sqsmFlag) {
                message.error(msg);
                return;
            }

            let blje = values['blje'];
            let xsid = getLoginUser().userId;
            let params = {
                fj: fjlist,
                blyy: blyy,
                blje: blje,
                sqsm: sqsm,
                hzjym: hzjym,
                dkyh: dkyh,
                xsid: xsid,
                zzbdhjid: zzbdhjid,
                pkid: pkid,
            }
            NHConfirm("绿色通道办理之后无法取消办理,请确认是否办理！",() => {
                if (!pkid) {
                    NHFetch('/lstd/insertofsszy', "POST", params)
                        .then(res => {
                            if (res) {
                                if(res.data===-1){
                                    message.error("学费标准未下发，暂时无法办理绿色通道！");
                                }else if(res.data==null){
                                    message.error("缴费系统接口调用失败！请重试！")
                                }else{
                                    message.success("绿色通道申请保存成功。");
                                }
                                this.loadLstdInfo();
                                this.reloadMenu();
                            }
                            this.setState({ loading: false })
                        }).catch((res) => {
                            this.setState({ loading: false })
                            return res;
                        });
                } else {
                    NHFetch('/lstd/updateofsszy', "POST", params)
                        .then(res => {
                            if (res) {
                                if(res.data===-1){
                                    message.error("学费标准未确认，暂时无法办理绿色通道！");
                                }else if(res.data==null){
                                    message.error("缴费系统接口调用失败！请重试！")
                                }else if(res.data && res.data > 0){
                                    message.success("绿色通道申请保存成功。");
                                }
                                this.loadLstdInfo();
                                this.reloadMenu();
                            } else {
                                message.warning("绿色通道申请保存失败，请重试。");
                            }
                            this.setState({ loading: false })
                        }).catch((res) => {
                            this.setState({ loading: false })
                            return res;
                        });
                }
            },"warn");
        });
    };

    handleChange = ({ fileList }) => {
        let count = 0;
        fileList.map((elem) => {
            if (elem && elem['status'] && elem['status'] === 'uploading') {
                this.setState({ uploading: true });
                return;
            } else if (elem && elem['status']) {
                count++;
            }
        })
        if (count > 0) {
            this.setState({ uploading: false });
        }
    }

    //判断文件类型是否正常  name：文件名称  map：类型范围数组  true正常
    setJudge = (name, map) => {
        let flag = false;
        map.map(item => {
            if (name.indexOf(item) !== -1) {
                flag = true;
            }
        })
        return flag;
    }

    beforeUpload = (file) => {
        if (
            !(this.setJudge(file.name, this.state.judgeImgList) ||
                this.setJudge(file.name, this.state.judgeFileList))
        ) {
            message.error('不能上传该格式文件');
            return false;
        } else {
            return true;
        }
    }

    remove = (ssbh) => {
        let url = `api/docrepo/attachment/sourceId/delete?souerceId=${ssbh}`;
        NHFetch(url, "POST").then(res => {
            if (res.data && res.data > 0) {
                message.success('已删除材料.');
            }
        });
    }

    //重新加载菜单
    reloadMenu = () => {
        this.refs.siderMenu.reloadMenuList()
    }


    render() {
        const { form } = this.props;
        const { getFieldDecorator } = this.props.form;
        const { previewImage, previewVisible, jymFlag, sqlyFlag } = this.state;
        const props = {
            name: 'file',
            action: 'anonymity/docrepo/upload',
            data: { uuid: createUuid(), type: '1' },

            onRemove: file => {
                this.setState(({ fileList }) => {
                    const index = fileList.indexOf(file);
                    const newFileList = fileList.slice();
                    newFileList.splice(index, 1);
                    this.props.form.setFieldsValue({ fj: { fileList: newFileList } });
                    return {
                        fileList: newFileList
                    };
                }, () => {
                    if (file.response && file.response.data && file.response.data.ssbh) {
                        this.remove(file.response.data.ssbh);
                    }
                });
            },
            beforeUpload: file => {
                if (!this.beforeUpload(file)) {
                    return false;
                }
                this.setState(({ fileList }) => ({
                    fileList: [...fileList, file]
                }));
                return true;
            },
            onChange: file => { this.handleChange(file) },
            onPreview: this.handlePreview,
            fileList: this.state.fileList,
        };

        let edit = false;
        const data = this.state.data;
        if (JSON.stringify(data) == "{}") {
            edit = true;
        } else if (data && data['shzt'] === '9') {
            edit = true;
        }
        return (
            <div style={{ position: 'relative' }}>{this.state.operable ?
                <div>
                    <Flex className="w1180 mt-20" align="start">
                        <SiderMenu
                            ref={"siderMenu"}
                            setMenuList={this.setMenuList}
                            title="自助报到"
                            mkbz={this.state.mkbz}
                            /*     active={4}*/
                            menuType='自助报到' />
                        <Flex.Item className="page_con_r" styleName="">
                            <Breadcrumb
                                noLine
                                list={[
                                    { name: '首页', url: '/index' },
                                    { name: '自助报到', url: '/register' },
                                    { name: this.state.hjmc }
                                ]}
                            />
                            <div className="box">
                                <Hint />
                                {edit ? <Form onSubmit={this.handleSubmit}>
                                    <TitleTop title="申请信息" extra={false} />
                                    <div className="p-sw">
                                        <div style={{ display: 'none' }}>
                                            <NHFormItem id={'pkid'} initialValue={data['pkid']} form={form}>
                                                <Input />
                                            </NHFormItem>
                                        </div>
                                        <div style = {{marginLeft:"30px"}}>
                                            <NHFormItem id={'blyy'} initialValue={data['blyydm']} form={form} formItemLayout={{labelCol:{span:4},wrapperCol:{span:16}}} label="办理原因">
                                                <CheckboxGroup style={{ width: '100%'}}>
                                                    {this.state.checkGroup}
                                                </CheckboxGroup>
                                            </NHFormItem>
                                        </div>
                                        <div className="form_item" style={{ marginLeft: '7%' }}>
                                            <NHFormItem id={'sqsm'} initialValue={data['sqsm']} form={form} formItemLayout={formItemLayout} label="申请理由">
                                                <TextArea maxLength={50} placeholder="请填写申请理由" />
                                            </NHFormItem>
                                            {/*<span style={{color: '#ff0000',fontSize:"12.7px",paddingLeft:'25%'}} >湖南省未设立高校助学贷款，只接受生源地助学贷款</span>*/}
                                            <NHFormItem id={'blje'} initialValue={data['blje']} form={form} formItemLayout={formItemLayout} label="贷款金额">
                                                <InputNumber style={{ width: '100%' }} min={0} max={99999999}  precision="0" placeholder="请填写贷款金额（元）" />
                                            </NHFormItem>
                                            <NHFormItem id={'dkyh'} initialValue={data['dkyh']} form={form} formItemLayout={formItemLayout} label="贷款银行">
                                                <NHSelect sign={"dmk_yxwz_dkyh"} onChange={(v) => { }} />
                                            </NHFormItem>
                                            <NHFormItem id={'hzjym'} initialValue={data['hzjym']} form={form} formItemLayout={formItemLayout} label="回执校验码">
                                                <Input maxLength={6} placeholder="6位数回执校验码" />
                                            </NHFormItem>
                                        </div>
                                    </div>
                                    <TitleTop title="申请材料" extra={false} />
                                    <div className="p-sw">
                                        <div className="form_item" style={{ marginLeft: '7%' }}>
                                            <FormItem
                                                {...formItemLayout}
                                                label="材料证明"
                                            >
                                                {getFieldDecorator('fj', {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message:
                                                                '请上传材料证明'
                                                        }
                                                    ]
                                                })(
                                                    <Dragger {...props}>
                                                        <p className="ant-upload-drag-icon">
                                                            <Icon type="inbox" />
                                                        </p>
                                                        <Spin spinning={this.state.uploading} />
                                                        <p className="ant-upload-text font-14">
                                                            点击此处或拖拽文件到此
                                                    </p>
                                                    </Dragger>
                                                )}
                                            </FormItem>
                                        </div>
                                    </div>
                                    <div className="text-center mt-50">
                                        <Button type="primary" htmlType="submit">
                                            保存
                                        </Button>
                                        <NextButton nextUrl={this.state.nextUrl} search={this.state.search} />
                                    </div>
                                </Form> : <View reloadMenu={this.reloadMenu} nextUrl={this.state.nextUrl} loadLstdInfo={this.loadLstdInfo} search={this.state.search} showPreview={this.showPreview} setJudge={this.setJudge} data={this.state.data} judgeImgList={this.state.judgeImgList} />}
                            </div>
                        </Flex.Item>
                    </Flex>
                    <Modal
                        visible={previewVisible}
                        footer={null}
                        onCancel={this.handleCancel}
                    >
                        <img
                            alt="example"
                            style={{ width: '100%' }}
                            src={previewImage}
                        />
                    </Modal>
                    <Spin style={{ position: 'absolute', top: '300px', left: '60%' }} spinning={this.state.loading} /> </div> : <CommonTip hjmc={"绿色通道申请"} key={"GD010"} mkbz='GD010' nonOperationalData={this.state.nonOperationalData} />}
            </div>

        );
    }
}
