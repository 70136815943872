
import {NHVirtualForm} from '../../../../../utils/NHVirtualForm.js';
import NHFetch from "../../../../../utils/NHFetch";
import {message} from 'antd';
let md5 = require("md5");

/**
 * 网上支付办理接口
 * 请自行在此处判断需要的参数是否符合要求，例如判断身份证件号是否为空，Toast.hide()，可以使用改方法关闭准备跳转支付界面提示
 *
 * @param {*} ddh 订单号
 * @param {*} csxx 配置参数信息，包含：pczflj(PC端支付路径),ydzflj(移动端支付路径)，zfts(支付提示),所有的自定义配置的参数信息
 * @param {*} xsxx 学生信息，包含：XSID(学生ID),XH(学号),XM(姓名),XBM(性别码),SFZJH(身份证件号),RXNF(入学年份),CSRQ(出生日期)
 * @param {*} jfxm 缴费信息，LIst<Map<String,Object>>对象,包含：sfxmid(收费项目ID),sfxmdm(收费项目代码),sfxmmc(收费项目名称)，je(金额)
 * @param {*} jyje 交易金额
 * @param {*} yjje 应缴金额
 * @param {*} ysje 已缴金额
 * @param {*} hjje 缓缴金额
 */
export const handle = (ddh,csxx,xsxx,jfxm,jyje,yjje,ysje,hjje) => {
    let url = csxx.axfpayurl;
    let key = csxx.securetkey
    let json = `{"custno":"${xsxx.SFZJH}"}`;
    let signature = md5(json+key)
    // window.open(`${url}?json={"custno":"13800138000"}&signature=${signature}`);
    window.open(`${url}?json=${json}&signature=${signature}`)
}

/**
 * 点击同步按钮触发时的操作
 * @param {*} ddh 订单号
 */
export const syscBtnFunc = (ddh) => {

}

