import React from 'react';
import {Link} from 'react-router-dom';
import {Flex} from '@components';
import {Form, DatePicker, Input, InputNumber, Button, Select, Table, Icon, TimePicker} from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import NHFetch from "@utils/NHFetch";
import {message} from "antd/lib/index";
import moment from 'moment';
import NHSelect from "@components/common/NHSelect";
import {createUuid, getLoginUser} from '@utils/NHCore';
import NextButton from '@pages/SelfHelp/common/nextIndex'
// import 'moment/src/locale/zh-cn';
import createHistory from 'history/createHashHistory';
import CommonTip from "../common";
import {mkbz} from "@pages/Assembly/siderMenu/menuObj";
import Hint from "@pages/SelfHelp/common/hint";

const history = createHistory();
const format = 'HH:mm';


const basicUrl = "/zzbd";
const hndysfUrl = "/hndysf/bdqk";
const commonUrl = '/zzbdCommon';

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16}
};

@Form.create()
export default class Report extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mkbz: 'GD004_10',
            hjmc: '',
            editable: true,
            update: false,
            initPOvalue: {
                nd: '',
                xsid: '',
                sfasbd: '',
                basbdyy: '',
                jtgj: '',
                ddz: '',
                dxsj: '',
                bc: '',
                ptrs: 0,
                xqmc: '',
                xmmc: '',
            },
            value: null,
            lxjtgjmcList: {},
            lxjtgjmc: '',
            ddz: {},
            options: [],
            xmoptions: [],
            dqddz: '',
            nextUrl: '',//左侧菜单加载完后传进来
            search: '',
            operable: true,//是否显示环节
            nonOperationalData: {},//不可操作的数据
            isHide: true,
            lxjtgjbc: '来校交通工具',
            lxrqbc: '来校日期',
            isShow: false,//来校日期是否显示
            xqmc:[],
            xmmc:[],
            xqdm:'',
            xmzs:'',
        };
    }


    componentWillMount() {
        this.getDmkMc("YXWZ_LXJTGJ");

    }

    //渲染前获取信息
    componentDidMount() {
        this.queryQztjAndYlhj();
        this.getLxjtgjmc();
        this.getLxrqmc();
        this.getDdz();
        this.getQkpo();
        this.getXqmc();
        this.getXmmc();
    }

    //查询前置条件和依赖环节
    queryQztjAndYlhj = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (!xsid) {
            this.setState({
                nonOperationalData: {nologin: true},
                operable: false,
            });
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', {xsid: xsid, zzbdhjid: zzbdhjid})
          .then(res => {
              if (res && res.data) {
                  let kfsj = res.data['kfsj'];
                  let ylhj = res.data['ylhj'];
                  let qzhj = res.data['qzhj'];
                  let nohj = res.data['nohj']; 
                  this.setState({nonOperationalData: res.data});
                  if (kfsj && kfsj['YXSJ'] != '1') {
                      this.setState({operable: false});
                  }

                  if (ylhj && ylhj.length > 0) {
                      this.setState({operable: false});
                  }

                  if (qzhj && qzhj.length > 0) {
                      this.setState({operable: false});
                  }

                  if (nohj) { 
                    this.setState({ operable: false });
                }
              }
          });
    }

    getLxjtgjmc = () => {
        NHFetch('/zzbdCommon/getCssz', "GET", {csbz: 'YXXT_ZZBD_BDQK_ZDBC_LXJTGJ'})
          .then(res => {
              if (res) {
                  if (res && res.code === 200) {
                      this.setState({lxjtgjbc: res.data});
                  }
              }
          });
    }

    getLxrqmc = () => {
        NHFetch('/zzbdCommon/getCssz', "GET", {csbz: 'YXXT_ZZBD_BDQK_ZDBC_LXRQ'})
          .then(res => {
              if (res) {
                  if (res && res.code === 200) {
                      this.setState({lxrqbc: res.data});
                  }
              }
          });
    }

    getQueryString = (name) => {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    }

    setMenuList = (menuList) => {
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem, index) => {
            if (elem.HJDM == bz) {
                let hjmc = elem.HJMC;
                this.setState({hjmc: hjmc})
                nextCount = index + 1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if (menu) {
            let hjdm = menu.HJDM;
            if (hjdm.indexOf('GD') == 0) {
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl: gdUrl,
                    search: 'pkid=' + menu.PKID,
                })
            } else if (hjdm.indexOf('ZDY') == 0) {
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl: zdyUrl,
                    search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
                })
            }
        } else {
            this.setState({
                nextUrl: '',
                search: '',
            })
        }
    }

    getQkpo() {
        const xsid = getLoginUser().userId;
        let lxjtgjmc = '';
        NHFetch(hndysfUrl + "/getQkpoByXsidAndNd", "GET", {xsid: xsid})
          .then(res => {
              if (res) {
                  if (res.data) {
                      lxjtgjmc = res.data.jtgjmc;
                      this.setState({initPOvalue: res.data, editable: false, lxjtgjmc: lxjtgjmc});
                      let dqddz = this.state.initPOvalue.ddz;
                      this.onChange(this.state.initPOvalue.jtgj);
                      this.setState({
                          dqddz: dqddz,
                          isHide: res.data.sfasbd != 1
                      });

                  }
              }
          });
    }


    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return;
            }
            if (values['sfasbd'] == '0') {
                if (values['basbdyy'] == null || typeof (values['basbdyy']) == "undefined" || values['basbdyy'].trim().length === 0) {
                    message.error('不按时到达请填写原因。');
                    return
                }
            }

            let sUrl = '/saveBdqkData';
            if (this.state.update) {
                sUrl = '/updateBdqkData';
            }
            //修改 可编辑状态，并可以update数据
            if (!this.state.editable) {
                this.setState({update: true, editable: true});
                return
            }
            const {dxsj} = values;
            let dxsjVal = moment(dxsj).format("YYYY-MM-DD HH:mm");
            values.dxsj = dxsjVal;
            const xsid = getLoginUser().userId;
            const zzbdhjid = this.getUrlParams("pkid");
            let params = {xsid: xsid, zzbdhjid: zzbdhjid, formData: values};


            //请求保存或修改数据
            NHFetch(hndysfUrl + sUrl, 'POST', params)
              .then(res => {
                  if (res) {
                      if (res.data == '1') {
                          message.success('信息保存成功！');
                          this.reloadMenu();
                          this.getQkpo();
                      } else {
                          message.error('信息保存失败，请重试！');
                      }
                  }
              })
        });
    };

    //获取url中传递的参数
    getUrlParams = (param) => {
        return decodeURIComponent((new RegExp('[?|&]' + param + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    };


    //获取下拉列表value对应的名称
    getDmkMc = (dmbz) => {
        NHFetch(basicUrl + "/getDmkMc", "GET", {dmbz: dmbz})
          .then(res => {
              if (res) {
                  if (res.data) {
                      if (dmbz === "YXWZ_LXJTGJ") {
                          this.setState({lxjtgjmcList: res.data});
                      }
                  }
              }
          });
    }
    //获取到达站下拉列表的名称
    getDdz = () => {
        NHFetch(basicUrl + "/getDdz", "GET")
          .then(res => {
              if (res) {
                  if (res.data) {
                      this.setState({ddz: res.data});
                  }
              }
          });
    }

    //到达站级联
    DdzSeleOption = (value) => {

        let dqddz = '';
        let coptions = '';
        if (this.state.ddz.length > 0) {
            coptions = this.state.ddz.map((item, key) => {
                if (value === item.ZTLX) {
                    dqddz = item.ZTMC;
                    return <Option key={item.ZTMC}>{item.ZTMC}</Option>
                } else {
                    return null
                }
            });

        }
        this.props.form.setFieldsValue({ddz: dqddz});
        this.setState({options: coptions, dqddz: dqddz});

    }


    //下拉框发生变化检测
    onChange = (value) => {
        this.DdzSeleOption(value);
    }

    //动态显示报到情况填写
    onSfasbdChange = (value) => {
        this.setState({
            isHide: value != '1',
            isShow: true
        });
    }

    //重新加载菜单
    reloadMenu = () => {
        this.refs.siderMenu.reloadMenuList()
    }

    //陪同人数不符合时提示
    numberChange = (value) => {
        if (value > 10 || value < 0) {
            message.warning('陪同人数请填写0-10之内的整数！');
        }
    }

    //disabledStartDate设置到校日期的时间选择器的可选日期区域
    disabledStartDate = startValue => {
        return startValue && startValue < moment().subtract(1, "days");
    };


    getXqmc = () => {
        NHFetch(commonUrl + "/getXqmc", "GET")
            .then(res => {
                if (res.data) {
                        let xqmc = res.data.map(initItem => {
                            return {label: initItem.XQMC, value: initItem.PKID,xqdm: initItem.XQDM}
                        })
                    
                        this.setState({xqmc: xqmc});
                        console.log("校区信息", xqmc);
                }
            });
    }

    //获取校门下拉列表的名称
    getXmmc = () => {
        NHFetch(commonUrl + "/getXmmc", "GET")
            .then(res => {
                if (res) {
                    if (res.data) {
                        let xmmc = res.data.map(initItem => {
                            return {label: initItem.XMMC, value: initItem.PKID,xqlx: initItem.XQLX}
                        })
                        this.setState({xmmc: xmmc});
                    }
                }
            });
    }


    //校门级联
    XMSeleOption = (value) => {
        this.state.xqmc.some((item) => {
            if (value === item.value) {
                this.setState({ xqdm: item.xqdm }, () => {
                    //console.log("xqdm", this.state.xqdm);
                        let xmzs = '';
                        let xmid = '';
                        let coptions = '';
                        console.log("value", this.state.xmmc);
                        if (this.state.xmmc.length > 0) {
                            coptions = this.state.xmmc.map((item, key) => {
                                if (this.state.xqdm === item.xqlx) {
                                    //console.log("item.xqlx", item.xqlx);
                                    xmzs = item.label;
                                    xmid = item.value;
                                    return <Option key={item.value}>{item.label}</Option>
                                } else {
                                    return null
                                }
                            });
                            //console.log("coptions", coptions);
                        }
                        this.props.form.setFieldsValue({xmmc: xmid});
                        this.setState({xmoptions: coptions, xmzs: xmzs});
                });
                return true; // 使用 some() 方法，一旦找到匹配项就停止遍历。
            }
        });
        

    }


    //下拉框发生变化检测
    onChangeXQ = (value) => {
        this.XMSeleOption(value);
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {editable, initPOvalue, lxjtgjmc, options, dqddz, isHide, lxjtgjbc, lxrqbc, isShow,xqmc,xmmc,xmzs,xmoptions} = this.state;
        return (
          <div>{this.state.operable ?
            <Flex className="w1180 mt-20" align="start">
                <SiderMenu
                  ref={"siderMenu"}
                  setMenuList={this.setMenuList}
                  title="自助报到"
                  /* active={3}*/
                  mkbz={this.state.mkbz}
                  menuType='自助报到'
                />
                <Flex.Item className="page_con_r" styleName="">
                    <Breadcrumb
                      noLine
                      list={[
                          {name: '首页', url: '/index'},
                          {name: '自助报到', url: '/register'},
                          {name: this.state.hjmc}
                      ]}
                    />
                    <div className="box">
                        <Hint/>
                        <TitleTop title={this.state.hjmc} extra={false}/>
                        <Form onSubmit={this.handleSubmit}>
                            <div className="p-sw">
                                <Flex align="start" wrap="wrap">

                                    <div className="form_item">
                                        <FormItem
                                          {...formItemLayout}
                                          label="是否按时报到"
                                        >
                                            {getFieldDecorator('sfasbd', {
                                                initialValue: initPOvalue.sfasbd, placeholder: '请选择',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message:
                                                          '请选择是否按时报到'
                                                    }
                                                ]
                                            })(
                                              editable ?
                                                <Select placeholder="请选择" onChange={this.onSfasbdChange}>
                                                    <Option value="1">
                                                        是
                                                    </Option>
                                                    <Option value="0">
                                                        否
                                                    </Option>
                                                </Select> : <span>{initPOvalue.sfasbd == 1 ? '是' : '否'}</span>
                                            )}
                                        </FormItem>
                                    </div>
                                    {isHide &&
                                    <div className="form_item">
                                        <FormItem
                                          {...formItemLayout}
                                          label="不按时报到原因"
                                        >
                                            {getFieldDecorator('basbdyy', {initialValue: initPOvalue.basbdyy,
                                             rules: [
                                                {
                                                    required: true,
                                                    message:
                                                      '请填写原因'
                                                }
                                            ]
                                        })(
                                              editable ? <Input placeholder="请填写原因"/> :
                                                <span>{initPOvalue.basbdyy}</span>
                                            )}
                                        </FormItem>
                                    </div>
                                    }

                                    {!isHide &&
                                    <div className="form_item">
                                        <FormItem
                                          {...formItemLayout}
                                          label={lxjtgjbc ? lxjtgjbc : '来校交通工具'}
                                        >
                                            {getFieldDecorator('jtgj', {
                                                initialValue: initPOvalue.jtgj,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message:
                                                          '请选择交通工具'
                                                    }
                                                ]
                                            })(
                                              editable ?

                                                <NHSelect sign={"zhxg_yxwz_lxjtgj"} 
                                                onChange={(v) => {
                                                    this.onChange(v);
                                                }}
                                                />

                                                : <span>{lxjtgjmc}</span>
                                            )}
                                        </FormItem>
                                    </div>
                                    }
                                    {/* {!isHide &&
                                    <div className="form_item">
                                        <FormItem
                                          {...formItemLayout}
                                          label="到达站"
                                        >
                                            {getFieldDecorator('ddz', {
                                                initialValue: dqddz,
                                                rules: [
                                                    {
                                                        message:
                                                          '请选择到达站'
                                                    }
                                                ]
                                            })(
                                              editable ?

                                                <Select>
                                                    {options}
                                                </Select>

                                                : <span>{dqddz}</span>
                                            )}
                                        </FormItem>
                                    </div>
                                    } */}
                                    {(isShow || initPOvalue.sfasbd == '0' || initPOvalue.sfasbd == '1') &&
                                    <div className="form_item">
                                        <FormItem
                                          {...formItemLayout}
                                          label={isHide ? '预计来校日期' : (lxrqbc ? lxrqbc : '预计来校日期')}
                                        >
                                            {getFieldDecorator('dxsj', {
                                                initialValue: initPOvalue.dxsj == '' ? null : moment(initPOvalue.dxsj, 'YYYY-MM-DD HH:mm'),
                                                rules: [
                                                    {
                                                        required: !isHide,
                                                        message:
                                                          '请选择日期'
                                                    }
                                                ]
                                            })(
                                              editable ?
                                                <DatePicker
                                                  style={{'width': '100%'}}
                                                  showTime
                                                  format="YYYY-MM-DD HH:mm"
                                                  disabledDate={this.disabledStartDate}
                                                  // defaultValue={initPOvalue.dxsj==''?null:moment(initPOvalue.dxsj, 'YYYY-MM-DD HH:mm')} //如果时间发生错误，请删除此句defaultValue

                                                />
                                                : <span>{initPOvalue.dxsj === 'Invalid date' ? '' : initPOvalue.dxsj}</span>
                                            )}
                                        </FormItem>
                                    </div>
                                    }

                                    {!isHide &&
                                    <div className="form_item">
                                        <FormItem
                                          {...formItemLayout}
                                          label="班次/车次"
                                        >
                                            {getFieldDecorator('bc', {initialValue: initPOvalue.bc})(
                                              editable ?
                                                <Input

                                                /> : <span>{initPOvalue.bc}</span>
                                            )}
                                        </FormItem>
                                    </div>
                                    }
                                    {!isHide &&
                                    <div className="form_item">
                                        <FormItem
                                          {...formItemLayout}
                                          label="陪同人数"
                                        >
                                            {getFieldDecorator('ptrs', {initialValue: initPOvalue.ptrs})(
                                              editable ?
                                                <InputNumber
                                                  precision={0}
                                                  min={0}
                                                  max={10}
                                                  onChange={this.numberChange}
                                                /> : <span>{initPOvalue.ptrs}</span>
                                            )}&nbsp;&nbsp;<span>  (人) </span>
                                        </FormItem>
                                    </div>
                                    }

                                    {!isHide &&
                                    <div className="form_item">
                                        <FormItem
                                          {...formItemLayout}
                                          label={'来校校区'}
                                        >
                                            {getFieldDecorator('xqmc', {
                                                initialValue: initPOvalue.xqmc,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message:
                                                          '请选择来校校区'
                                                    }
                                                ]
                                            })(
                                              editable ?

                                                <NHSelect dataSource={xqmc} onChange={(v) => {
                                                    this.onChangeXQ(v);
                                                }}/>

                                                : <span>{initPOvalue.xqxx}</span>
                                            )}
                                        </FormItem>
                                    </div>
                                    }
                                    {!isHide &&
                                    <div className="form_item">
                                        <FormItem
                                          {...formItemLayout}
                                          label="校门名称"
                                        >
                                            {getFieldDecorator('xmmc', {
                                                initialValue: initPOvalue.xmxx,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message:
                                                          '请选择校门'
                                                    }
                                                ]
                                            })(
                                              editable ?

                                                <Select>
                                                    {xmoptions}
                                                </Select>

                                                : <span>{initPOvalue.xmxx}</span>
                                            )}
                                        </FormItem>
                                    </div>
                                    }
                                </Flex>
                                <div className="text-center mt-50">
                                    <Button
                                      type="primary"
                                      htmlType="submit"
                                    >
                                        {editable ? '保存' : '修改'}
                                    </Button>
                                    <NextButton nextUrl={this.state.nextUrl} search={this.state.search}/>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Flex.Item>
            </Flex>
            :
            <CommonTip hjmc={"报到情况填写"} key={'GD004_10'} mkbz='GD004_10' nonOperationalData={this.state.nonOperationalData}/>}
          </div>
        );
    }
}
