import NHFetch from "../../../../../utils/NHFetch";
import { message } from "antd";

/**
 * 网上支付办理接口
 * 请自行在此处判断需要的参数是否符合要求，例如判断身份证件号是否为空，Toast.hide()，可以使用改方法关闭准备跳转支付界面提示
 *
 * @param {*} ddh 订单号
 * @param {*} csxx 配置参数信息，包含：pczflj(PC端支付路径),ydzflj(移动端支付路径)，zfts(支付提示),所有的自定义配置的参数信息
 * @param {*} xsxx 学生信息，包含：XSID(学生ID),XH(学号),XM(姓名),XBM(性别码),SFZJH(身份证件号),RXNF(入学年份),CSRQ(出生日期)
 */
export const handle = (ddh,csxx,xsxx) => {
  console.info(csxx);
    NHFetch('/cxsfChatPay/queryToken','GET',{hjdm:'GD008_1'}).then(res =>{
      if(res && res.data){
        var data=res.data;
        let purl =csxx.ydzflj + "?head="+ encodeURI(data.headStr) + "&data="+encodeURI(data.dataStr)+"&sign="+encodeURI(data.signStr);
        window.location.href=purl;
      }else {
        message.error("获取登录地址失败，请联系管理员！")
      }
    })
    
}

/**
 * 点击同步按钮触发时的操作
 * @param {*} ddh 订单号
 */
export const syscBtnFunc = (ddh) => {

}

