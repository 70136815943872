import React from 'react';
import { Flex } from '@components';
import { Form, Upload, message } from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import NHFetch from "../../../../utils/NHFetch";
import './index.css';
import { getLoginUser } from '@utils/NHCore';
import CommonTip from "../common";
import { mkbz } from "@pages/Assembly/siderMenu/menuObj";
import Hint from "@pages/SelfHelp/common/hint";
import NextButton from '@pages/SelfHelp/common/nextIndex'
const uploadUrl = "api/zhxg-yxwz/newStudent/cacheStudentPhoto"

class SinglePhotoForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mkbz: 'GD015_7',
            hjmc: '',
            nonOperationalData: {},  // 不可操作的数据
            operable: true,          // 是否显示环节
            nextUrl: '',             // 左侧菜单加载完后传进来
            search: '',
            uploadImg: false,
        }
    }
    componentDidMount() {
        this.checkIsZzbdHjLinked();
        this.queryQztjAndYlhj();
        this.getStudentPhotoBase64()
    }

    // 查询前置条件和依赖环节
    queryQztjAndYlhj = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (!xsid) {
            this.setState({
                nonOperationalData: { nologin: true },
                operable: false,
            });
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', { xsid: xsid, zzbdhjid: zzbdhjid })
            .then(res => {
                if (res && res.data) {
                    let kfsj = res.data['kfsj'];
                    let ylhj = res.data['ylhj'];
                    let qzhj = res.data['qzhj'];
                    let nohj = res.data['nohj'];
                    this.setState({ nonOperationalData: res.data });
                    if (kfsj && kfsj['YXSJ'] !== '1') {
                        this.setState({ operable: false });
                    }
                    if (ylhj && ylhj.length > 0) {
                        this.setState({ operable: false });
                    }
                    if (qzhj && qzhj.length > 0) {
                        this.setState({ operable: false });
                    }
                    if (nohj) {
                        this.setState({ operable: false });
                    }
                }
            });
    }

    // 环节pkid
    getQueryString = (name) => {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    }

    // 检查该环节是否已经办理
    checkIsZzbdHjLinked = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        let zzbdhjid = this.getQueryString('pkid');

        NHFetch("/zzbdCommon/checkIsZzbdHjLinked", 'GET', { xsid: xsid, zzbdhjid: zzbdhjid })
            .then(res => {
                if (res) {
                    if (res.data) {
                        this.setState({
                            editable: false
                        });
                    } else {
                        this.setState({
                            editable: true
                        })
                    }
                } else {
                    this.setState({
                        editable: true
                    })
                }
            });
    };

    // 设置左侧菜单
    setMenuList = (menuList) => {
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem, index) => {
            if (elem.HJDM == bz) {
                let hjmc = elem.HJMC;
                this.setState({ hjmc: hjmc })
                nextCount = index + 1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if (menu) {
            let hjdm = menu.HJDM;
            if (hjdm.indexOf('GD') == 0) {
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl: gdUrl,
                    search: 'pkid=' + menu.PKID,
                })
            } else if (hjdm.indexOf('ZDY') == 0) {
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl: zdyUrl,
                    search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
                })
            }
        } else {
            this.setState({
                nextUrl: '',
                search: '',
            })
        }
    }

    // 获取url中传递的参数
    getUrlParams = (param) => {
        return decodeURIComponent((new RegExp('[?|&]' + param + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    };

    // 重新加载菜单
    reloadMenu = () => {
        this.refs.siderMenu.reloadMenuList()
    }

    // 获取学生头像
    getStudentPhotoBase64 = () => {
        let userId = getLoginUser() ? getLoginUser().userId : undefined;
        if (userId) {
            NHFetch('api/zhxg-yxwz/openapi/student/getStudentPhotoBase64', 'get', { xsid: userId, type: 10 })
                .then(res => {
                    this.setState({
                        xsid: userId,
                        imageUrl: `data:image/jpeg;base64,${res.data}`,
                    })
                });

        }

    }

    beforeUpload = (file) => {
        const isJPG = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJPG) {
            message.error("只能上传.png,.jpg,.jpeg格式的文件");
        }

        let minSize = 0, maxSize = 2 * 1024
        try {
            if(/\d/.test(window.photoMinSize)) {
                minSize = Number(window.photoMinSize)
            }
            if(/\d/.test(window.photoMaxSize)) {
                maxSize = Number(window.photoMaxSize)
            }
        } catch (e) {
            message.error('PHOTOMINSIZE或PHOTOMAXSIZE环境变量错误，请设置为数字')
            minSize = 0
            maxSize = 2 * 1024
        }

        const isSizeLimit = file.size >= minSize * 1024 && file.size <= maxSize * 1024
        if (!isSizeLimit) {
            const uploadElement = document.getElementById('photoUpload')
            if(uploadElement) {
                uploadElement.value = ''
            }
            const minMsg = minSize >= 1024 ? `${(minSize / 1024).toFixed(1)}MB` : `${minSize}KB`
            const maxMsg = maxSize >= 1024 ? `${(maxSize / 1024).toFixed(1)}MB` : `${maxSize}KB`
            message.warning(`图片大小应满足${minMsg}-${maxMsg}`);
        }

        return isJPG && isSizeLimit;
    }

    getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }
    // 上传图片
    handleUploadChange = info => {
        if (info && info['file'] && info['file'].status === 'done') {
            let photoUUID = info['file'].response.data;
            if (photoUUID) {
                this.setState({
                    photoUUID
                })
            }
        }
        this.getBase64(info.file.originFileObj, imageUrl =>
            this.setState({ imageUrl })
        );
    }

    onSaveTxscFormData = (callback) => {
        let userId = getLoginUser() ? getLoginUser().userId : undefined;
        let photoUUID = this.state.photoUUID;
        if (photoUUID) {
            let url = '/newStudent/saveStudentPhoto?xsid=' + userId + '&photoUUID=' + photoUUID + '&type=' + '10' + "&faceMatch=1";
            NHFetch(url, 'POST')
            .then(res => {
                if (res && res.code === 200 && res.data && res.data.status === 'success') {
                    this.setState({ uploadImg: false});
                    this.handleConfirmSubmit(callback);
                } else {
                    if (res && res.code === 200 && res.data) {
                        message.error(res.data.msg);
                    } else {
                        message.error(res.message);
                    }
                }
            });
        }
    }

    handleConfirmSubmit = (callback) => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        let zzbdhjid = this.getUrlParams('pkid');
        let params = { xsid: xsid, zzbdhjid: zzbdhjid };
        NHFetch('/zzbdCommon/commonSaveLinkTransactionStatus', 'POST', params)
            .then(res => {
                if (res) {
                    if (res.data === 1) {
                        message.success('信息保存成功！');
                        this.reloadMenu();
                        callback(true)
                    }
                } else {
                    message.error('环节办理失败，请稍后重试！');
                }
            });
    };

    render() {
        const { editable, imageUrl, uploadImg } = this.state;
        const userId = getLoginUser().userId;
        return (
            <div styleName="page_buy">
                {this.state.operable ?
                    <Flex className="w1180 mt-20" align="start">
                        <SiderMenu
                            ref={"siderMenu"}
                            setMenuList={this.setMenuList}
                            title="自助报到"
                            mkbz={this.state.mkbz}
                            menuType="自助报到"
                        />
                        <Flex.Item className="page_con_r">
                            <Breadcrumb
                                noLine
                                list={[
                                    { name: '首页', url: '/index' },
                                    { name: '自助报到', url: '/register' },
                                    { name: this.state.hjmc }
                                ]}
                            />
                            <div className="box">
                                <Hint />
                                {/* <TitleTop title={this.state.hjmc} extra={false} /> */}
                                <TitleTop title={this.state.hjmc} extra={true}
                                    onEdit={type => {
                                        this.setState({
                                            uploadImg: true
                                        });
                                    }}
                                    onSave={(callback) => {
                                        this.onSaveTxscFormData(callback);
                                    }}
                                    onClose={() => {
                                        this.setState({
                                            imageUrl: this.state.imageUrl,
                                            uploadImg: false
                                        });
                                    }}
                                />
                                <Form layout="inline">
                                    <div styleName="txsc" className="_bg">
                                        {uploadImg && (
                                            <Upload
                                                id='photoUpload'
                                                name="photo"
                                                accept={'image/*'}
                                                showUploadList={false}
                                                action={uploadUrl + "?xsid=" + userId}
                                                beforeUpload={this.beforeUpload}
                                                onChange={this.handleUploadChange}
                                            >
                                                {
                                                    imageUrl ?

                                                        <img
                                                            src={imageUrl}
                                                            alt=""
                                                            className="avatar"
                                                        />

                                                        : (
                                                            <div styleName="upload-box">
                                                                <div styleName="upload-title">
                                                                    上传图片
                                                </div>
                                                            </div>
                                                        )
                                                }
                                            </Upload>
                                        )}

                                        {!uploadImg && (
                                            <>
                                                {
                                                    imageUrl ?
                                                    <img
                                                        src={imageUrl}
                                                        alt=""
                                                        className="avatar"
                                                    />

                                                    : (
                                                        <div styleName="upload-box">
                                                            <div styleName="upload-title">
                                                                上传图片
                                                    </div>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                          
                                        }

                                    </div>
                                </Form>
                            </div>
                            <div className="text-center mt-50">
                                <NextButton nextUrl={this.state.nextUrl} search={this.state.search}/>
                            </div>
                        </Flex.Item>
                    </Flex>
                    : <CommonTip hjmc={"照片上传"} key={"GD015_7"} mkbz='GD015_7'
                        nonOperationalData={this.state.nonOperationalData} />}
            </div>
        )
    }
}
export default Form.create()(SinglePhotoForm)