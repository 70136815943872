import React from 'react';
import { Flex } from '@components';
import { Button, Progress, Table } from 'antd';
import TitleTop from '@pages/Assembly/titleTop';
import './print.css';
import NHFetch from "../../../../utils/NHFetch";
import {getLoginUser,createUuid} from "@utils/NHCore";
import createHistory from 'history/createHashHistory';
import BarCode from 'react-barcode';
import KzxxFormc from '../updateInfo/KzxxFormc';
const history = createHistory();

export default class PrintTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            xsxx:this.props.initFormDate,//学生信息
            schoolName:this.props.schoolName,//学校名称
            year:this.props.year,//当前年度
            account:this.props.account,//默认账号
            bddbz:this.props.bddbz,//备注
            kzxxInitData: this.props.kzxxInitData,
            nr:this.props.nr
        };
    }

    componentDidMount(){
        let userId = getLoginUser() ? getLoginUser().userId : undefined;
        if (userId) {
            this.getStudentPhotoBase64(userId, (data => {
                if (!data) return;
                this.setState({ 
                    xsid: userId,
                    imageUrl: `data:image/jpeg;base64,${data}`
                })
            })) 
        } 
    }

      // 获取学生头像
      getStudentPhotoBase64 =  (userId, callback) => {
        NHFetch('api/zhxg-yxwz/openapi/student/getStudentPhotoBase64', 'get', { xsid: userId, type: 10 })
        .then(res => {
            if (res && res.code == 200) {
                callback(res.data);
            }
        }); 
    }


    render() {
        const { xsxx, schoolName, year, nr, account, bddbz,kzxxInitData,imageUrl} = this.state;
        let sfxmList = [];
        sfxmList = xsxx.sfxmList;
        if(sfxmList) {
            for(let i=0;i<sfxmList.length;i++){
                sfxmList[i].row_id=i+1;
            }
        }
        const payColumns = [
            {
                title: '序号',
                dataIndex: 'row_id'
            },
            {
                title: '收费项目',
                dataIndex: 'sfxmmc'
            },
            {
                title: '应收金额',
                dataIndex: 'ysje'
            },
            {
                title: '已交金额',
                dataIndex: 'ssje',
                render: (text, record) => {
                    return <span className="text-primary">{text}</span>;
                }
            }
        ];
        const Columns = [
            {
                title: '序号',
                dataIndex: 'row_id'
            },
            {
                title: '主要内容',
                dataIndex: 'HJMC'
            }
        ];
       
        return (
                            <div className="box" style={{width:'95%'}}>
                                <div>
                                    <div className="text-center">
                                        <div styleName="title">
                                            {schoolName}{year}新生入学报到单
                                        </div>
                                    </div>

                                    <TitleTop title="基本信息" extra={false} />
                                    <Flex className="p-sw-big">
                                        <img styleName="jbxx_icon" className="_bg" src={imageUrl}
                                        onError={e => { e.target.src = stuDefault }}
                                        />
                                        <Flex.Item className="ml-20">
                                            {[
                                                {
                                                    label: '姓名',
                                                    name: xsxx.xm
                                                },
                                                {
                                                    label: '性别',
                                                    name: xsxx.xb
                                                },
                                                {
                                                    label: '考生号',
                                                    name: xsxx.ksh
                                                },
                                                {
                                                    label: '民族',
                                                    name: xsxx.mz
                                                },
                                                {
                                                    label: '政治面貌',
                                                    name: xsxx.zzmm
                                                },
                                                {
                                                    label: '籍贯',
                                                    name: xsxx.jg
                                                },
                                                {
                                                    label: '联系电话',
                                                    name: xsxx.sjhm
                                                },
                                                {
                                                    label: '身份证号',
                                                    name: xsxx.sfzjh
                                                },
                                                {
                                                    label: '考生类型',
                                                    name: xsxx.xslb
                                                },
                                                {
                                                    label: '学院',
                                                    name: xsxx.bmmc
                                                },
                                                {
                                                    label: '专业',
                                                    name: xsxx.zymc
                                                },
                                                {
                                                    label: '班级',
                                                    name: xsxx.bjmc
                                                }
                                            ].map((elem, index) => {
                                                return (
                                                    <div styleName="jbxx_item" key={index}>
                                                        <span styleName="item-label">{elem.label}：</span>
                                                        <div
                                                                className="t-clamp-1"
                                                                styleName="item-name"
                                                                style={{display: 'inline'}}
                                                            >
                                                        {elem.name}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </Flex.Item>
                                    </Flex>
                                    <KzxxFormc ref="refKzxxFormc" 
                                        initData={kzxxInitData}
                                    />
                                    <TitleTop title="宿舍信息" extra={false} />
                                    <div className="p-sw-big">
                                        <div styleName="ssxx_list">
                                            <div styleName="ssxx_item">
                                                <div styleName="ssxx_label">
                                                    校区：
                                                </div>
                                                <div styleName="ssxx_name">
                                                    {xsxx.xqmc}
                                                </div>
                                            </div>
                                            <div styleName="ssxx_item">
                                                <div styleName="ssxx_label">
                                                    楼栋：
                                                </div>
                                                <div styleName="ssxx_name">
                                                    {xsxx.ldmc}
                                                </div>
                                            </div>
                                        </div>
                                        <div styleName="ssxx_list">
                                            <div styleName="ssxx_item">
                                                <div styleName="ssxx_label">
                                                    房间：
                                                </div>
                                                <div styleName="ssxx_name">
                                                    {xsxx.fjmc}
                                                </div>
                                            </div>
                                            <div styleName="ssxx_item">
                                                <div styleName="ssxx_label">
                                                    床位：
                                                </div>
                                                <div styleName="ssxx_name">
                                                    {xsxx.cwh}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <TitleTop title="缴费信息" extra={false} />
                                    <div className="p-sw-big">
                                        <Table
                                            bordered
                                            size="middle"
                                            pagination={false}
                                            dataSource={sfxmList}
                                            columns={payColumns}
                                        />
                                    </div>
                                    <TitleTop title="二级学院报到" extra={false} />
                                    <div className="p-sw-big">
                                        <Table
                                            bordered
                                            size="middle"
                                            pagination={false}
                                            dataSource={nr}
                                            columns={Columns}
                                        />
                                    </div>
                                    <Flex className="p-sw-big mt-30">
                                        <Flex.Item>
                                            <div className="text-title">
                                                备注：
                                            </div>
                                            <div styleName="bz-item">{bddbz}</div>
                                            {/* <div styleName="bz-item">
                                                1.新生拿到报到单后，请到指定地点完成报到流程。
                                            </div>
                                            <div styleName="bz-item">
                                                2.未缴清费用的同学，需在报到期间缴清费用，如有特殊情况，请到学院迎新点处登记。
                                            </div>
                                            <div styleName="bz-item">
                                                3.报到单请务必保存好，报到结束后，由学院统一收回。
                                            </div> */}
                                        </Flex.Item>
                                        <BarCode styleName="bz-img"
                                        value={account}
                                        width={3}
                                        height={60}
                                        fontSize={18}
                                        >
                                        </BarCode>

                                    </Flex>
                                    </div>
                            </div>
        );
    }
}
