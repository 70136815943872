import { handle as nxmzzyjsxyHandle, syscBtnFunc as nxmzzyjsxyHandleSyscBtnFunc } from './nxmzzyjsxy.js';
import { handle as gdsxzyjsxyHandle, syscBtnFunc as gdsxzyjsxyHandleSyscBtnFunc } from './gdsxzyjsxy.js';
import { handle as zzsdjmglxyHandle, syscBtnFunc as zzsdjmglxyHandleSyscBtnFunc } from './zzsdjmglxy.js';
import { handle as wuhantieluHandle, syscBtnFunc as wuhantieluHandleSyscBtnFunc } from './wuhantielu.js';
import { handle as ynjtHandle, syscBtnFunc as ynjtHandleSyscBtnFunc } from './ynjt.js';
import { handle as scjtzyjsxyHandle, syscBtnFunc as scjtzyjsxyHandleSyscBtnFunc } from './scjtzyjsxy.js';
import { handle as jhyszyHandle, syscBtnFunc as jhyszyHandleSyscBtnFunc } from './jhyszy.js';
import { handle as tdtc_mmdlHandle, syscBtnFunc as tdtc_mmdlSyscBtnFunc } from './tdtc_mmdl.js';
import { handle as axf_mmdlHandle } from './axf_mmdl.js';
import { handle as payFxxy_tdtc_mmdlHandle } from './payFxxy_tdtc_mmdl.js';
import { handle as ynjd_mmdlHandle } from './ynjd_mmdl.js';
import { handle as scjt_mmdlHandle } from './scjt_mmdlHandle';
import { handle as xjjt_xjgxjfpt_mmdlHandle } from './xjjt_xjgxjfpt_mmdlHandle.js';
import { handle as xzkjHandle } from './xzkj_mmdl.js';

/**
 * 网上支付办理接口
 * @param {*} ddh 订单号
 * @param {*} csxx 配置参数信息，包含：pczflj(PC端支付路径),ydzflj(移动端支付路径)，zfts(支付提示),所有的自定义配置的参数信息
 * @param {*} xsxx 学生信息，包含：XSID(学生ID),XH(学号),XM(姓名),XBM(性别码),SFZJH(身份证件号),RXNF(入学年份),CSRQ(出生日期)
 * @param {*} jfxm 缴费信息，LIst<Map<String,Object>>对象,包含：sfxmid(收费项目ID),sfxmdm(收费项目代码),sfxmmc(收费项目名称)，je(金额)
 * @param {*} jyje 交易金额
 * @param {*} yjje 应缴金额
 * @param {*} ysje 已缴金额
 * @param {*} hjje 缓缴金额
 */
export const handle = (type, ddh, csxx, xsxx, jfxm, jyje, yjje, ysje, hjje, buttonLoadingCallBack) => {
  if (type === 'nxmzzyjsxy') {//宁夏民族职业技术学院
    nxmzzyjsxyHandle(ddh, csxx, xsxx, jfxm, jyje, yjje, ysje, hjje);
  } else if (type === 'gdsxzyjsxy') {//广东松山技术学院
    gdsxzyjsxyHandle(ddh, csxx, xsxx, jfxm, jyje, yjje, ysje, hjje);
  } else if (type === 'zzsdjmglxy') {//郑州升达经贸管理学院
    zzsdjmglxyHandle(ddh, csxx, xsxx, jfxm, jyje, yjje, ysje, hjje);
  } else if (type === 'wuhantieluanxinfu') { // 武汉铁路
    wuhantieluHandle(ddh, csxx, xsxx, jfxm, jyje, yjje, ysje, hjje)
  } else if (type === 'ynjt') { //云南交通
    ynjtHandle(ddh, csxx, xsxx, jfxm, jyje, yjje, ysje, hjje);
  } else if (type === 'scjtzyjsxy' || type === 'hzyyzx_scjtzyjsxy_cwcj_cwcjjk_mmdl') { //四川交通职业技术学院
    scjtzyjsxyHandle(ddh, csxx, xsxx, jfxm, jyje, yjje, ysje, hjje);
  } else if (type === 'tdtc_mmdl' || type === 'tdtc_1_cwcj_zjk_mmdl') { //天大天财-免密登录
    tdtc_mmdlHandle(ddh, csxx, xsxx, jfxm, jyje, yjje, ysje, hjje);
  } else if (type === 'yjxc_jhyszy_cwcj_cwcjjk_mmdl') { //江汉艺术职业-免密登录
    jhyszyHandle(ddh, csxx, xsxx, jfxm, jyje, yjje, ysje, hjje);
  } else if (type === 'axf_1_cwcj_jk_mmdl') {
    axf_mmdlHandle(csxx, xsxx);
  } else if (type === 'tdtc_2_cwcj_jk_mmdl') {
    payFxxy_tdtc_mmdlHandle(csxx, xsxx);
  } else if (type === 'yzjd_1_cwcj_zjk') {
    ynjd_mmdlHandle(csxx, xsxx);
  } else if (type === 'fjbs_1_cwcj_cwcj_cwcjjk_2mmdl') {
    scjt_mmdlHandle(csxx, xsxx);
  } else if (type === 'xjjt_xjgxjfpt') {
    xjjt_xjgxjfpt_mmdlHandle(ddh, csxx, xsxx, jfxm, buttonLoadingCallBack);
  } else if (type === 'xzkj_1_cwcj_cwcjjk_mmdl') {//学之科技--免密登录
    xzkjHandle(ddh, csxx, xsxx);
  }
}

/**
 * 点击同步按钮触发时的操作
 * @param {*} ddh 订单号
 */
export const syscBtnFunc = (type, ddh) => {
  if (type === 'nxmzzyjsxy') {//宁夏民族职业技术学院
    nxmzzyjsxyHandleSyscBtnFunc(ddh);
  }
}
