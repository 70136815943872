import React from 'react';
import PropTypes, { number } from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';
import { Row, Col, Button, Icon, Card, Form, Input, Divider, DatePicker, message, Alert, Spin, Modal } from 'antd';
import { getLoginUser, hasAuth } from "@utils/NHCore";
import NHFormItem from '@components/common/NHFormItem/index';
import FormRules from '@components/common/NHFormItem/FormRules';
import NHFetch from "@utils/NHFetch";
import moment from 'moment';
import NHInputRender from '@components/common/NHInputPicker/render';
import '../index.css';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import { Link } from 'react-router-dom';
import { Flex } from '@components';

const FormItem = Form.Item;
const basicUrl = "/zzbd";
const stuInfoUrl = "/xxcj/";


//日期格式化
const dateFormat = 'YYYY-MM-DD';
let xlkList={};
class KzxxFormComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            xsid: '',
            kzxx: false,
            scrollHeight: 0, //默认滚动高度
            initData: {}, //初始数据
            loading: false, //页面加载状态
            onSubmit: false, //保存按钮加载状态
            isShow: false,
            previewVisible: false,
            formData: '',
            dmkmcList: {},
            zdzmc: '',
            flag: false,
            needReloadNHcascader: false
        }
    }

    componentDidMount() {
        this.getOptionData(this.props.initData)
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.initData !== this.props.initData) {
            this.getOptionData(nextProps.initData)
        }
    }

    submitCheck =(flag) => {
        const submitCheck = this.props.submitCheck;
        if(typeof submitCheck ==="function"){
            submitCheck(flag);
        }
    }


    onSaveKzxx = (cb) => {
        this.props.form.validateFields((error, values) => {
            if (error) {
                return;
            }
            cb(true)

            const { initData } = this.props;
            Object.keys(values).forEach(function (key) {
                if (values[key] != null && values[key] != undefined) {
                    if (values[key].constructor.name == 'Moment') {   //处理Moment格式的数据  
                        //values[key] = moment(values[key]).format(dateFormat); 
                    } else if (values[key].constructor.name == 'Array') {
                        if (typeof values[key] === 'object') {
                            let isFileUpload = false;
                            let array = [];
                            for (let item of values[key]) {
                                if (item.hasOwnProperty('uuid') && item['uuid']) {
                                    isFileUpload = true;
                                    array.push(item['uuid'])
                                }
                            }
                            if (isFileUpload) {
                                values[key] = array;
                            }
                        } else {
                            values[key] = values[key];
                        }
                    }
                } else {
                    values[key] = '';
                }
            })

            let array = initData.VALUE.filter((item) => item.SFKBJ === "1").map((item, index) => {
                let { BM, ZD, ZDM, GLZD, SFBT, KJCS } = item; //表单字段
                const { type, format } = JSON.parse(KJCS || "{}");
                let nhcasZdz;
                values[ZD] =
                    type === "datepicker"
                        ? values[ZD] && moment(values[ZD]).format(format)
                        : values[ZD];
                if(type == "nhcascader") {
                    nhcasZdz = values[ZD];
                    values[ZD] = values[ZD] && values[ZD][values[ZD].length -1 ];
                }
                const temp = {
                    ZD,
                    ZDM,
                    CSZ: values[ZD],
                    BM,
                    GLZD,
                    SFBT,
                };
                if(type == "nhcascader") {
                    values[ZD] = nhcasZdz;
                }
                return temp;
            })

            this.props.cacheFormData([initData.FLDM], array, null, 2)
            this.setState({
                formData: { ...values },
                kzxx: false,
                dmkmcList: []
            });
            // NHFetch(`/zzbd/createOrUpdateXscjXx?xsid=${this.props.xsid}&fllx=2`, 'POST', { [initData.FLDM]: array })
            //     .then(res => {
            //         if (res && res.code == 200) {
            //             message.success("保存成功");

            //             cb(true);
            //             this.props.reloadMenu();
            //         } else {
            //             message.error("保存失败");
            //         }
            //     });
        });
    }


    //获取下拉列表value对应的名称
    getDmkMc = (dmbz, zdbz) => {
      let dmkmcList = this.state.dmkmcList;
        if(xlkList[dmbz]){
          dmkmcList[zdbz] = xlkList[dmbz];
          this.setState({
            dmkmcList: dmkmcList
           })
        }else {
          NHFetch("/proData/selectDataList", "GET", { sign: dmbz })
          .then(res => {
              if (res) {
                  if (res.data) {
                      dmkmcList[zdbz] = res.data;
                      xlkList[dmbz] = res.data;
                      this.setState({
                          dmkmcList: dmkmcList
                      })
                  }
              }
          });
        }
    }
    //获取联级下拉列表value对应的名称
    getCascaderDmkMc = (level, sign, cascaderValue, zdbz) => {
        console.log('getCascaderDmkMc')
        NHFetch("/proData/selectCascaderList", "GET", { level: level, sign: sign, cascaderValue: cascaderValue })
            .then(res => {
                if (res) {
                    if (res.data) {
                        let dmkmcList = this.state.dmkmcList;
                        dmkmcList[level + zdbz] = res.data;
                        this.setState({
                            dmkmcList: dmkmcList
                        })
                    }
                }
            });
    }
    // 点击文件链接或预览图标时的回调
    handlePreview = (fjmc, fjid) => {

        if (fjmc === "isImage") {
            this.showPreview(`anonymity/docrepo/download/file?attachmentId=${fjid}`);
        } else {
            window.open(`anonymity/docrepo/download/file?attachmentId=${fjid}`);
        }
    };

    //打开图片弹窗
    showPreview = (url) => {
        this.setState({
            previewImage: url,
            previewVisible: true
        });
    }
    // 关闭图片弹窗
    closeModal = () => this.setState({ previewVisible: false });
    // 文件展示 
    fileLists = (fileLists) => {
        {
            let items = [];
            for (let key in fileLists) {
                let fileList = typeof fileLists[key] == 'string' ? fileLists[key].split(";") : fileLists[key];
                items.push(
                    <div >
                        <a onClick={() => {
                            this.handlePreview("file", fileList[0]);
                        }}>{fileList[1]}</a>
                    </div>
                )
            }
            return items;
        }
    };
    //图片展示
    imageLists = (imageLists) => {
        {
            let items = [];
            for (let key in imageLists) {
                let imageList = typeof imageLists[key] == 'string' ? imageLists[key].split(";") : imageLists[key];
                items.push(
                    <span>
                        <img style={{ height: "80px", width: "80px" }} src={"anonymity/docrepo/download/file?attachmentId=" + imageList}
                            onClick={() => {
                                this.handlePreview("isImage", imageList[0]);
                            }} />
                    </span>
                )
            }
            return items;
        }
    }

    onChange = (value) => {
        // console.log('formData',value)
        // this.setState({
        //     formData: value
        // })
    }

    getOptionData = (initData)=>{
        const { formData, dmkmcList } = this.state;
        if (initData) {
            //表单信息、表单字段信息
            if (initData.VALUE != null) {
                if (initData.VALUE.length > 0) {
                    for (let key in initData.VALUE) {
                        //字段状态、是否必填、默认值、字段标识、字段名称、控件参数、字段值
                        // let { zdbz, zdmc, kjcs, zdz, sfbt , mrz, zdzt} = initData.VALUE[key]; //表单字段
                        // zdbz == ZD、 zdmc == ZDM zdz == CSZ sfbt == SFBT mrz == defaultValue zdzt == SFKBJ
                        //字段标识、字段名称、字段值、控件参数、是否必填、字段是否可编辑、只读视图
                        let { ZD, CSZ, KJCS } = initData.VALUE[key]
                        const zdstCom = { type: "input" };
                        KJCS = KJCS || JSON.stringify(zdstCom);
                        let kjcsjson = JSON.parse(KJCS || '{}');
                        if (formData && formData[ZD] != null) {
                            CSZ = formData[ZD];
                        }
                        if (kjcsjson.type === "select") {
                            if (kjcsjson.dataTag && !dmkmcList[ZD]) {
                                this.getDmkMc(kjcsjson.dataTag, ZD);
                            }
                        } else if (kjcsjson.type === "multiselect") {
                            if (kjcsjson.dataTag && !dmkmcList[ZD]) {
                                this.getDmkMc(kjcsjson.dataTag, ZD);
                            }
                        } else if (kjcsjson.type === "nhcascader") {
                            if (CSZ) {
                                let nhcascaderList = typeof CSZ == 'string' ? CSZ.slice(1, -1).split(/\s*,\s*/) : CSZ;
                                for (let i in nhcascaderList) {
                                    // i 是字符串
                                    let index = Number.parseInt(i)
                                    let level = index + 1;
                                    if (kjcsjson.dataTag && !dmkmcList[level + ZD]) {
                                        if (index === 0) {
                                            this.getCascaderDmkMc(level, kjcsjson.dataTag, '', ZD)
                                        }
                                        else {
                                            this.getCascaderDmkMc(level, kjcsjson.dataTag, nhcascaderList[index - 1], ZD)
                                        }
                                    }
                                   
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    render() {
        const { form, initData, editable, isRequired } = this.props;
        const { kzxx, formData, previewVisible, previewImage, dmkmcList } = this.state;
        //提示信息
        let AlertParams = {
            message: '当前没有需要填写的信息',
            description: '',
            type: 'info'
        }
        //信息采集字段分类
        const infoItems = [];
        let infoRow = []
        let reactid = 0;
        // if (initData.FLMC === "学生一卡通信息456") {
        //     console.log('initData',initData)

        // }
        if (initData) {
            //表单信息、表单字段信息
            if (initData.VALUE != null) {
                if (initData.VALUE.length > 0) {
                    let array = [];
                    //循环过滤出多行文本框的key，偶数在左，奇数在右，相应的同行的另一条文本也需要加宽
                    for (let key in initData.VALUE) {
                        let { KJCS } = initData.VALUE[key];
                        const zdstCom = { type: "input" };
                        KJCS = KJCS || JSON.stringify(zdstCom);
                        let kjcsjson = JSON.parse(KJCS || '{}');
                        if (kjcsjson.type === "textarea" || kjcsjson.type === "oldupload" || kjcsjson.type === "upload") {
                            if (key % 2 === 0) {
                                array.push(Number.parseInt(key));
                                array.push(Number.parseInt(key) + 1);
                            } else {
                                array.push(Number.parseInt(key));
                                array.push(Number.parseInt(key) - 1);
                            }
                        }
                    }
                    //显示表单字段信息
                    for (let key in initData.VALUE) {
                        //字段状态、是否必填、默认值、字段标识、字段名称、控件参数、字段值
                        // let { zdbz, zdmc, kjcs, zdz, sfbt , mrz, zdzt} = initData.VALUE[key]; //表单字段
                        // zdbz == ZD、 zdmc == ZDM zdz == CSZ sfbt == SFBT mrz == defaultValue zdzt == SFKBJ
                        //字段标识、字段名称、字段值、控件参数、是否必填、字段是否可编辑、只读视图
                        let { ZD, ZDM, CSZ, KJCS, SFBT, SFKBJ, ZDST } = initData.VALUE[key]
                        let zdzmc = '';
                        const zdstCom = { type: "input" };
                        KJCS = KJCS || JSON.stringify(zdstCom);
                        let kjcsjson = JSON.parse(KJCS || '{}');
                        let { defaultValue } = kjcsjson; //默认值
                        if (formData && formData[ZD] != null) {
                            CSZ = formData[ZD];
                        }
                        if (kjcsjson.type === 'datepicker' || kjcsjson.type === 'datetimepicker' || kjcsjson.type === 'timepicker' || kjcsjson.type === 'monthpicker' || kjcsjson.type === 'rangepicker') {    //日期值展示阶段，从moment转为文字 
                            if (CSZ) {

                                zdzmc = CSZ && moment(CSZ).format(kjcsjson.format);
                            }
                        } else if (kjcsjson.type === "select") {
                            let list = dmkmcList[ZD];
                            list && list.map(item => {
                                if (item.VALUE === CSZ) {
                                    zdzmc = item.LABEL;
                                }
                            })
                        } else if (kjcsjson.type === "multiselect") {
                            let list = dmkmcList[ZD];
                            if (CSZ) {
                                let multiselect = typeof CSZ == 'string' ? CSZ.split(",") : CSZ;
                                let multiselectList = [];
                                for (let i in multiselect) {
                                    list && list.map(item => {
                                        if (item.VALUE === multiselect[i]) {
                                            multiselectList.push(item.LABEL);
                                        }
                                    })
                                }
                                zdzmc = multiselectList.join(",");
                            }
                        } else if (kjcsjson.type === "nhcascader") {
                            if (CSZ) {
                                let nhcascaderList = typeof CSZ == 'string' ? CSZ.slice(1, -1).split(/\s*,\s*/) : CSZ;
                                let nhcascaderLists = [];
                                for (let i in nhcascaderList) {
                                    // i 是字符串
                                    let index = Number.parseInt(i)
                                    let level = index + 1;
                                    // console.log('a + ZD', level + ZD, this.state.dmkmcList)
                                    let list = dmkmcList[level + ZD];

                                    list && list.map(item => {
                                        // console.log(item.value, nhcascaderList[i])
                                        if (item.value === nhcascaderList[index]) {
                                            nhcascaderLists.push(item.label);
                                        }
                                    })
                                }
                                zdzmc = nhcascaderLists.join("/");
                            }
                        } else if (kjcsjson.type === "switch") {
                            zdzmc = (CSZ === "1" || CSZ === true) ? kjcsjson.checkedText : kjcsjson.uncheckedText;
                        } else if (kjcsjson.type === "radio") {
                            kjcsjson.options && kjcsjson.options.map((item, key) => {
                                if (item.value === CSZ) {
                                    zdzmc = item.label;
                                }
                            })
                        } else if (kjcsjson.fileType === "file" && CSZ) {
                            let fileLists = typeof CSZ == 'string' ? CSZ.split(",") : CSZ;
                            zdzmc = (
                                this.fileLists(fileLists)
                            )
                        } else if (kjcsjson.fileType === "image" && CSZ) {
                            console.log('image',ZD,CSZ)
                            
                            if (typeof CSZ === 'object') {
                                // let array = [];
                                // for (let item of CSZ) {
                                //     if (item.hasOwnProperty('uuid') && item['uuid']) {
                                //         array.push(item['uuid'])
                                //     }
                                // }
                                // CSZ = array
                            } else {
                                CSZ = CSZ.slice(1, -1)
                                CSZ = CSZ.split(/\s*,\s*/);
                                if (CSZ.length === 1 && CSZ[0].length === 0) {
                                    CSZ = []
                                }
                                console.log('IMAGE',ZD, CSZ)

                            }
                            zdzmc = (
                                this.imageLists(CSZ)
                            )
                            console.log('zdzmc',ZD,zdzmc)
                        } else {
                            zdzmc = CSZ;
                        }
                        let required = SFBT === "1" && !Boolean(ZDST)
                        let readOnly = SFKBJ !== "1" || Boolean(ZDST); //是否只读
                        let formItem;
                        CSZ = formData ? formData[ZD] : CSZ
                        if (required) {
                            formItem = (kjcsjson.type === 'textarea' || kjcsjson.type === "oldupload" || kjcsjson.type === "upload" ?
                                (
                                    <div key={'div' + reactid++}
                                        style={(kzxx && array.indexOf(Number.parseInt(key)) !== -1 ? { minHeight: "120px" } : {})}
                                        styleName={"kzxx_item_textarea"}
                                    >

                                        <div styleName="kzxx_label"><span style={{ color: 'red' }}>*</span>{ZDM}：</div>
                                        <div styleName="kzxx_name_textarea">
                                            {
                                                kzxx && !readOnly ?
                                                    <NHInputRender key={key} id={ZD} label={''} defaultValue={defaultValue}
                                                        value={kjcsjson.validationValueType === 'array' ? (CSZ && typeof CSZ == 'string' ? CSZ.slice(1,-1).split(/\s*,\s*/) : CSZ ? CSZ : []) : CSZ}
                                                        required={required}
                                                        disabled={kzxx ? false : true}
                                                        form={form} formLayout={'vertical'} feature={KJCS} />
                                                    :
                                                    zdzmc

                                            }
                                        </div>
                                    </div>

                                )
                                :
                                (
                                    <div key={'div' + reactid++}
                                        style={(kzxx && array.indexOf(Number.parseInt(key)) !== -1 ? { minHeight: "71px" } : {})}
                                        styleName={"kzxx_item"}
                                    >

                                        <div styleName="kzxx_label"><span style={{ color: 'red' }}>*</span>{ZDM}：</div>
                                        <div styleName="kzxx_name">
                                            {
                                                kzxx && !readOnly ?
                                                    <NHInputRender key={key} id={ZD} label={''} defaultValue={defaultValue}
                                                        value={kjcsjson.validationValueType === 'array' ? (CSZ && typeof CSZ == 'string' ? CSZ.slice(1,-1).split(/\s*,\s*/) : CSZ ? CSZ : []) : CSZ}
                                                        required={required}
                                                        disabled={kzxx ? false : true}
                                                        form={form} formLayout={'vertical'} feature={KJCS}
                                                        onChange={this.onChange}
                                                    />
                                                    :
                                                    zdzmc
                                            }
                                        </div>
                                    </div>

                                ));
                        } else {
                            formItem = (kjcsjson.type === 'textarea' || kjcsjson.type === "oldupload" || kjcsjson.type === "upload" ?
                                (
                                    <div key={'div' + reactid++}
                                        style={kzxx && array.indexOf(Number.parseInt(key)) !== -1 ? { minHeight: "120px" } : {}}
                                        styleName={"kzxx_item_textarea"}
                                    >
                                        <div styleName="kzxx_label">{ZDM}：</div>
                                        <div styleName="kzxx_name_textarea">
                                            {
                                                kzxx && !readOnly ?
                                                    <NHInputRender key={key} id={ZD} label={''} defaultValue={defaultValue}
                                                        value={kjcsjson.validationValueType === 'array' ? (CSZ && typeof CSZ == 'string' ? CSZ.slice(1,-1).split(/\s*,\s*/) : CSZ ? CSZ : []) : CSZ}
                                                        required={required}
                                                        disabled={kzxx ? false : true}
                                                        form={form} formLayout={'vertical'} feature={KJCS} />
                                                    :
                                                    zdzmc
                                            }
                                        </div>
                                    </div>

                                )
                                :
                                (
                                    <div key={'div' + reactid++}
                                        style={(kzxx && array.indexOf(Number.parseInt(key)) !== -1 ? { minHeight: "71px" } : {})}
                                        styleName={"kzxx_item"}
                                    >
                                        <div styleName="kzxx_label">{ZDM}：</div>
                                        <div styleName="kzxx_name">
                                            {
                                                kzxx && !readOnly ?
                                                    <NHInputRender key={key} id={ZD} label={''} defaultValue={defaultValue}
                                                        value={kjcsjson.validationValueType === 'array' ? (CSZ && typeof CSZ == 'string' ? CSZ.slice(1,-1).split(/\s*,\s*/) : CSZ ? CSZ : []) : CSZ}
                                                        required={required}
                                                        disabled={kzxx ? false : true}
                                                        form={form} formLayout={'vertical'} feature={KJCS}
                                                        onChange={this.onChange}
                                                    />
                                                    :
                                                    zdzmc
                                            }
                                        </div>
                                    </div>

                                ));

                        }

                        if ((Number.parseInt(key) + 1) % 2 === 0 || Number.parseInt(key) === initData.VALUE.length - 1) {
                            infoRow.push(formItem)
                            infoItems.push(
                                <div styleName='kzxx_items_row'>
                                    {infoRow}
                                </div>
                            )
                            infoRow = []
                        } else {
                            infoRow.push(formItem)
                        }
                        // infoItems.push(formItem);
                    }
                } else {
                    //未找到表单配置的采集字段信息
                    AlertParams['message'] = '暂时没有需要填写的信息';
                    AlertParams['description'] = '当前未配置采集信息项';
                    AlertParams['type'] = 'warning';
                }
            }
        }

        return (
            <div>
                {infoItems.length > 0 &&
                    <div>
                        <TitleTop
                            extra={editable}
                            title={<span><span style={{ display: isRequired ? '' : 'none', color: 'red' }}>*</span>{this.props.initData.FLMC}</span>}
                            onEdit={type => {
                                this.setState({
                                    kzxx: true
                                });
                                this.submitCheck(false);
                            }}
                            onSave={cb => {
                                this.onSaveKzxx(cb);
                            }}
                            onClose={() => {
                                this.setState({
                                    kzxx: false
                                });
                                this.submitCheck(true);
                            }}
                        />
                        {
                            infoItems.length > 0 ?
                                <Form>
                                    <div className="p-sw">
                                        <div styleName="kzxx_list">
                                            {infoItems}
                                        </div>
                                    </div>
                                </Form>
                                :
                                <Alert style={{ marginTop: 20 }}
                                    {...AlertParams}
                                    showIcon
                                />
                        }
                        <Modal
                            visible={previewVisible}
                            footer={null}
                            onCancel={this.closeModal}
                        >
                            <img
                                alt="example"
                                style={{ width: '100%' }}
                                src={previewImage}
                            />
                        </Modal>
                    </div>
                }
                <style jsx="true" global="true">
                    {`

                            .app__css__app-pages-SelfHelp-updateInfo-__index__kzxx_item {
 
                                height: 100% !important; 
                            }
                            .app__css__app-pages-SelfHelp-updateInfo-__index__kzxx_label {
                                background-color: #fff;
                            } 
                            .app__css__app-pages-SelfHelp-updateInfo-__index__kzxx_name { 
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                width: 67%;
                            }
                        `}
                </style>
            </div>
        )
    }

}

const KzxxForm = Form.create()(KzxxFormComponent);
export default KzxxForm;