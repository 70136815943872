import React from 'react'
import { Table, Input, Icon, Form, message } from 'antd'
import TitleTop from '@pages/Assembly/titleTop'
import { createUuid } from '@utils/NHCore'
import './index.css'
import NHSelect from '@components/common/NHSelect'
import NHFetch from '@utils/NHFetch'
import { FormRules, regPhone } from '@utils/RegExp'
import { deepCopy } from '@utils/deepCopy'
const FormItem = Form.Item

class JtcyForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      jtcy: false,
      jtcyAdd: false,
      jtcyData: [],
      zzmmmList: [],
      tempStr: '',
      jtcyList: [], //家庭成员数据加载
      errorFiled: false,
      flag: false,
    }
  }

  componentDidMount() {
    NHFetch(
      'front/zhxg-unauth/yxwz/proData/selectDataList?&sign=DMK_XTGL_ZZMMM',
      'GET'
    ).then(res => {
      if (res && res.data) {
        let zzmmmList = []
        for (let obj of res.data) {
          zzmmmList.push({ label: obj.LABEL, value: obj.VALUE })
        }
        this.setState({ zzmmmList: zzmmmList })
      }
    })
    NHFetch(
      'front/zhxg-unauth/yxwz/proData/selectDataList?&sign=DMK_XSXX_JTCYGX',
      'GET'
    ).then(res => {
      if (res && res.data) {
        let jtcyList = []
        for (let obj of res.data) {
          jtcyList.push({ label: obj.LABEL, value: obj.VALUE })
        }
        this.setState({ jtcyList: jtcyList })
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    if ('jtcyInitData' in nextProps && nextProps.jtcyInitData) {
      if (nextProps.jtcyInitData instanceof Array) {
        let temp = [...nextProps.jtcyInitData]
        if (!this.state.flag && temp.length > 0)
          this.setState({ jtcyData: temp, flag: true })
      }
    }
  }

  cacheJtcyData = cb => {
    this.props.form.validateFields((err, values) => {
      const { jtcyData, errorFiled } = this.state
      if (err || errorFiled) return cb(false)
      //判断数据是否全部填写
      let dataList = []
      if (jtcyData && jtcyData.length > 0) {
        for (let i = 0; i < jtcyData.length; i++) {
          let data = jtcyData[i]
          if (!data.gx && !data.xm && !data.gzxxdw && !data.lxfs) {
            return message.error(`请完善第${i + 1}行信息再保存`)
          } else {
            dataList.push(data)
          }
        }
      }

      const { cacheFormData, getChildComponent } = this.props
      getChildComponent(3, true)
      cacheFormData('jtcy', dataList, true)
      cb(true)
      this.setState({
        jtcy: false,
        jtcyAdd: false,
      })
    })
  }

  // 家庭成员 添加
  onjtcyAdd = () => {
    let { jtcyAdd, jtcyData = [] } = this.state
    if (!jtcyAdd) return message.error('操作逻辑异常!')
    jtcyData.push({
      key: createUuid(),
      gx: '',
      xm: '',
      gzxxdw: '',
      zzmmm: '',
      lxfs: '',
      operation: '',
      qq: '',
      wx: '',
      errorFiled: false,
    })
    this.setState({ jtcyData })
  }
  // 家庭成员 保存添加
  onjtcySave = () => {
    let { jtcyData } = this.state
    jtcyData.push()
    this.setState({
      jtcyData: jtcyData,
      jtcyAdd: false,
    })
  }
  // 家庭成员 关闭添加
  onjtcyClose = (e, index) => {
    e.preventDefault()
    let { jtcyData } = this.state
    jtcyData.splice(index, 1)
    this.setState({
      jtcyData: jtcyData,
      jtcyAdd: true,
    })
  }

  handleChange = (value, index, key) => {
    const errorFiled = key === 'lxfs' && !regPhone(value)
    let jtcyData = this.state.jtcyData
    jtcyData[index][key] = value
    jtcyData[index]['errorFiled'] = errorFiled
    this.setState({ jtcyData, errorFiled })
  }

  getzzmmmStr = value => {
    let temp = ''
    this.state.zzmmmList.forEach(i => {
      if (i.value === value) {
        temp = i.label
      }
    })
    return temp
  }

  //   deepCopy = obj => {
  //     if (typeof obj != 'object') {
  //       return obj
  //     }

  //     if (this.isArrayFn(obj)) {
  //       let newobj = []
  //       for (let i = 0; i < obj.length; i++) {
  //         newobj.push(this.deepCopy(obj[i]))
  //       }
  //       return newobj
  //     } else {
  //       let newobj = {}
  //       for (var attr in obj) {
  //         newobj[attr] = this.deepCopy(obj[attr])
  //       }
  //       return newobj
  //     }
  //   }

  //   isArrayFn = value => {
  //     if (typeof Array.isArray === 'function') {
  //       return Array.isArray(value)
  //     } else {
  //       return Object.prototype.toString.call(value) === '[object Array]'
  //     }
  //   }

  render() {
    const { jtcy, jtcyData, jtcyAdd, jtcyList } = this.state
    const { getChildComponent, form } = this.props
    let jtcyTransalate = {} //家庭成员翻译
    for (let i = 0; i < jtcyList.length; i++) {
      jtcyTransalate[jtcyList[i].value] = jtcyList[i].label
    }
    const jtcyColumns = [
      {
        title: '关系',
        key: 'gx',
        dataIndex: 'gx',
        render: (text, record, index) => {
          return jtcy ? (
            <NHSelect
              style={{ width: 100 }}
              sign='DMK_XSXX_JTCYGX'
              value={text}
              onChange={value => {
                this.handleChange(value, index, 'gx')
              }}
            />
          ) : (
            jtcyTransalate[text]
          )
        },
      },
      {
        title: '姓名',
        dataIndex: 'xm',
        render: (text, record, index) => {
          return jtcy ? (
            <Input
              value={text}
              onChange={e => {
                this.handleChange(e.target.value, index, 'xm')
              }}
              style={{ width: 100 }}
              placeholder='请填写姓名'
            />
          ) : (
            text
          )
        },
      },
      // {
      //     title: '政治面貌',
      //     dataIndex: 'zzmmm',
      //     render: (text, record,index) => {
      //         //let tempStr = '';
      //         return(
      //             jtcy ?
      //             <NHSelect disabled = {jtcy ? false : true} style={{ width: 140 }} sign="DMK_XTGL_ZZMMM"
      //             value={text}
      //             onChange={(value) =>{this.handleChange(value,index,'zzmmm')}}

      //             />
      //             :
      //             (this.getzzmmmStr(text))
      //         );
      //     }
      // },
      {
        title: '工作单位及职务',
        dataIndex: 'gzxxdw',
        render: (text, record, index) => {
          return jtcy ? (
            <Input
              value={text}
              onChange={e => {
                this.handleChange(e.target.value, index, 'gzxxdw')
              }}
              style={{ width: 140 }}
              placeholder='请填写单位和职务'
            />
          ) : (
            text
          )
        },
      },
      {
        title: '联系电话',
        dataIndex: 'lxfs',
        render: (text, record, index) => {
          return jtcy ? (
            <FormItem
              form={form}
              style={{ marginBottom: '0px', width: '140px' }}
            >
              <Input
                value={text}
                onChange={e => {
                  this.handleChange(e.target.value, index, 'lxfs')
                }}
                style={{ width: 110 }}
                placeholder='请填写电话'
              />
              <div
                style={{
                  display: record.errorFiled ? 'block' : 'none',
                  color: '#ff4d4f',
                }}
              >
                {text ? FormRules.MOBILE_PHONE_NUMBER.message : '请填写电话'}
              </div>
            </FormItem>
          ) : (
            text
          )
        },
      },
      {
        title: 'QQ',
        dataIndex: 'qq',
        render: (text, record, index) => {
          return jtcy ? (
            <Input
              value={text}
              onChange={e => {
                this.handleChange(e.target.value, index, 'qq')
              }}
              style={{ width: 100 }}
              placeholder='请填QQ号'
            />
          ) : (
            text
          )
        },
      },
      {
        title: '微信',
        dataIndex: 'wx',
        render: (text, record, index) => {
          return jtcy ? (
            <Input
              value={text}
              onChange={e => {
                this.handleChange(e.target.value, index, 'wx')
              }}
              style={{ width: 100 }}
              placeholder='请填微信号'
            />
          ) : (
            text
          )
        },
      },
      {
        title: '删除',
        dataIndex: 'operation',
        width: 80,
        render: (text, record, index) => {
          if (jtcy) {
            return (
              <div className='editable-row-operations'>
                <a href='#' onClick={e => this.onjtcyClose(e, index)}>
                  <Icon type='close' style={{ color: 'red' }} />
                </a>
              </div>
            )
          }
          return (
            <a href='#' onClick={e => this.onjtcyClose(e, index)}>
              <Icon type='delete' style={{ color: 'red' }} />
            </a>
          )
        },
      },
    ]
    if (!jtcy) {
      jtcyColumns.pop()
    }
    return (
      <div>
        <TitleTop
          title='家庭成员信息'
          onEdit={type => {
            this.setState({
              jtcy: true,
              jtcyAdd: true,
              cacheData: deepCopy(jtcyData),
            })
            getChildComponent(3, false)
          }}
          onSave={cb => {
            this.cacheJtcyData(cb)
          }}
          onClose={() => {
            this.setState({
              errorFiled: false,
              jtcy: false,
              jtcyAdd: false,
              jtcyData: deepCopy(this.state.cacheData),
            })
          }}
        />

        <Form>
          <div className='p-sw'>
            <Table
              bordered
              size='middle'
              pagination={false}
              dataSource={jtcyData}
              columns={jtcyColumns}
            />
            {jtcyAdd && (
              <div
                className='mt-10'
                styleName='btn_add'
                onClick={this.onjtcyAdd}
              >
                添加家庭成员
              </div>
            )}
          </div>
        </Form>
      </div>
    )
  }
}
export default Form.create()(JtcyForm)
