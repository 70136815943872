import React from "react";
import { Link } from 'react-router-dom';
import { Flex } from '@components';
import {
    Button,
    DatePicker,
    Progress,
    Popconfirm,
    Table,
    Input,
    Icon,
    Select,
    Upload,
    Form,
    message
} from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import { createUuid } from "@utils/NHCore";
import './index.css';
import NHFormItem from '@components/common/NHFormItem';
import NHSelect from "@components/common/NHSelect";
import NHFetch from '@utils/NHFetch';
import { FormRules } from "@utils/RegExp";
const FormItem = Form.Item;


class ZyshgxForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            zyshgx: false,
            zyshgxAdd: false,
            zyshgxData: [],
            zzmmmList: [],
            tempStr: '',
        }
    }

    componentDidMount() {
        NHFetch('front/zhxg-unauth/yxwz/proData/selectDataList?&sign=DMK_XTGL_ZZMMM', 'GET')
            .then(res => {
                if (res && res.data) {
                    let zzmmmList = [];
                    for (let obj of res.data) {
                        zzmmmList.push({ 'label': obj.LABEL, 'value': obj.VALUE });
                    }
                    this.setState({ zzmmmList: zzmmmList });
                }
            });
    }

    componentWillReceiveProps(nextProps) {
        if ('zyshgxInitData' in nextProps && nextProps.zyshgxInitData) {
            if (nextProps.zyshgxInitData instanceof Array) {
                let temp = [...nextProps.zyshgxInitData];
                this.setState({ zyshgxData: temp });
            }

        }
    }

    cacheZyshgxData = (cb) => {
        this.props.form.validateFields((err, values) => {
            if (err) return cb(false);
            //判断数据是否全部填写
            let dataList = [];
            let errorPhone = "";
            let errorList = [];
            if (this.state.zyshgxData && this.state.zyshgxData.length > 0) {
                for (let i = 0; i < this.state.zyshgxData.length; i++) {
                    let data = this.state.zyshgxData[i];
                    let pattern = /^1\d{10}$/;
                    if(data.lxfs){
                        if(!pattern.test(data.lxfs)){
                            if(errorList.length == 0){
                                errorPhone += data.lxfs
                            }else{
                                errorPhone += "," + data.lxfs;
                            }
                            errorList.push(data);
                        }
                    }
                    if (!data.gx && !data.xm && !data.gzxxdw && !data.lxfs && !data.operation) {

                    } else {
                        dataList.push(data);
                    }
                }
            }
            if(errorList && errorList.length > 0){
                message.error(`${errorPhone}号码填写错误`);
                return ;
            }
            const { cacheFormData, getChildComponent } = this.props;
            getChildComponent(3, true);
            cacheFormData('zyshgx', dataList, true);
            cb(true);
            this.setState({
                zyshgx: false,
                zyshgxAdd: false
            });
        })
    }

    // 家庭成员 添加
    onzyshgxAdd = () => {
        let { zyshgxAdd, zyshgxData } = this.state;
        if (!zyshgxData) {
            zyshgxData = [];
        }

        if (zyshgxAdd) {
            zyshgxData.push({
                key: createUuid(),
                gx: '',
                xm: '',
                gzxxdw: '',
                zzmmm: '',
                lxfs: '',
                operation: ''
            });
            this.setState({
                zyshgxData
            });
        }
        this.setState({
            zyshgxAdd: true
        });
    };
    // 家庭成员 保存添加
    onzyshgxSave = () => {
        let { zyshgxData } = this.state;
        zyshgxData.push();
        this.setState({
            zyshgxData: zyshgxData,
            zyshgxAdd: false
        });
    };
    // 家庭成员 关闭添加
    onzyshgxClose = (index) => {
        let { zyshgxData } = this.state;
        zyshgxData.splice(index, 1);
        this.setState({
            zyshgxData: zyshgxData,
            zyshgxAdd: true
        });
    };

    handleChange = (value, index, key) => {
        let zyshgxData = this.state.zyshgxData;
        zyshgxData[index][key] = value;
        this.setState({ zyshgxData });
    }

    getzzmmmStr = (value) => {
        let temp = '';
        this.state.zzmmmList.forEach((i) => {
            if (i.value === value) {
                temp = i.label;
            }
        })
        return temp;
    }

    deepCopy = (obj) => {
        if (typeof obj != 'object') {
            return obj;
        }

        if (this.isArrayFn(obj)) {
            let newobj = [];
            for (let i = 0; i < obj.length; i++) {
                newobj.push(this.deepCopy(obj[i]));
            }
            return newobj;
        } else {
            let newobj = {};
            for (var attr in obj) {
                newobj[attr] = this.deepCopy(obj[attr]);
            }
            return newobj;
        }
    }


    isArrayFn = (value) => {
        if (typeof Array.isArray === "function") {
            return Array.isArray(value);
        } else {
            return Object.prototype.toString.call(value) === "[object Array]";
        }
    }

    render() {

        const { zyshgx, zyshgxData, zyshgxAdd, zyshgxList } = this.state;
        const { getChildComponent } = this.props;
        const { zzmmmList } = this.state;
        const { getFieldDecorator } = this.props.form;
        const zyshgxColumns = [
            {
                title: '关系',
                key: 'gx',
                dataIndex: 'gx',
                render: (text, record, index) => {
                    return zyshgx ? (

                        <Input
                            value={text}
                            onChange={(e) => { this.handleChange(e.target.value, index, 'gx') }}
                            style={{ width: 100 }}
                            placeholder="请填写关系"
                        />
                    ) : (
                        text
                    );
                }
            },
            {
                title: '姓名',
                dataIndex: 'xm',
                render: (text, record, index) => {
                    return zyshgx ? (
                        <Input
                            value={text}
                            onChange={(e) => { this.handleChange(e.target.value, index, 'xm') }}
                            style={{ width: 100 }}
                            placeholder="请填写姓名"
                        />
                    ) : (
                        text
                    );
                }
            },
            {
                title: '工作单位及职务',
                dataIndex: 'gzxxdw',
                render: (text, record, index) => {
                    return zyshgx ? (
                        <Input
                            value={text}
                            onChange={(e) => { this.handleChange(e.target.value, index, 'gzxxdw') }}
                            style={{ width: 140 }}
                            placeholder="请填写单位和职务"
                        />
                    ) : (
                        text
                    );
                }
            },
            {
                title: '联系电话',
                dataIndex: 'lxfs',
                render: (text, record, index) => {
                    return zyshgx ? (
                        /* <FormItem style={{ marginBottom: '0px' }}>
                            {getFieldDecorator('lxfs', {
                                rules: [
                                    { required: true, message: '请填写电话' },
                                    FormRules.MOBILE_PHONE_NUMBER
                                ],
                                initialValue: text
                            })( */
                            <Input
                                value={text}
                                maxLength={11}
                                onChange={(e) => { this.handleChange(e.target.value, index, 'lxfs') }}
                                style={{ width: 100 }}
                                placeholder="请填写电话"
                            />
                           /*  )}
                        </FormItem> */

                    ) : (
                        text
                    );
                }
            },
            {
                title: 'QQ',
                dataIndex: 'qq',
                render: (text, record, index) => {
                    return zyshgx ? (
                        <Input
                            value={text}
                            onChange={(e) => { this.handleChange(e.target.value, index, 'qq') }}
                            style={{ width: 100 }}
                            placeholder="请填QQ号"
                        />
                    ) : (
                        text
                    );
                }
            },
            {
                title: '微信',
                dataIndex: 'wx',
                render: (text, record, index) => {
                    return zyshgx ? (
                        <Input
                            value={text}
                            onChange={(e) => { this.handleChange(e.target.value, index, 'wx') }}
                            style={{ width: 100 }}
                            placeholder="请填微信号"
                        />
                    ) : (
                        text
                    );
                }
            },
            {
                title: '删除',
                dataIndex: 'operation',
                width: 80,
                render: (text, record, index) => {
                    if (zyshgx) {
                        return (
                            <div className="editable-row-operations">

                                <a
                                    href="javascript:;"
                                    onClick={() => this.onzyshgxClose(index)}
                                >
                                    <Icon
                                        type="close"
                                        style={{ color: 'red' }}
                                    />
                                </a>
                            </div>
                        );
                    }
                    return (
                        <a href="javascript:;" onClick={() => this.onzyshgxClose()}>

                            <Icon type="delete" style={{ color: 'red' }} />
                        </a>
                    );
                }
            }
        ];
        if (!zyshgx) {
            zyshgxColumns.pop();
        }
        return (
            <div>
                <TitleTop
                    title="主要社会关系"
                    onEdit={type => {
                        this.setState({
                            zyshgx: true,
                            zyshgxAdd: true,
                            cacheData: this.deepCopy(this.state.zyshgxData)
                        });
                        getChildComponent(3, false);
                    }}
                    onSave={cb => {
                        this.cacheZyshgxData(cb);


                    }}
                    onClose={() => {
                        this.setState({
                            zyshgx: false,
                            zyshgxAdd: false,
                            zyshgxData: this.deepCopy(this.state.cacheData)
                        });
                    }}
                />

                <Form>
                    <div className="p-sw">
                        <Table
                            bordered
                            size="middle"
                            pagination={false}
                            dataSource={zyshgxData}
                            columns={zyshgxColumns}
                        />
                        {zyshgxAdd && (
                            <div
                                className="mt-10"
                                styleName="btn_add"
                                onClick={() => {

                                    this.onzyshgxAdd();
                                }}
                            >
                                添加主要社会关系
                            </div>
                        )}
                    </div>
                </Form>
            </div>
        );
    }
}
export default Form.create()(ZyshgxForm);