import React from 'react';
import { Button, Table, Icon, InputNumber, Pagination, message, Modal,Alert } from 'antd';
import {getLoginUser} from "@utils/NHCore";
import NHFetch from "@utils/NHFetch";
import {mkbz} from "@pages/Assembly/siderMenu/menuObj";
import {Flex} from "@components/index";
import SiderMenu from "@pages/Assembly/siderMenu";
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import Hint from "@pages/SelfHelp/common/hint";
import TitleTop from "@pages/Assembly/titleTop";
import NextButton from "@pages/SelfHelp/common/nextIndex";
import CommonTip from "@pages/SelfHelp/common";
import Order from "@pages/SelfHelp/payOnline/order";
import '../pay/index.css';
import {NHConfirm} from "@components/common/NHModal";

export default class PayOnlineInit extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            mkbz: 'GD008_4',
            hjmc: '',
            data: [],
            loading: false,
            nextUrl: '',//左侧菜单加载完后传进来
            search: '',
            operable: true,//是否显示环节
            nonOperationalData: {},//不可操作的数据
            zxjfInfo: {},                    //在线缴费相关信息
            jfxxHz: {},                        //缴费信息汇总
            visible: false,
            flage: true,                        //是否存在需要缴费的项目
            selectData:[],
            token: '', //防重令牌
        }
    }

    componentDidMount(){
        //获取防重令牌token
        this.queryToken();
        //检查环节是否办理
        this.checkIsZzbdHjLinked();
        //查询缴费环节基础参数
        // this.loadZxjfInfo();
        //查询是否存在前置环节和依赖环节
        this.queryQztjAndYlhj();
    }

    //获取防重令牌
    queryToken =() =>{
        NHFetch('/wxChatPay/queryToken','GET').then(res =>{
            if (res && res.code === 200){
                this.setState({
                    token:res.data
                })
            } else {
                message.error("获取令牌失败，请稍后再试")
            }
        })
    }

    //检查该环节是否已经办理
    checkIsZzbdHjLinked = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        let zzbdhjid = this.getQueryString('pkid');

        NHFetch("/zzbdCommon/checkIsZzbdHjLinked", 'GET', { xsid: xsid, zzbdhjid: zzbdhjid })
            .then(res => {
                if (res && res.code == 200) {
                    if (!res.data) {
                        //查询是否存在支付中的订单
                        this.queryOrderForPaying();
                    }
                } else {
                    message.error("检查环节办理接口调用失败，请稍后再试")
                }
            });
    };

    //查询前置条件和依赖环节
    queryQztjAndYlhj = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (!xsid) {
            this.setState({nonOperationalData: { nologin: true }, operable: false,});
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', { xsid: xsid, zzbdhjid: zzbdhjid })
            .then(res => {
                if (res && res.data) {
                    let kfsj = res.data['kfsj'];
                    let ylhj = res.data['ylhj'];
                    let qzhj = res.data['qzhj'];
                    let nohj = res.data['nohj'];
                    this.setState({ nonOperationalData: res.data });
                    if (kfsj && kfsj['YXSJ'] != '1') {
                        this.setState({ operable: false });
                    }else if (ylhj && ylhj.length > 0) {
                        this.setState({ operable: false });
                    }else if (qzhj && qzhj.length > 0) {
                        this.setState({ operable: false });
                    }else if (nohj) {
                        this.setState({ operable: false });
                    }else{
                        this.getSfxxList();
                    }
                }
            });
    };

    getQueryString = (name) => {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    };

    //查询是否存在支付中的订单
    queryOrderForPaying =() =>{
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (!xsid) {
            this.setState({nonOperationalData: { nologin: true }, operable: false,});
            return;
        }
        NHFetch('/payOnline/queryOrderForPaying','GET',{stuId:xsid}).then(res =>{
            if (res && res.code === 200){
                if (res.data === 1){
                    this.reloadMenu();
                    this.getSfxxList();
                    this.loadZxjfInfo();
                }
            }
        })
    };

    //获取缴费信息
    getSfxxList = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        NHFetch('/payOnline/queryPayItemList', 'GET',{stuId:xsid})//所有的收费数据
            .then(resData => {
                if (resData && resData.code === 200) {
                    this.setState({ data: resData.data }, () => {
                        let list = this.state.data.filter((item) => item.QFJE > 0);
                        if(resData.data.length===0){
                            this.setState({
                                flage: false
                            })
                        }else{
                            if (list.length <= 0) {
                                this.setState({ flage: false })
                            }
                        }

                    });
                }else {
                    message.error("查询缴费信息失败，请稍后再试");
                }
            });
    };

    //获取在线缴费相关信息
    loadZxjfInfo = () => {
        const { mkbz } = this.state;
        NHFetch('/payOnline/queryBasicPayInfo', 'GET',{linkCode:mkbz})
            .then(res => {
                if (res && res.data) {
                    this.setState({
                        zxjfInfo: res.data,
                    });
                }

            });
    }

    //重新加载菜单
    reloadMenu = () => {
        this.refs.siderMenu.reloadMenuList()
    };

    //刷新左边菜单
    setMenuList = (menuList) => {
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem, index) => {
            if (elem.HJDM == bz) {
                let hjmc = elem.HJMC;
                this.setState({ hjmc: hjmc })
                nextCount = index + 1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if (menu) {
            let hjdm = menu.HJDM;
            if (hjdm.indexOf('GD') == 0) {
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl: gdUrl,
                    search: 'pkid=' + menu.PKID,
                })
            } else if (hjdm.indexOf('ZDY') == 0) {
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl: zdyUrl,
                    search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
                })
            }
        } else {
            this.setState({
                nextUrl: '',
                search: '',
            })
        }
    };

    showModal = () => {
        const { zxjfInfo,selectData } = this.state;

        if (selectData.length <= 0){
            message.error("请先勾选需要进行缴费的项目");
            return;
        }
        console.log(selectData)
        let shid = '';
        for (let item in selectData){

            if (shid){

                if ( shid == selectData[item].SHID){
                    shid = selectData[item].SHID;
                }else {
                    message.error("不同商家的项目不能一起缴费，请勾选同一个收费商家的项目！");
                    return;
                }
            } else {
                shid = selectData[item].SHID;
            }
        }

        //先关闭未支付的订单
        this.closeOrder(zxjfInfo.DDH);
    };

    //关闭订单
    closeOrder =(orderCode)=>{

        if (orderCode){
            NHFetch('/payOnline/closeOrder','GET',{orderCode:orderCode}).then(res =>{
                if (res && res.code === 200){
                    if (res.data === 1){
                        this.setState({
                            visible: true,
                        })
                    }else {
                        message.error("关闭未支付订单操作失败，请稍后再试")
                    }
                } else {
                    message.error("网络异常，请稍后再试")
                }
            })
        } else {
            this.setState({
                visible: true,
            })
        }
    }

    handleCancel = e => {
        this.setState({
            visible: false,
        }, () => {
            this.loadZxjfInfo();
            this.getSfxxList();
        });
    };

    //获取选择的项目
    onRowSelect = (selectedRowKeys, selectedRows) =>{
        this.setState({
            selectData:selectedRows.filter(item => item.QFJE > 0)
        })
    };
    render() {
        const { loading, data, zxjfInfo,selectData,token,qrcode } = this.state;
        const columns = [
            {
                title: '序号',
                dataIndex: 'XH'
            },
            {
                title: '收费项目',
                dataIndex: 'SFXMMC',
            },
            {
                title: '应收金额（元）',
                dataIndex: 'YSJE',
            },
            {
                title: '实收金额（元）',
                dataIndex: 'SSJE',
                render: (text, record) => {
                    return <span className="text-primary">{text}</span>;
                }
            },{
                title: '缓交金额（元）',
                dataIndex: 'HJJE',
                render: (text, record) => {
                    return <span className="text-primary">{text}</span>;
                }
            },{
                title: '减免金额（元）',
                dataIndex: 'JMJE',
                render: (text, record) => {
                    return <span className="text-primary">{text}</span>;
                }
            },
            {
                title: '欠费金额（元）',
                dataIndex: 'QFJE',
                render: (text, record) => {
                    if (text > 0) {
                        return <span className="text-info-red">{text}</span>
                    } else {
                        return text
                    }
                }
            }, {
                title: '收费商家',
                dataIndex: 'SHMC',
            },
        ];
        return (
            <div styleName="page_pay">
                {this.state.operable ?
                    <Flex className="w1180 mt-20" align="start">
                        <SiderMenu
                            ref={"siderMenu"}
                            setMenuList={this.setMenuList}
                            title="自助报到"
                            /*active={7}*/
                            mkbz={this.state.mkbz}
                            menuType='自助报到'
                        />
                        <Flex.Item className="page_con_r">
                            <Breadcrumb
                                noLine
                                list={[
                                    { name: '首页', url: '/index' },
                                    { name: '自助报到', url: '/register' },
                                    { name: this.state.hjmc }
                                ]}
                            />
                            <div className="box">
                                <Hint />
                                <TitleTop title={this.state.hjmc} extra={false} />
                                <div className="p-sw mt-20">
                                    {zxjfInfo.DDH && <Alert message="你存在正在进行时的订单，请尽快处理。。。" type="info" />}
                                    <br/>
                                    <Table
                                        bordered
                                        size="middle"
                                        rowKey={record => record.SFXMID}
                                        rowSelection={{
                                            onChange: (selectedRowKeys,selectedRows)=>this.onRowSelect(selectedRowKeys,selectedRows)
                                        }}
                                        pagination={false}
                                        columns={columns}
                                        dataSource={data}
                                    />
                                    <div className="text-center mt-50">
                                        <div className="text-center mt-50">
                                            {this.state.flage && zxjfInfo.ZFD !== '0'&&<Button onClick={() => this.showModal()} type="primary" htmlType="submit">网上支付</Button>}
                                            <NextButton nextUrl={this.state.nextUrl} search={this.state.search} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Flex.Item>
                    </Flex>
                    : <CommonTip hjmc={"在线缴费"} key={'GD008_4'} mkbz='GD008_4' nonOperationalData={this.state.nonOperationalData} />}
                <Modal
                    title="支付详情信息"
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            关闭
                        </Button>]
                    }>
                    <Order zxjfInfo={zxjfInfo} selectData={this.state.selectData} token={this.state.token}/>
                </Modal>
            </div>
        );
    }
}