import React from 'react';
import createHistory from 'history/createHashHistory';
const history = createHistory();
import NHFetch from "@utils/NHFetch";
/*页面*/
import { Button, message, Table, Descriptions, Divider } from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import { getLoginUser, createUuid,checkLogin } from "@utils/NHCore";
import {mkbz} from "@pages/Assembly/siderMenu/menuObj";
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import Flex from "@components/Flex";
import './index.css';
export default class Route extends React.Component {

    constructor(props) {
        super(props);
        this.state={
        }
    }

    componentWillMount(){
        // checkLogin(true);//登录检查
        // let userLogin = sessionStorage.getItem("userLogin");
        // console.log
        let userId = getLoginUser() ? getLoginUser().userId : undefined;
        if (userId) {
            this.loadMenuList();
        }else{
            message.error('未获取登录信息')
            history.push({
                pathname: '/index',
            });
            return false;
        }
      
    }

    loadMenuList=()=>{
        NHFetch('/zzbd/getZzbdInfoList','GET').then(resData => {
            if (resData && resData.data) {
                this.toLink(resData.data);
            }
        });
    }

    toLink=(menuList)=>{
        if(menuList && menuList.length>0){
            let menu = menuList[0];
            let hjdm = menu.HJDM;
            let pkid = menu.PKID;
            let lx = menu.LX;
            if(hjdm.indexOf('GD')==0){
                let hashUrl = mkbz[hjdm];
                history.push({
                        pathname:hashUrl,
                        search:'pkid='+pkid,
                    }
                );
            }else if(hjdm.indexOf('ZDY')==0){
                let hashUrl = '/customLink';
                history.push({
                        pathname:hashUrl,
                        search:'pkid='+pkid+'&mkbz='+hjdm+'&lx='+lx,
                    }
                );
            }
        }
    }
 
    render() {
        return (
            <div styleName="page_pay">     
                    <Flex className="w1180 mt-20" align="start">
                        <Flex.Item className="page_con_r">
                            <Breadcrumb
                                noLine
                                list={[
                                    { name: '首页', url: '/index' },
                                    { name: '自助报到', url: '/register' },
                                ]}
                            />

                        </Flex.Item>
                    </Flex>
            </div>
        );
    }
}