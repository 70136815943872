import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Flex } from '@components';
import { Button, Progress, Table } from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import NextButton from '@pages/SelfHelp/common/nextIndex'
import './index.css';
import {getLoginUser,createUuid} from "@utils/NHCore";
import createHistory from 'history/createHashHistory';
import NHFetch from "../../../../utils/NHFetch";
import CommonTip from "../common";
import {mkbz} from "@pages/Assembly/siderMenu/menuObj";
import BarCode from 'react-barcode';
import KzxxFormc from '../updateInfo/KzxxFormc';

import Print from "../../../../utils/Print";
import PrintTemplate from "./printTemplate";
import Hint from "@pages/SelfHelp/common/hint";

// import PrintProvider, { Print, NoPrint } from 'react-easy-print';

const history = createHistory();

export default class pagePrint extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mkbz:'GD009',
            hjmc:'',
            loading: false,
            xsxx:[],//学生信息
            nextUrl:'',//左侧菜单加载完后传进来
            search:'',
            operable:true,//是否显示环节
            nonOperationalData:{},//不可操作的数据
            schoolName:'',//学校名称
            year:'',//当前年度
            account:'',//默认账号
            bddbz:'',
            kzxxInitData: {},
            nr:[
                // {
                //     key: 1,
                //     row_id:1,
                //     nr:'交验录取通知书'
                // },
                // {
                //     key: 2,
                //     row_id:2,
                //     nr:'领取缴费收据'
                // },
                // {
                //     key: 3,
                //     row_id:3,
                //     nr:'购买床上用品'
                // },
                // {
                //     key: 4,
                //     row_id:4,
                //     nr:'领取宿舍钥匙'
                // },
                // {
                //     key: 5,
                //     row_id:5,
                //     nr:'领取军训服'
                // },
            ]
        };
    }

    componentDidMount(){
        let userId = getLoginUser() ? getLoginUser().userId : undefined;
        if (userId) {
            this.getStudentPhotoBase64(userId, (data => {
                if (!data) return;
                this.setState({ 
                    xsid: userId,
                    imageUrl: `data:image/jpeg;base64,${data}`
                })
            })) 
        } 
        this.queryQztjAndYlhj();
        this.getPrintInfo();
        this.getCurrentYear();
        this.getCurrentSchool();
        this.getAccount();
        this.getBddBz();
    }

     // 获取学生头像
     getStudentPhotoBase64 =  (userId, callback) => {
        NHFetch('api/zhxg-yxwz/openapi/student/getStudentPhotoBase64', 'get', { xsid: userId, type: 10 })
        .then(res => {
            if (res && res.code == 200) {
                callback(res.data);
            }
        }); 
    }

     //获取当前学校
     getCurrentSchool = () => {
        let params = {};
        return NHFetch("/getCurrentXxmc", "GET", params)
            .then(resData => {
                if (resData.data != null) {
                    this.setState({ schoolName: resData.data });
                }
            })
    }

    //获取当前年度
    getCurrentYear = () => {
        let params = {};
        return NHFetch("/zdybl/getCurrentYear", "GET", params)
            .then(resData => {
                if (resData.data != null) {
                    this.setState({ year: resData.data });
                }
            })
    }

    //获取当前年度
    getAccount = () => {
        let xsid = getLoginUser()?getLoginUser().userId:'';
        if(xsid==''){

        }
        return NHFetch("/zdybl/getAccount", "GET", {xsid:xsid})
            .then(resData => {
                if (resData.data != null) {
                    this.setState({ account: resData.data });
                }
            })
    }

    //获取打印信息
    getPrintInfo = () => {
        let xsid = getLoginUser()?getLoginUser().userId:'';
        if(xsid==''){

        }
        NHFetch("/zdybl/getInfoForPrint", 'GET', {xsid:xsid})
            .then(res => {
                if (res && res.data) {
                    let stuXxcj = res.data.stuXxcj;
                    this.setState({xsxx:res.data,kzxxInitData: { ...stuXxcj },nr:res.data.xclcList})
                }
            });
    }

    //获取报到单备注
    getBddBz = () => {
        NHFetch('/zzbdCommon/getCssz', 'GET', { csbz: 'YXXT_BDD_BZ' }).then(res => {
            if (res && res.data) {
                this.setState({ bddbz: res.data });
            }
        })
    }

    //查询前置条件和依赖环节
    queryQztjAndYlhj=()=>{
        let xsid = getLoginUser()?getLoginUser().userId:'';
        if(!xsid){
            this.setState({
                nonOperationalData:{nologin:true},
                operable:false,
            });
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', {xsid:xsid,zzbdhjid:zzbdhjid})
            .then(res => {
                if (res && res.data) {
                    let kfsj = res.data['kfsj'];
                    let ylhj = res.data['ylhj'];
                    let qzhj = res.data['qzhj'];
                    let nohj = res.data['nohj']; 
                    this.setState({nonOperationalData:res.data});
                    if(kfsj && kfsj['YXSJ']!='1'){
                        this.setState({operable:false});
                    }

                    if(ylhj && ylhj.length>0){
                        this.setState({operable:false});
                    }

                    if(qzhj && qzhj.length>0){
                        this.setState({operable:false});
                    }

                    if (nohj) { 
                        this.setState({ operable: false });
                    }
                }
            });
    }

    getQueryString =(name)=> {
        return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[, ''])[1].replace(/\+/g, '%20')) || null
    }

    setMenuList=(menuList)=>{
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem,index)=>{
            if(elem.HJDM==bz){
                let hjmc = elem.HJMC;
                this.setState({hjmc:hjmc})
                nextCount = index+1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if(menu){
            let hjdm = menu.HJDM;
            if(hjdm.indexOf('GD')==0){
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl:gdUrl,
                    search:'pkid='+menu.PKID,
                })
            }else if(hjdm.indexOf('ZDY')==0){
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl:zdyUrl,
                    search:'pkid='+menu.PKID+'&mkbz='+hjdm+'&lx='+menu.LX,
                })
            }
        }else{
            this.setState({
                nextUrl:'',
                search:'',
            })
        }
    }

    // onPrint = () => {
    //     this.setState({ loading: true });
    //     setTimeout(() => {
    //         this.setState({
    //             loading: false
    //         });
    //         print();
    //     }, 500);
    // };

    onPrint = () => {
        this.refs.print.onPrint();
    }

    //重新加载菜单
    reloadMenu=()=>{
        this.refs.siderMenu.reloadMenuList()
    }

    render() {
        const { loading, xsxx, schoolName, year, nr, account, bddbz, kzxxInitData,imageUrl } = this.state;
        const sfxmList = xsxx.sfxmList
        if(sfxmList) {
            for(let i=0;i<sfxmList.length;i++){
                sfxmList[i].row_id=i+1;
            }
        }
        if(nr) {
            for(let ic=0;ic<nr.length;ic++){
                nr[ic].row_id=ic+1;
            }
        }
        const payColumns = [
            {
                title: '序号',
                dataIndex: 'row_id'
            },
            {
                title: '收费项目',
                dataIndex: 'sfxmmc'
            },
            {
                title: '应收金额',
                dataIndex: 'ysje'
            },
            {
                title: '已交金额',
                dataIndex: 'ssje',
                render: (text, record) => {
                    return <span className="text-primary">{text}</span>;
                }
            }
        ];
        const Columns = [
            {
                title: '序号',
                dataIndex: 'row_id'
            },
            {
                title: '主要内容',
                dataIndex: 'HJMC'
            }
        ];

        return (
            <div>{this.state.operable?
                <Flex className="w1180 mt-20" align="start">
                    <SiderMenu
                        ref={"siderMenu"}
                        setMenuList={this.setMenuList}
                        title="自助报到"
                        unShowBlzt={"0"}
                  /*      active={8}*/
                        mkbz={this.state.mkbz}
                        menuType="自助报到"
                    />
                    <Flex.Item className="page_con_r">
                        <Breadcrumb
                            noLine
                            list={[
                                { name: '首页', url: '/index' },
                                { name: '自助报到', url: '/register' },
                                { name: this.state.hjmc }
                            ]}
                        />
                            <div className="box">
                                <Hint/>
                                <Print ref="print" lazyRender={true} insertHead={true} winStyle='toolbar=yes,menubar=yes' isIframe={false}>
                                    <PrintTemplate kzxxInitData={kzxxInitData} nr={nr} initFormDate={xsxx} year={year} schoolName={schoolName} account={account} bddbz={bddbz}/>
                                </Print>
                                <div>
                                    <div className="text-center">
                                        <div styleName="title">
                                            {schoolName}{year}新生入学报到单
                                        </div>
                                    </div>

                                    <TitleTop title="基本信息" extra={false} />
                                    <Flex className="p-sw-big">
                                        <img styleName="jbxx_icon" className="_bg" src={imageUrl}
                                        onError={e => { e.target.src = stuDefault }}
                                        />
                                        <Flex.Item className="ml-20">
                                            {[
                                                {
                                                    label: '姓名',
                                                    name: xsxx.xm
                                                },
                                                {
                                                    label: '性别',
                                                    name: xsxx.xb
                                                },
                                                {
                                                    label: '考生号',
                                                    name: xsxx.ksh
                                                },
                                                {
                                                    label: '民族',
                                                    name: xsxx.mz
                                                },
                                                {
                                                    label: '政治面貌',
                                                    name: xsxx.zzmm
                                                },
                                                {
                                                    label: '籍贯',
                                                    name: xsxx.jg
                                                },
                                                {
                                                    label: '联系电话',
                                                    name: xsxx.sjhm
                                                },
                                                {
                                                    label: '身份证号',
                                                    name: xsxx.sfzjh
                                                },
                                                {
                                                    label: '考生类型',
                                                    name: xsxx.xslb
                                                },
                                                {
                                                    label: '学院',
                                                    name: xsxx.bmmc
                                                },
                                                {
                                                    label: '专业',
                                                    name: xsxx.zymc
                                                },
                                                {
                                                    label: '班级',
                                                    name: xsxx.bjmc
                                                }
                                            ].map((elem, index) => {
                                                return (
                                                    <div key={index} styleName="jbxx_item">
                                                        <span styleName="item-label">{elem.label}：</span>
                                                        <div
                                                                className="t-clamp-1"
                                                                styleName="item-name"
                                                                style={{display: 'inline'}}
                                                            >
                                                        {elem.name}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </Flex.Item>
                                    </Flex>
                                    <KzxxFormc ref="refKzxxFormc" 
                                        initData={kzxxInitData}
                                    />
                                    <TitleTop title="宿舍信息" extra={false} />
                                    <div className="p-sw-big">
                                        <div styleName="ssxx_list">
                                            <div styleName="ssxx_item">
                                                <div styleName="ssxx_label">
                                                    校区：
                                                </div>
                                                <div styleName="ssxx_name">
                                                    {xsxx.xqmc}
                                                </div>
                                            </div>
                                            <div styleName="ssxx_item">
                                                <div styleName="ssxx_label">
                                                    楼栋：
                                                </div>
                                                <div styleName="ssxx_name">
                                                    {xsxx.ldmc}
                                                </div>
                                            </div>
                                        </div>
                                        <div styleName="ssxx_list">
                                            <div styleName="ssxx_item">
                                                <div styleName="ssxx_label">
                                                    房间：
                                                </div>
                                                <div styleName="ssxx_name">
                                                    {xsxx.fjmc}
                                                </div>
                                            </div>
                                            <div styleName="ssxx_item">
                                                <div styleName="ssxx_label">
                                                    床位：
                                                </div>
                                                <div styleName="ssxx_name">
                                                    {xsxx.cwh}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <TitleTop title="缴费信息" extra={false} />
                                    <div className="p-sw-big">
                                        <Table
                                            bordered
                                            size="middle"
                                            pagination={false}
                                            dataSource={xsxx.sfxmList}
                                            columns={payColumns}
                                        />
                                    </div>
                                    <TitleTop title="二级学院报到" extra={false} />
                                    <div className="p-sw-big">
                                        <Table
                                            bordered
                                            size="middle"
                                            pagination={false}
                                            dataSource={nr}
                                            columns={Columns}
                                        />
                                    </div>
                                    <Flex className="p-sw-big mt-30">
                                        <Flex.Item>
                                            <div className="text-title">
                                                备注：
                                            </div>
                                            <div styleName="bz-item">{bddbz}</div>
                                            {/* <div styleName="bz-item">
                                                1.新生拿到报到单后，请到指定地点完成报到流程。
                                            </div>
                                            <div styleName="bz-item">
                                                2.未缴清费用的同学，需在报到期间缴清费用，如有特殊情况，请到学院迎新点处登记。
                                            </div>
                                            <div styleName="bz-item">
                                                3.报到单请务必保存好，报到结束后，由学院统一收回。
                                            </div> */}
                                        </Flex.Item>
                                        <BarCode styleName="bz-img"
                                        value={this.state.account}
                                        width={2}
                                        height={60}
                                        fontSize={18}
                                        >
                                        </BarCode>

                                    </Flex>
                                    </div>
                                    <div className="p-sw-big text-center mt-30">
                                        <Button
                                            type="primary"
                                            loading={loading}
                                            onClick={this.onPrint}
                                        >
                                            打印
                                        </Button>
                                        <NextButton nextUrl={this.state.nextUrl}  search={this.state.search}/>
                                    </div>
                            </div>
                    </Flex.Item>
                </Flex>:<CommonTip   hjmc = {"入学报到单打印"} key={'GD009'} mkbz='GD009' nonOperationalData={this.state.nonOperationalData}/>}
            </div>
        );
    }
}
