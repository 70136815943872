import React from "react";
import { List, Flex, Button, Modal, Table, message } from "antd";
import NHFetch from "../../../../utils/NHFetch";
import {getLoginUser} from "@utils/NHCore";
import { handle, syscBtnFunc } from "./paydisc/index.js";

const Item = List.Item;
const Brief = Item.Brief;
const { confirm } = Modal;

export default class Order extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ddxx: {},//订单信息
      jfje: 0,//本次缴费总金额
      list: [],
      noallowpay: false//支付按钮禁用
    };
  }
  
  componentWillMount() {
    //进来后先计算缴费金额
    this.getJfje();
  }

  getTotalNumber = () => {
    //let list = this.props.sfxmInfo;
    let list = this.props.sfxmInfo.filter(item =>
      item.QFJE > 0 ? true : false
    );
    let yjje = 0.0; //应缴金额
    let ysje = 0.0; //已收金额
    let sjje = this.state.jfje; //实缴金额
    let hjje = this.props.hjje ? this.props.hjje : 0.0;
    list.map(item => {
      yjje += item.YSJE;
      ysje += item.SSJE;
      // sjje+=item.JE;
      //  hjje+=(item.QFJE-item.JE);//缓交金额，还需要加上这次学生修改的没交的金额
      return item;
    });
    return {
      yjje: yjje,
      ysje: ysje,
      sjje: sjje - hjje,
      hjje: hjje
    };
  };
  //获取订单信息
  getDdxx = () => {
    NHFetch("/openapi/jfxt/sszy/addOrder", "POST", {}).then(res => {
      if (res) {
        let msg = res.data;
        this.setState(
          {
            ddxx: msg
          },
          () => {
            if (msg.code === "1") {
              //返回信息时正常的，此时跳转到支付界面
              //this.handleConfirmSubmit();
              this.toPayView();
            }else if(msg.code === "-1"){
              message.error("调用建行查询方法失败，请重新请求")
            }else if(msg.code === "2"){
              message.info("已支付成功，无需重复支付")
            } else {
              //返回信息有误
              message.error(msg.message);
            }
            //this.toPayView();
          }
        );
      }
    });
  };
//添加一个订单
  addOrder = () => {
    //1、获取支付项目
    let list = this.props.sfxmInfo.filter(item =>
      item.QFJE > 0 ? true : false
    );
    if (list.length <= 0) {
      message.error("支付项目为空，不能执行支付操作");
      return;
    }
    let jfxm = [];
    //je(交易金额),ysje(应缴金额),ssje(已缴金额)
    list.map(item => {
      jfxm.push({
        sfxmid: item.SFXMID,
        sfxmdm: item.SFXMDM,
        sfxmmc: item.SFXMMC,
        je: item.YSJE,
        ysje: item.YSJE,
        ssje: item.SSJE
      });
      return item;
    });
    let map = this.getTotalNumber();
  
    //2、生成订单
    let params = {
      jfxm: jfxm,
      sfxyjldd: this.props.zxjfInfo.SFXYJLDD,
      jyje: map.sjje,
      yjje: map.yjje,
      ysje: map.ysje,
      hjje: map.hjje
    };
    NHFetch("/openapi/jfxt/sszy/addOrder", "POST", params).then(res => {
      if (res) {
        let msg = res.data;
        this.setState({
          ddxx: msg
        });
        if (msg.code === "1") {
          //返回信息时正常的，此时跳转到支付界面
          //this.handleConfirmSubmit();
          this.toPayView();
        }else if(msg.code === "-1"){
          message.error("调用建行查询方法失败，请重新请求")
        }else if(msg.code === "2"){
          message.info("已支付成功，无需重复支付")
        } else {
          //返回信息有误
          message.error(msg.message);
        }
      }
    });
  };
  getQueryString = name => {
    return (
      decodeURIComponent(
        (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
          location.href
        ) || [, ""])[1].replace(/\+/g, "%20")
      ) || null
    );
  };
  //确认知悉
  handleConfirmSubmit = () => {
    let xsid = getLoginUser() ? getLoginUser().userId : "";
    let zzbdhjid = this.getQueryString("pkid");
    let params = { xsid: xsid, zzbdhjid: zzbdhjid };
    // this.toPayView();
    NHFetch("/zzbdCommon/commonSaveLinkTransactionStatus", "POST", params).then(
      res => {
        if (res) {
          if (res.data === 1) {
            this.toPayView();
          }
        } else {
          message.error("确认失败，请稍后重试！");
        }
      }
    );
  };

  //计算要缴费的金额
  getJfje = () => {
    let list = this.props.sfxmInfo.filter(item =>
      item.QFJE > 0 ? true : false
    );
    let jfje = 0.0; // 缴费金额
    list.map(item => {
      jfje += item.QFJE;
    });
    jfje=Math.round( jfje * Math.pow( 10, 2 ) ) / Math.pow( 10, 2 );  
    this.setState({
      jfje: jfje
    });
  };

  
  handleBackFunc = () => {
    if (this.props.backFunc) {
      this.props.backFunc();
    }
  };
  
//点击支付按钮触发的事件
  handleSubmit = () => {
    //如果有提示信息，则需要先显示提示信息
    let $this = this;
    if (this.props.zxjfInfo.ZFTS) {
      confirm({
        title: "提示",
        content: this.props.zxjfInfo.ZFTS,
        onOk() {
          $this.setState({
            noallowpay: true
          },()=>{
            $this.toRedirertPayView();
          })
          
        },
        onCancel() {}
      });
    } else {
      this.setState({
        noallowpay: true
      },()=>{
        this.toRedirertPayView();
      })
    }
  };

  //进行支付跳转前的判断
  toRedirertPayView = () => {
    //不存在正在进行的订单的时候就要先添加一个订单
    // if (this.props.ddh === null) {
    //   this.addOrder();
    // } else {
    //   //此时说明存在正在进行的订单，就把订单的信息查询出来
    //   this.getDdxx();
      
    // }
    //都改成调addOrder()方法，原因是，虽然数据库有处于交易中的数据但是这条数据可能是交易失败了建行没回写，这边需要作废原来的并且重新生成订单
    //重新生成订单的话需要有参数传入到后台的方法里边去，而页面方法getDdxx()方法没有传参，所以都换成addOrder()
    this.addOrder();
  };
  //跳转到支付界面
  toPayView = () => {
    let msg = this.state.ddxx;
    let type = this.props.zxjfInfo.DJFSID;
    handle(
      type,
      msg.ddh,
      msg.csxx,
      msg.xsxx,
      msg.jfxm,
      msg.jyje,
      msg.yjje,
      msg.ysje,
      msg.hjje
    );
  };

  //绑定同步按钮
  handleSyncBtn = () => {
    let msg = this.state.ddxx;
    let type = this.props.zxjfInfo.DJFSID;
    syscBtnFunc(type, msg.ddh);
  };

  render() {
    let noallowpay = this.state.noallowpay;
    let ddxx = this.state.ddxx;
    let zxjfInfo = this.props.zxjfInfo;
    let list = this.props.sfxmInfo.filter(item =>
      item.QFJE > 0 ? true : false
    );
    const columns = [
      {
        title: "收费项目",
        dataIndex: "SFXMMC"
      },
      {
        title: "支付金额（元）",
        dataIndex: "QFJE"
      }
    ];
    return (
      <div>
        {this.state.jfje != 0 ? (
          <p>
            <span style={{ fontSize: "15px", fontWeight: "bold" }}>
              交易金额:{this.state.jfje}元
            </span>
          </p>
        ) : null}

        <Table
          bordered
          size="middle"
          pagination={false}
          columns={columns}
          dataSource={list}
        />
        <p style={{ color: "red" }}>
          特别说明：为了解决银行卡交易限额等原因，网上缴费支持多次缴费；请勿恶意多次小额缴费（如0.1元），以免你的银行卡检测有风险被禁止交易
        </p>
        <Button
          style={{
            float: "left",
            backgroundColor: "rgb(1, 176, 241)",
            color: "#fff"
          }}
          onClick={this.handleSubmit}
          disabled={noallowpay}
        >
          进入支付界面
        </Button>
      </div>
    );
  }
}
