import React from "react";
import { Card, Form, Button, Divider, message,Row, Col,Modal } from 'antd';
import PropTypes from 'prop-types';
import NHFetch from "../../../../utils/NHFetch";
import NHInputRender from "../../../components/common/NHInputPicker/render";
import moment from 'moment';
import {getLoginUser} from "@utils/NHCore";
import {getHashParam} from "../../../../utils/globalUtil";
import styles from './index.css';
const FormItem = Form.Item;
class Common extends React.Component {
    constructor() {
        super()
        this.state = {
            hjblztData: {}, //环节办理状态信息
            hjywxData: [], //环节业务项信息
            editable: true, //是否可编辑
            previewVisible: false,
            dmkmcList:{},
            loading:false
        }
    }

    componentWillReceiveProps(nextProps) {
        if ('hjblztData' in nextProps) {
            const hjblztData = nextProps.hjblztData;
            if (hjblztData) {
                let editable = hjblztData.blzt == '1' ? false : true;
                this.setState({ hjblztData: hjblztData, editable: editable });
            } else {
                this.setState({ hjblztData: hjblztData, editable: true });
            }
        }
    }

    //确认办理
    handleSubmit = () => {
        this.setState({
            loading:true
        })
        let postData = { xsid: getLoginUser()?getLoginUser().userId:'', zzbdhjid: this.getQueryString('pkid')};
        NHFetch('/yzzy/yxjf/handlePaymentStatusAndProcessStep', 'POST',)
        .then(res => {
            if (res) {
                if (res.data === 1) {
                    message.success("校园E卡缴费成功!");
                    
                    const onFinish = this.props.onFinish;
                    if (onFinish) {
                        onFinish();
                    }
                }
                if (res.data === 0) {
                    message.warning("请先下载app缴费!");
                }
            }
            this.setState({
                loading:false
            })
        });
    }

    getQueryString =(name)=> {
        return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[, ''])[1].replace(/\+/g, '%20')) || null
    }

    //取消办理
    handleCancel = () => {
        const { pkid } = this.state.hjblztData;
        let zzbdhjid = getHashParam('pkid');
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        let params = { xsid: xsid, lchjid: pkid ,zzbdhjid:zzbdhjid};
        NHFetch('/zdybl/cancelCustomLinkData', 'POST', params)
            .then(res => {
                if (res) {
                    if (res.data == '1') {
                        message.success('环节办理取消成功！');
                        //取消办理完成回调
                        const onFinish = this.props.onFinish;
                        if (onFinish) {
                            onFinish();
                        }
                    } else {
                        message.error('环节办理取消失败，请重试！');
                    }
                }
            })
    }

    //上一步
    handleLast = () => {
        let onLast = this.props.onLast;
        if (onLast) {
            onLast();
        }
    }

    //下一步
    handleNext = () => {
        let onNext = this.props.onNext;
        if (onNext) {
            onNext();
        }
    }
    //获取下拉列表value对应的名称
    getDmkMc = (dmbz, zdbz) => {
        NHFetch("/proData/selectDataList", "GET", { sign: dmbz })
            .then(res => {
                if (res) {
                    if (res.data) {
                        let dmkmcList = this.state.dmkmcList;
                        dmkmcList[zdbz] = res.data;
                        this.setState({
                            dmkmcList: dmkmcList
                        })
                    }
                }
            });
    }

       //获取联级下拉列表value对应的名称
       getCascaderDmkMc = (level,sign, cascaderValue,zdbz) => { 
        NHFetch("/proData/selectCascaderList", "GET", { level:level,sign: sign ,cascaderValue:cascaderValue})
            .then(res => {  
                if (res) {
                    if (res.data) {
                        let dmkmcList = this.state.dmkmcList;
                        dmkmcList[level+zdbz] = res.data; 
                        this.setState({
                            dmkmcList: dmkmcList
                        })
                    }
                }
            });
    }
      
     // 点击文件链接或预览图标时的回调
     handlePreview = (fjmc, fjid) => {  
        if (fjmc==="isImage") {
            this.showPreview(`anonymity/docrepo/download/file?attachmentId=${fjid}`);
        } else {
            window.open(`anonymity/docrepo/download/file?attachmentId=${fjid}`);
        }
    };

    //打开图片弹窗
    showPreview = (url) => {
        this.setState({
            previewImage: url,
            previewVisible: true
        });
    }
    // 关闭图片弹窗
    closeModal = () => this.setState({ previewVisible: false });
    // 文件展示
    fileLists = (fileLists) => {
        {
            let items=[];
            for(let key in fileLists){
                let fileList = fileLists[key].split(";"); 
                items.push (
                    <div>
                        <a onClick={() => {
                        this.handlePreview("file",fileList[0]);
                        }}>{fileList[1]} </a> 
                    </div>
                )
            }
        return items;
        }
    };
    //图片展示
    imageLists = (imageLists) =>{
        {
            let items=[];
            for(let key in imageLists){
                let imageList = imageLists[key].split(";");
                items.push (
                    <div>
                    <img style={{height:"86px" ,width:"86px"}} src={"anonymity/docrepo/download/file?attachmentId=" + imageList} 
                     onClick={() => {
                        this.handlePreview("isImage",imageList[0]);
                        }}/>
                    </div>
                )
            }
            return items;
        }
    }
    render() {

        return (
            <div>
                <div style={{position:'relative',textAlign:'center'}}>
                {
                    <Button  type="primary" style={{position: 'absolute',top: '74px',left: '308px'}} 
                        onClick={this.handleSubmit} loading={this.state.loading}>刷新</Button>
                }
                </div>
            </div>
        )
    }
}

const common = Form.create()(Common);

Common.propTypes = {
    xsData: PropTypes.object, //学生信息
    hjblztData: PropTypes.object, //环节办理状态数据
    hjywxData: PropTypes.array, //环节业务项数据
}
export default common