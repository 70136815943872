import React from 'react';
import { List, Button, Modal, Table, message,Divider,Radio,Icon,Alert } from "antd";
import {getLoginUser} from "@utils/NHCore";
import NHFetch from "@utils/NHFetch";

export default class OrderInit extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            orderInfo:{},
            jfje:0,  //缴费金额
            list:[],  //缴费明细
            selectData:[], //选中的数据
            payType:'',
        }
    }

    componentDidMount(){
        //组装缴费数据
        this.setState({selectData:this.props.selectData},()=>{ this.buildPayAmount()})
    }

    componentWillReceiveProps (nextProps){
        //组装缴费数据
        this.setState({selectData:nextProps.selectData},()=>{ this.buildPayAmount()})
    }

    buildPayAmount = () => {
        const { selectData } = this.state;
        let list = selectData.filter(item => item.QFJE > 0);
        let jfje = 0.0;
        list.map(item => {
            jfje += item.QFJE;
        });
        jfje=Math.round( jfje * Math.pow( 10, 2 ) ) / Math.pow( 10, 2 );
        this.setState({
            jfje: jfje,
            list: list
        });
    };

    handleSubmit =()=>{
        const { payType,list,jfje } = this.state;
        const { token } = this.props;
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        let shid = list[0].SHID;

        if (!payType){
            message.error("请先选择对应的支付方式(支付宝、微信)");
            return;
        }

        let shArray = shid.split(":");
        let shxx = shArray.filter(item => item.indexOf(payType) !== -1)[0];
        if (!shxx){
            message.error("所选的收费项目不包含这种支付方式，支付失败");
            return;
        }

        let id = shxx.split("@")[0];
        console.log(id);
        let params = {
            token:token,
            stuId:xsid,
            payType:payType === 'alipay' ? '1':'2',
            shid:id,
            itemList:list,
            paymentAmount:jfje
        }

        console.log(params)

        NHFetch('/payOnline/saveOrderInfo','POST',params).then(res=>{
            if (res && res.code === 200){
                if (res.data === 1){
                    if(payType === 'alipay'){
                        this.placeAliPayOrder(xsid,id);
                    }else if (payType === 'wechat') {

                    }
                } else {
                    message.error("创建支付订单失败，请稍后再试")
                }
            } else {
                message.error("创建支付订单失败，请稍后再试")
            }
        })
    };

    placeAliPayOrder =(xsid,shid)=>{

        NHFetch('/alipay/placeAliPayOrder','POST',{shid:shid,stuId:xsid,TerminalType:'pc'}).then(res =>{
            if (res && res.code === 200){
                if (res.data){
                    const div=document.createElement('divform');
                    div.innerHTML=res.data;
                    document.body.appendChild(div);
                    document.forms[0].acceptCharset='UTF-8';//保持与支付宝默认编码格式一致，如果不一致将会出现：调试错误，请回到请求来源地，重新发起请求，错误代码 invalid-signature 错误原因: 验签出错，建议检查签名字符串或签名私钥与应用公钥是否匹配
                    document.forms[0].submit();
                }
            }else {
                message.error("支付宝订单创建失败，请稍后再试")
            }
        })

    };

    handleRadioChange =(e)=>{
        console.log(e.target.value);
        this.setState({
            payType:e.target.value
        })
    };
    render() {
        const {list} = this.state;

        const columns = [
            {
                title: "收费项目",
                dataIndex: "SFXMMC"
            },
            {
                title: "应付金额（元）",
                dataIndex: "QFJE"
            }
        ];

        return (
            <div>
                {this.state.jfje != 0 ? (
                    <p>
                        <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                          交易金额:{this.state.jfje}元
                        </span>
                    </p>
                ) : null}

                <Table
                    bordered
                    size="middle"
                    pagination={false}
                    columns={columns}
                    dataSource={list}
                />
                <br/>
                <p style={{ color: "red" }}>
                    特别说明：为了解决银行卡交易限额等原因，网上缴费支持多次缴费；请勿恶意多次小额缴费（如0.1元），以免你的银行卡检测有风险被禁止交易
                </p>

                <div>
                    <Divider />
                    <p>
                        <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                          支付方式：
                        </span>
                    </p>
                    <div style={{marginBottom:'45px'}}>
                        <Radio.Group size={'large'} onChange={this.handleRadioChange}>
                            <Radio.Button value={'alipay'} style={{ width: '115px',height: '50px',textAlign: 'center'}} className="spe">
                                <span style={{fontSize:'20px',fontWeight:'bolder'}}><Icon type={"alipay-circle"} style={{fontSize:'20px'}}/>支付宝</span>
                            </Radio.Button>
                            <Radio.Button value={'wechat'} style={{marginLeft:'10px',width: '115px',height: '50px',textAlign: 'center'}} className="fee">
                                <span style={{fontSize:'20px',fontWeight:'bolder'}}><Icon type={"wechat"} style={{fontSize:'20px'}}/>微信</span>
                            </Radio.Button>
                        </Radio.Group>
                    </div>
                </div>
                <Button
                    style={{
                        float: "left",
                        backgroundColor: "rgb(1, 176, 241)",
                        color: "#fff"
                    }}
                    onClick={this.handleSubmit}
                >
                    进入支付界面
                </Button>

                <style jsx="true" global="true">
                    {`
                          .ant-radio-button-wrapper-checked.spe{
                              z-index: 1;
                              color: #fafafa;
                              background: #40a9ff;
                              border-color: #f5222d;
                              box-shadow: -1px 0 0 0 #f5222d;
                          }
                          
                          .ant-radio-button-wrapper-checked.fee{
                              z-index: 1;
                              color: #fafafa;
                              background: rgb(31 159 43);
                              border-color: #f5222d;
                              box-shadow: -1px 0 0 0 #f5222d;
                          }
                    `}
                </style>
            </div>
        );
    }

}