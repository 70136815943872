import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Flex } from '@components';
import {Form, Input, Checkbox, Row, Col, Button, Modal,
        Upload, Icon, message,Spin,InputNumber,Radio} from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import {createUuid, getLoginUser} from '@utils/NHCore';
import NHFetch from "@utils/NHFetch";
import View from "@pages/SelfHelp/szjdChannel/view";
const Dragger = Upload.Dragger;
import createHistory from 'history/createHashHistory';
import CommonTip from "@pages/SelfHelp/common";
import NHSelect from "@components/common/NHSelect";
import NextButton from '@pages/SelfHelp/common/nextIndex'
import {mkbz} from "@pages/Assembly/siderMenu/menuObj";
import Hint from "@pages/SelfHelp/common/hint";
import NHFormItem from "@components/common/NHFormItem";
const history = createHistory();
const { TextArea } = Input;
const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 }
};

const xsids = getLoginUser()?getLoginUser().userId:'';
@Form.create()
export default class SzjdChannel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mkbz:'GD005_1',
            hjmc:'',
            uploading:false,
            loading:false,
            previewVisible: false,
            fileList: [],
            checkGroup:[],//绿色通道办理原因
            data:{},//绿色通道申请信息
            nextUrl:'',
            search:'',
            operable:true,//是否显示环节
            nonOperationalData:{},//不可操作的数据
            sqlyFlag:false , //申请理由flag
            jymFlag:false ,  //6位数回执校验码flag
            judgeImgList: ['.png', '.PNG', '.jpg', '.JPG', '.bpm', '.BPM', '.jpeg', '.JPEG'],   //可以导入的图片格式
            judgeFileList: ['.pdf', '.PDF', '.doc', '.DOC', '.zip', '.ZIP', '.txt', '.TXT', '.xls', '.XLS', '.rar', '.RAR'],  //可以导入的非图片格式
            dkfsValue:"",
            dkfsList:[],
        };
    }

    componentDidMount(){
        this.queryQztjAndYlhj((operable) => {
                if (operable) {
                    this.loadLstdInfo();
                    this.loadLstdBlyy();
                    this.loadLstddkfs();
                    this.updateZzbdBlzt();
                }
            });
       
    }

    //迎新现场已办理时迎新网站自动办理
    updateZzbdBlzt = () =>{
            let zzbdhjid = this.getQueryString('pkid');
            NHFetch('/zzbd/updateZzbdBlzt', 'GET',{zzbdhjid:zzbdhjid,hjdm:'GD005_1'}).then(res => {
                if(res.data){
                    this.reloadMenu();
                }
            })
    }

    //查询前置条件和依赖环节
    queryQztjAndYlhj=(callback)=>{
        let xsid = getLoginUser()?getLoginUser().userId:'';
        if(!xsid){
            this.setState({
                nonOperationalData:{nologin:true},
                operable:false,
            });
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', {xsid:xsid,zzbdhjid:zzbdhjid})
            .then(res => {
                if (res && res.data) {
                    let kfsj = res.data['kfsj'];
                    let ylhj = res.data['ylhj'];
                    let qzhj = res.data['qzhj'];
                    let nohj = res.data['nohj']; 
                    this.setState({nonOperationalData:res.data});
                    if(kfsj && kfsj['YXSJ']!='1'){
                         this.setState({operable:false});
                    }

                    if(ylhj && ylhj.length>0){
                        this.setState({operable:false});
                    }

                    if(qzhj && qzhj.length>0){
                        this.setState({operable:false});
                    }
                    if (nohj) {
                        this.setState({ operable: false });
                    }
                    if (callback) {
                        callback(this.state.operable);
                    }
                    
                }
            });
    }

    getQueryString =(name)=> {
        return decodeURIComponent((new RegExp('[?|&]'+name+'='+'([^&;]+?)(&|#|;|$)').exec(location.href)||[, ''])[1].replace(/\+/g, '%20')) || null
    }

    setMenuList=(menuList)=>{
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem,index)=>{
            if(elem.HJDM==bz){
                let hjmc = elem.HJMC;
                this.setState({hjmc:hjmc})
                nextCount = index+1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if(menu){
            let hjdm = menu.HJDM;
            if(hjdm.indexOf('GD')==0){
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl:gdUrl,
                    search:'pkid='+menu.PKID,
                })
            }else if(hjdm.indexOf('ZDY')==0){
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl:zdyUrl,
                    search:'pkid='+menu.PKID+'&mkbz='+hjdm+'&lx='+menu.LX,
                })
            }
        }else{
            this.setState({
                nextUrl:'',
                search:'',
            })
        }
    }

    loadLstddkfs=()=>{
        NHFetch('front/zhxg-unauth/yxwz/proData/selectDataList','get','sign=dmk_YXXT_DKLX').then(res=>{
            if(res && res.code == 200){
               let dkfsList = res.data;
                this.setState({
                    dkfsList:dkfsList
                })
            }
        });
    }

    loadLstdBlyy=()=>{
        NHFetch('front/zhxg-unauth/yxwz/proData/selectDataList','get','sign=dmk_YXXT_LSTD_BLYY').then(res=>{
            if(res){
                let col = [];
                res.data.map(item=>{
                    col.push(
                        <Col span={6}>
                            <Checkbox value={item.VALUE}>
                                {item.LABEL}
                             </Checkbox>
                        </Col>
                    );
                });
                let html = <Row>{col}</Row>
                this.setState({checkGroup:html});
            }
        });
    }

    //读取学生的绿色通道信息
    loadLstdInfo=()=>{
        this.setState({
            dkfsValue:""
        })
        NHFetch('/xmd/szkj/lstd/getLstdInfoByXsid', 'GET').then(resData => {
            if (resData && resData.data) {
                this.setState({ data: resData.data });
                let fileList = [];
                if(resData.data && resData.data['fj']){
                    resData.data['fj'].map((elem,index)=>{
                        //格式化对于附件取值
                        let file = {
                            uid: elem.fjid,
                            name: elem.fjmc,
                            response:{
                                data:{
                                    ssbh:elem.fjid,
                                    fjmc:elem.fjmc,
                                }
                            },
                            status: 'done'
                        }
                        fileList.push(file);
                    })
                }
                this.setState({fileList:fileList});
                this.props.form.setFieldsValue({fj:{fileList:this.state.fileList}});
            }else{
                this.setState({ data:{}});
                this.setState({fileList:[]});
                this.props.form.resetFields();
            }
        });
    }

    // 转化base64
    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    // 点击文件链接或预览图标时的回调
    handlePreview = file => {
        const isImage = /^image\/.*$/i.test(file.type);
        if (isImage) {
            if (file) {
                this.getBase64(file, imageUrl => {
                    this.setState({
                        previewImage: imageUrl,
                        previewVisible: true
                    });
                });
            } else {
                this.setState({
                    previewImage: file.url || file.thumbUrl,
                    previewVisible: true
                });
            }
        }else if(file && file['response'] && file['response']['data']){//其他情况
            let isImage = true;
            let fjmc = file['response']['data']['fjmc'];
            let ssbh = file['response']['data']['ssbh'];
            if (
                !this.setJudge(fjmc,this.state.judgeImgList)
            ) {
                isImage = false;
            }

            if (isImage) {
                this.showPreview(`anonymity/docrepo/download/file?attachmentId=${ssbh}`);
            } else {
                window.open(`anonymity/docrepo/download/file?attachmentId=${ssbh}`);
            }
        }
    };

    showPreview=(url)=>{
        this.setState({
            previewImage:url,
            previewVisible: true
        });
    }



    // 关闭图片弹窗
    handleCancel = () => this.setState({ previewVisible: false });

    // 提交
    handleSubmit = e => {
        let  xsids = getLoginUser().userId;
        console.log( getLoginUser().userId);
        this.setState({loading:true});
        e.preventDefault();
        let zzbdhjid = this.getQueryString('pkid');
        this.props.form.validateFieldsAndScroll((err, values) => {
            if(err){
                this.setState({loading:false});
                return false;
            }
            let hzjym = values['hzjym'];
            if(hzjym != undefined && hzjym.trim().length != 6){
                this.setState({loading:false});
                message.warning("请填写6位回执校验码");
                return ;
            }

            let blyy = values['blyy'];
            let sqsm = values['sqsm'];
            let pkid = values['pkid'];
            let msg ="";
            /* blyy.map( item =>{
                if(item == '99'){
                    if(typeof(sqsm) == "undefined" || sqsm.trim().length == 0){
                        sqsmFlag = true ;
                        msg="其它原因，请填写申请理由"
                    }
                }
            })
            console.log(sqsmFlag);
            if(sqsmFlag){
                this.setState({loading:false});
                message.warning("其它原因，请填写申请理由");
                return ;
            } */
            let dkyh = values['dkyh'];
            let blje = values['blje'];
            let params = {
                blyy:blyy,
                blje:blje,
                hzjym:hzjym,
                dkyh:dkyh,
                sqsm:sqsm,
                dklx:this.state.dkfsValue,
                xsid:xsids,
                zzbdhjid:zzbdhjid,
                pkid:pkid,
                dkfsValue:"",
            }
            if(!pkid){
                NHFetch('/xmd/szkj/lstd/insert',"POST",params)
                    .then(res => {
                        if (res&&res.code==200) {
                            message.success("绿色通道申请保存成功。");
                            this.loadLstdInfo();
                            this.reloadMenu();
                        }
                        this.setState({ loading: false })
                    }).catch((res) => {
                    this.setState({ loading: false })
                    return res;
                });
            }else{
                NHFetch('/xmd/szkj/lstd/update',"POST",params)
                    .then(res => {
                        if (res && res.data && res.data>0) {
                            message.success("绿色通道申请保存成功。");
                            this.loadLstdInfo();
                            this.reloadMenu();
                        }else{
                            message.warning("绿色通道申请保存失败，请重试。");
                        }
                        this.setState({ loading: false })
                    }).catch((res) => {
                    this.setState({ loading: false })
                    return res;
                });
            }
        });
    };

    handleChange = ({ fileList } ) => {
            let count = 0;
            fileList.map((elem)=>{
                if(elem && elem['status'] && elem['status']==='uploading'){
                    this.setState({uploading:true});
                    return;
                }else if(elem && elem['status']){
                    count++;
                }
            })
            if(count>0){
                this.setState({uploading:false});
            }
    }

    //判断文件类型是否正常  name：文件名称  map：类型范围数组  true正常
    setJudge = (name, map) => {
        let flag = false;
        map.map(item => {
            if (name.indexOf(item) !== -1) {
                flag = true;
            }
        })
        return flag;
    }

    beforeUpload = (file) => {
            if (
                !(this.setJudge(file.name, this.state.judgeImgList) ||
                this.setJudge(file.name, this.state.judgeFileList))
            ) {
                message.error('不能上传该格式文件');
                return false;
            } else {
                return true;
            }
    }

    remove=(ssbh)=>{
        let url = `api/docrepo/attachment/sourceId/delete?souerceId=${ssbh}`;
        NHFetch(url,"POST").then(res => {
            if (res.data && res.data > 0) {
                message.success('已删除材料.');
            }
        });
    }

    //重新加载菜单
    reloadMenu=()=>{
        this.refs.siderMenu.reloadMenuList()
    }

    // 回调
    dkfsOnChange = e => {
        console.log('radio checked', e.target.value);
        this.setState({
            dkfsValue: e.target.value,
        });
      };


    render() {
        const {form} = this.props;
        const { getFieldDecorator } = this.props.form;
        const { previewImage, previewVisible,jymFlag,sqlyFlag ,dkfsList,dkfsValue} = this.state;
        console.log("dkfsValue",dkfsValue);
        const props = {
            name: 'file',
            action: 'anonymity/docrepo/upload',
            data: { uuid: createUuid(), type: '1' },

            onRemove: file => {
                this.setState(({ fileList }) => {
                    const index = fileList.indexOf(file);
                    const newFileList = fileList.slice();
                    newFileList.splice(index, 1);
                    this.props.form.setFieldsValue({fj:{fileList:newFileList}});
                    return {
                        fileList: newFileList
                    };
                },()=>{
                    if(file.response && file.response.data && file.response.data.ssbh){
                        this.remove(file.response.data.ssbh);
                    }
                });
            },
            beforeUpload: file => {
                if(!this.beforeUpload(file)){
                    return false;
                }
                this.setState(({ fileList }) => ({
                    fileList: [...fileList, file]
                }));
                return true;
            },
            onChange:file=>{this.handleChange(file)},
            onPreview: this.handlePreview,
            fileList:this.state.fileList,
        };

        let edit = false;
        const data = this.state.data;
        if(JSON.stringify(data) == "{}"){
            edit = true;
        }else if(data && data['shzt']==='9'){
            edit = true;
        }
        return (
            <div style={{position:'relative'}}>{this.state.operable?
                <div>
                <Flex className="w1180 mt-20" align="start">
                    <SiderMenu
                                ref={"siderMenu"}
                                setMenuList={this.setMenuList}
                                title="自助报到"
                                mkbz = {this.state.mkbz}
                          /*     active={4}*/
                                menuType='自助报到'/>
                    <Flex.Item className="page_con_r" styleName="">
                        <Breadcrumb
                            noLine
                            list={[
                                { name: '首页', url: '/index' },
                                { name: '自助报到', url: '/register' },
                                { name: this.state.hjmc }
                            ]}
                        />
                        <div className="box">
                            <Hint/>
                            {edit?<Form  onSubmit={this.handleSubmit}>
                                <TitleTop title="申请信息" extra={false} />
                                <div className="p-sw">
                                    <div style={{display:'none'}}>
                                    <NHFormItem id={'pkid'} initialValue={data['pkid']} form={form}>
                                        <Input/>
                                    </NHFormItem>
                                    </div>
                                    <div style = {{marginLeft:"30px"}}>
                                        <NHFormItem id={'blyy'} required={true} initialValue={data['blyydm']} form={form} formItemLayout={{labelCol:{span:4},wrapperCol:{span:16}}} label="办理原因">
                                            <CheckboxGroup style={{ width: '100%'}}>
                                                {this.state.checkGroup}
                                            </CheckboxGroup>
                                        </NHFormItem>
                                    </div>
                                    <div className="form_item" style={{marginLeft: '7%'}}>
                                        <NHFormItem required={true} id={'dkfs'} initialValue={data['dkfs']} form={form}  formItemLayout = {formItemLayout} label="贷款方式">
                                            <Radio.Group onChange={this.dkfsOnChange} value={this.state.dkfsValue} style={{display:"flex",margin:"10px"}}>
                                                {
                                                    dkfsList.map((item,index)=>{
                                                        return  <Radio value={item.VALUE}>{item.LABEL}</Radio>
                                                    })
                                                }
                                            </Radio.Group>
                                        </NHFormItem>

                                        {dkfsValue=='1'&&<NHFormItem id={'blje'} required={true}  initialValue={data['blje']} form={form}  formItemLayout = {formItemLayout} label="贷款金额">
                                            <InputNumber style={{width:'100%'}} min={0} max={9999999}  precision="0" placeholder="请填写贷款金额（元）" />
                                        </NHFormItem>}
                                        {dkfsValue=='1'&&<NHFormItem id={'dkyh'} required={true}  initialValue={data['dkyh']} form={form}  formItemLayout = {formItemLayout} label="贷款银行">
                                            <NHSelect sign={"dmk_yxwz_dkyh"} onChange={(v) => { }}/>
                                        </NHFormItem>}
                                        {dkfsValue=='1'&&<NHFormItem id={'hzjym'} required={true} initialValue={data['hzjym']} form={form}  formItemLayout = {formItemLayout} label="回执校验码">
                                            <Input maxLength ={6} placeholder="6位数回执校验码" />
                                        </NHFormItem>}

                                        <NHFormItem id={'sqsm'} initialValue={data['sqsm']} form={form} required={true} formItemLayout = {formItemLayout} label="申请理由">
                                            <TextArea maxLength ={50} placeholder="请填写申请理由" />
                                        </NHFormItem>
                                    </div>
                                </div>
                                <div className="text-center mt-50">
                                    <Button type="primary" htmlType="submit" onClick={this.handleSubmit}> 
                                        保存
                                    </Button>
                                    <NextButton nextUrl={this.state.nextUrl}  search={this.state.search}/>
                                </div>
                            </Form>:<View  reloadMenu={this.reloadMenu} nextUrl={this.state.nextUrl} loadLstdInfo={this.loadLstdInfo}  search={this.state.search} showPreview={this.showPreview} setJudge={this.setJudge}  data={this.state.data} judgeImgList={this.state.judgeImgList}/>}
                        </div>
                    </Flex.Item>
                </Flex>
                    <Spin style={{ position:'absolute',top:'300px',left:'60%'}} spinning={this.state.loading}/> </div>:<CommonTip hjmc = {"绿色通道（深圳技大"}  key={"GD005_1"} mkbz='GD005_1' nonOperationalData={this.state.nonOperationalData}/>}
            </div>

        );
    }
}
