import React from 'react';
import { Button, Table, Spin, message } from 'antd';
import {getLoginUser} from "@utils/NHCore";
import NHFetch from "@utils/NHFetch";
import {mkbz} from "@pages/Assembly/siderMenu/menuObj";
import {Flex} from "@components/index";
import SiderMenu from "@pages/Assembly/siderMenu";
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import Hint from "@pages/SelfHelp/common/hint";
import TitleTop from "@pages/Assembly/titleTop";
import NextButton from "@pages/SelfHelp/common/nextIndex";
import CommonTip from "@pages/SelfHelp/common";
import '../pay/index.css';

export default class PayOnlineInit extends React.Component{

    //获取query参数
    getQueryString = (name) => {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    };

    //重新加载菜单
    reloadMenu = () => {
        this.refs.siderMenu.reloadMenuList()
    };

    //刷新左边菜单
    setMenuList = (menuList) => {
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem, index) => {
            if (elem.HJDM == bz) {
                let hjmc = elem.HJMC;
                this.setState({ hjmc: hjmc })
                nextCount = index + 1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if (menu) {
            let hjdm = menu.HJDM;
            if (hjdm.indexOf('GD') == 0) {
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl: gdUrl,
                    search: 'pkid=' + menu.PKID,
                })
            } else if (hjdm.indexOf('ZDY') == 0) {
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl: zdyUrl,
                    search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
                })
            }
        } else {
            this.setState({
                nextUrl: '',
                search: '',
            })
        }
    };
    

    constructor(props) {
        super(props);
        this.state = {
            mkbz: 'GD008_9',
            hjmc: '',
            data: [],
            loading: true,
            btnLoading: false,
            nextUrl: '',//左侧菜单加载完后传进来
            search: '',
            operable: true,//是否显示环节
            nonOperationalData: {},//不可操作的数据
            flag: false,//true缴费,false不需要缴费
            hjblzt: false,//环节办理状态
        }
    }

    componentDidMount(){
        //查询环节是否办理
        this.checkIsZzbdHjLinked()
        .then(hjblzt => {
            //查询是否存在前置环节和依赖环节
            if(!hjblzt){
                //查询是否存在前置环节和依赖环节
                return this.queryQztjAndYlhj()
            }else{
                this.getSfxxList(hjblzt)
            }
        })
        .then(res => {
            if(res){
                //查询收费信息
                return this.getSfxxList();
            }
        })

    }

    //查询环节是否办理
    checkIsZzbdHjLinked = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        let zzbdhjid = this.getQueryString('pkid');

        return  NHFetch("/zzbdCommon/checkIsZzbdHjLinked", 'GET', { xsid: xsid, zzbdhjid: zzbdhjid })
                .then(res => {
                    if (res && res.code == 200) {
                        this.setState({hjblzt:res.data})
                    }
                    return res.data
                });
    };

    //查询前置条件和依赖环节
    queryQztjAndYlhj = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (!xsid) {
            this.setState({nonOperationalData: { nologin: true }, operable: false,});
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        return  NHFetch("/zzbd/queryQztjAndYlhj", 'GET', { xsid: xsid, zzbdhjid: zzbdhjid })
                .then(res => {
                    if (res && res.data) {
                        let kfsj = res.data['kfsj'];
                        let ylhj = res.data['ylhj'];
                        let qzhj = res.data['qzhj'];
                        let nohj = res.data['nohj'];
                        this.setState({ nonOperationalData: res.data });
                        if (kfsj && kfsj['YXSJ'] != '1') {
                            this.setState({ operable: false });
                        }else if (ylhj && ylhj.length > 0) {
                            this.setState({ operable: false });
                        }else if (qzhj && qzhj.length > 0) {
                            this.setState({ operable: false });
                        }else if (nohj) {
                            this.setState({ operable: false });
                        }else{
                            return true;
                        }
                    }
                });
    };

    //查询收费信息
    getSfxxList = (hjblzt) => {
        NHFetch('/orderPayOnline/getItemOfPay', 'GET')//所有的收费数据
            .then(resData => {
                if (resData && resData.code == 200) {
                    let list = resData.data.filter((item) => !item.flag);//筛选出没缴费的项目
                    this.setState({ 
                        data: resData.data, 
                        flag: list.length > 0,
                        loading: (list.length > 0 && !hjblzt)
                    });
                    if(list.length > 0 && !hjblzt) {//需要缴费且没办理往下走
                        //查询缴费情况
                        this.queryPayQuery(list[0].fcode)
                    } else {
                        this.reloadMenu()
                    }
                }
            });
    };

    //查询缴费情况
    queryPayQuery = (fcode) => {
        NHFetch('/orderPayOnline/queryPayQuery', 'GET',{fcode:fcode})
            .then(res => {
                if (res && res.code == 200) {
                    this.setState({ 
                        loading: false,
                        flag: !res.data,
                        btnLoading: false
                    });
                    this.reloadMenu()
                }
            });
    };

    submitForm = ()=> {
        const uni_no = this.state.data[0].uni_no
        const name = this.state.data[0].name
        const list = this.state.data
        const returnUrl = window.location.href
        const mapList = list.map(i=>{
            return {
                feeitemid:i.fcode,
                dtlremark:i.fname,
                feeord:i.startyear,
                amt:i.amt,
            }
        })

        NHFetch('/orderPayOnline/2/paymentAppPay', 'POST',{
            orderinfono:uni_no,
            orderinfoname:name,
            returnURL:returnUrl,
            billdtl:mapList,
        })
        .then(res => {
            if(res && res.data == null){
                message.info("存在交易订单")
                return
            }
            this.setState({btnLoading:true},()=>{
                console.log("res.data",res.data)
                this.paymentAppPay(res.data)
            })
        })
    }

    paymentAppPay = (map)=> {
        const {sign, sysid, subsysid, data, pczflj} = map
        const form = document.createElement("form");
        form.action = pczflj;
        form.method = "post";

        const signInput = document.createElement("input");
        signInput.type = "hidden";
        signInput.name = "sign";
        signInput.value = sign;
        form.appendChild(signInput);

        const sysidInput = document.createElement("input");
        sysidInput.type = "hidden";
        sysidInput.name = "sysid";
        sysidInput.value = sysid;
        form.appendChild(sysidInput);

        const dataInput = document.createElement("input");
        dataInput.type = "hidden";
        dataInput.name = "data";
        dataInput.value = data;
        form.appendChild(dataInput);

        const subsysidInput = document.createElement("input");
        subsysidInput.type = "hidden";
        subsysidInput.name = "subsysid";
        subsysidInput.value = subsysid;
        form.appendChild(subsysidInput);

        document.body.appendChild(form);
        form.submit();
    }

    
    render() {
        const {  data, loading, flag, btnLoading, hjblzt } = this.state;
        const columns = [
            {
                title: '序号',
                dataIndex: 'ROW_ID',
                render: (text, record,index) => {
                    return index+1;
                }
            },
            {
                title: '开账年月',
                dataIndex: 'startyear',
                render: (text, record) => {
                    return <span className="text-primary">{text}</span>;
                }
            },{
                title: '收费项代码',
                dataIndex: 'fcode',
                render: (text, record) => {
                    return <span className="text-primary">{text}</span>;
                }
            },{
                title: '收费项名称',
                dataIndex: 'fname',
                render: (text, record) => {
                    return <span className="text-primary">{text}</span>;
                }
            },{
                title: '应收金额',
                dataIndex: 'samt',
                render: (text, record) => {
                    if (text > 0) {
                        return <span className="text-info-red">{text}</span>
                    } else {
                        return text
                    }
                }
            },{
                title: '已收金额',
                dataIndex: 'yamt',
                render: (text, record) => {
                    if (text > 0) {
                        return <span className="text-success">{text}</span>
                    } else {
                        return text
                    }
                }
            },
        ];
        return (
            <div styleName="page_pay">
                {this.state.operable ?
                    <Flex className="w1180 mt-20" align="start">
                        <SiderMenu
                            ref={"siderMenu"}
                            setMenuList={this.setMenuList}
                            title="自助报到"
                            mkbz={this.state.mkbz}
                            menuType='自助报到'
                        />
                        <Flex.Item className="page_con_r">
                            <Breadcrumb
                                noLine
                                list={[
                                    { name: '首页', url: '/index' },
                                    { name: '自助报到', url: '/register' },
                                    { name: this.state.hjmc }
                                ]}
                            />
                            <div className="box">
                                <Hint />
                                <TitleTop title={this.state.hjmc} extra={false} />
                                <div className="p-sw mt-20">
                                    <br/>
                                    <Spin spinning={loading}>
                                         <Table
                                            bordered
                                            size="middle"
                                            rowKey={record => record.fcode}
                                            pagination={false}
                                            columns={columns}
                                            dataSource={data}
                                        />
                                        <div className="text-center mt-50">
                                            <div className="text-center mt-50">
                                                {!hjblzt&&flag&&<Button loading={btnLoading} onClick={() => this.submitForm()} type="primary" htmlType="submit">网上支付</Button>}
                                                <NextButton nextUrl={this.state.nextUrl} search={this.state.search} />
                                            </div>
                                        </div>
                                    </Spin>
                                   
                                </div>
                            </div>
                        </Flex.Item>
                    </Flex>
                    :
                    <CommonTip hjmc={"在线缴费"} key={'GD008_9'} mkbz='GD008_9' nonOperationalData={this.state.nonOperationalData} />}
            </div>
        );
    }
}