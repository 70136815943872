import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Flex} from '@components';
import {message} from 'antd';
import NHFetch from "@utils/NHFetch";
import './index.css';

/**
 * @author weishihuai
 * @date 2018/7/6
 * @time 14:41
 * @Description: 迎新网站-自助报到-宿舍分配-选择床位弹层
 */
export default class SelectModal extends React.Component {

    static propTypes = {
        className: PropTypes.string,
        id: PropTypes.any,
        currentClickCwh: PropTypes.string,
        onChange: PropTypes.func
    };

    static defaultProps = {
        id: '',
        onChange: Function.prototype,
        currentClickCwh: ""
    };

    constructor(props) {
        super(props);
        const {id} = props;
        this.state = {
            xzId: id,
            currentClickCwh: "",      //当前床位号
        };
    }

    componentDidMount() {
        const {currentClickCwh, data} = this.props;
        const cws = data.allNum;
        this.setState({
            currentClickCwh: currentClickCwh
        });
        this.getPicByCws(cws);
    }

    getPicByCws = (cws) => {
        NHFetch('/zzbd/zxss/getPicByFjlx','GET', {lxbz:"cws",lxz:cws,}).then(res=>{
            if(res && res.data){
                this.setState({
                    picList: res.data,
                    picData: "data:image/png;base64," + res.data
                }, () => {
                    // this.setState({
                    //     picUrl : <img src={this.state.picData} alt="" className="avatar" />
                    // })
                })
            }
     })
    }

    render() {
        const {xzId, currentClickCwh, picUrl, picList} = this.state;
        const {className, data, onChange} = this.props;

        return (
            <div className={classNames('text-center', className)}>
                
                {
                    picList ? 
                    <div style={{'white-space': 'nowrap'}}>
                        <style jsx="true" global="true">
                        {`
                            .ant-modal-content {
                                width: 740px;
                            }            
                        `}
                        </style>
                        {
                            this.state.picList.map((el, i) => {
                                return (
                                    <img src={"data:image/png;base64," + el} alt="" className="avatar" style={{width:250,height:250,margin:1}}/>
                                )
                            })
                        }
                    </div>
                    : null
                }
                
                <div className="text-blue">{currentClickCwh ? currentClickCwh > 9  ? currentClickCwh + "床" : ("0" + currentClickCwh + "床") : "请选择床铺"}</div>
                {/* <div>{data.ysje + "元/年"}</div> */}
                <Flex wrap="wrap" align="center" justify="center">
                    <div styleName="modal_box">
                        {data.bedList[0] &&
                        data.bedList.map((el, i) => {
                            return (
                                <div key={i}>
                                    {el.map((cwxx, index) => {
                                        return (
                                            <div
                                                styleName="modal_list"
                                                onClick={() => {
                                                    if (cwxx.xz === "0") {
                                                        this.setState({
                                                            xzId: cwxx.id,
                                                            currentClickCwh: cwxx.cwh
                                                        });
                                                        onChange(cwxx);
                                                    } else {
                                                        message.warning(
                                                            '抱歉，该床铺已选择！'
                                                        );
                                                    }
                                                }}
                                                key={index}
                                            >
                                                <div
                                                    styleName={classNames(
                                                        'select_item', {
                                                            select_item_b:
                                                            cwxx.xz === '1',
                                                            select_item_y:
                                                            (xzId === cwxx.id && cwxx.xz === '0') || cwxx.currentXsSelected
                                                        }
                                                    )}
                                                />
                                                <div>
                                                    {cwxx.cwh > 9 ? (cwxx.cwh) + "床" : "0" + (cwxx.cwh) + "床"}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </Flex>
            </div>
        );
    }
}
