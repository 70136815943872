import React from 'react';
import { Link } from 'react-router-dom';
import { Flex } from '@components';
import { Button, Table, Icon, InputNumber,Pagination,message,Modal } from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import NextButton from '@pages/SelfHelp/common/nextIndex'
import './index.css';
import NHFetch from "@utils/NHFetch";
import { getLoginUser } from "@utils/NHCore";
import createHistory from 'history/createHashHistory';
import CommonTip from "../common";
import { mkbz } from "@pages/Assembly/siderMenu/menuObj";
import Hint from "@pages/SelfHelp/common/hint";
import Order from './order.js';
const history = createHistory();
export default class SszyxfPay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mkbz: 'GD011',
            hjmc: '',
            data: [],
            loading: false,
            pageData: {//分页设置的是三个参数
                total: 0,
                pageSize: 10,//当前页默认多少条数据
                page: 1
            },
            nextUrl: '',//左侧菜单加载完后传进来
            search: '',
            operable: true,//是否显示环节
            nonOperationalData: {},//不可操作的数据
            isHjLinked: false,              //环节是否办理标志
            zxjfInfo:{},                    //在线缴费相关信息
            hjje:0,                          //缓交金额
            jfxxHz:{},                        //缴费信息汇总
            visible: false,
            flage:true                        //是否存在需要缴费的项目
        };
    }

    componentDidMount() {
        this.checkIsZzbdHjLinked();
        this.queryQztjAndYlhj();
        //this.getSfxxList();//列表显示
    }


    getSfxxList = () => {
        NHFetch('/jfxtUnauth/yxwzjfxx/getJfxxList', 'GET')
            .then(resData => {
            if (resData && resData.data) {
                let data = resData.data.filter((item)=>{return item.SFXMDM=='GD_001'||item.SFXMDM=='GD_003'});//只显示学费和教材费的
                data.map((v,i)=>{ v.XH = i+1 });
                this.setState({ data: data },()=>{
                    if(data.length===0){//收费（学费和教材费）数据未导进去,不能自动办理环节，网上支付按钮也要屏蔽掉
                        this.setState({
                            flage:false
                        })
                    }else{
                        let list=this.state.data.filter((item) => item.QFJE>0?true:false);
                        if(list.length<=0){
                            this.setState({ flage:false })
                            this.handleConfirmSubmit();//进入这里说明没有欠费信息，所以直接保存环节数据
                        }
                    }

                    
                    
                });
            }
        });
    }
    showModal = () => {
        this.loadZxjfInfo();
        this.setState({
          visible: true,
        });
      };
    
      handleOk = e => {
        
        this.setState({
          visible: false,
        },()=>{
            this.getSfxxList();
        });
      };
    
      handleCancel = e => {
        this.setState({
          visible: false,
        },()=>{
            this.getSfxxList();
        });
      };    
      //获取在线缴费相关信息
      loadZxjfInfo=()=>{
       
        NHFetch('/jfxtUnauth/yxwzjfxx/getCurrDdxx', 'GET')
          .then(res => {
            if (res && res.data) {
              this.setState({
                zxjfInfo: res.data,
              });
            }
           
          });
      }
    
    //查询前置条件和依赖环节
    queryQztjAndYlhj = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (!xsid) {
            this.setState({
                nonOperationalData: { nologin: true },
                operable: false,
            });
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', { xsid: xsid, zzbdhjid: zzbdhjid })
            .then(res => {
                if (res && res.data) {
                    let kfsj = res.data['kfsj'];
                    let ylhj = res.data['ylhj'];
                    let qzhj = res.data['qzhj'];
                    let nohj = res.data['nohj']; 
                    this.setState({ nonOperationalData: res.data });
                    if (kfsj && kfsj['YXSJ'] != '1') {
                        this.setState({ operable: false });
                    }else if (ylhj && ylhj.length > 0) {
                        this.setState({ operable: false });
                    }else if (qzhj && qzhj.length > 0) {
                        this.setState({ operable: false });
                    }else if (nohj) {
                        this.setState({ operable: false });
                    }else{
                        this.getSfxxList();
                    }
                }
            });
    }

    getQueryString = (name) => {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    }

    setMenuList = (menuList) => {
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem, index) => {
            if (elem.HJDM == bz) {
                let hjmc = elem.HJMC;
                this.setState({ hjmc: hjmc })
                nextCount = index + 1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if (menu) {
            let hjdm = menu.HJDM;
            if (hjdm.indexOf('GD') == 0) {
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl: gdUrl,
                    search: 'pkid=' + menu.PKID,
                })
            } else if (hjdm.indexOf('ZDY') == 0) {
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl: zdyUrl,
                    search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
                })
            }
        } else {
            this.setState({
                nextUrl: '',
                search: '',
            })
        }
    }

    onSave = () => {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({
                loading: false
            });
        }, 1000);
    };

    //检查该环节是否已经办理
    checkIsZzbdHjLinked = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        let zzbdhjid = this.getQueryString('pkid');

        NHFetch("/zzbdCommon/checkIsZzbdHjLinked", 'GET', { xsid: xsid, zzbdhjid: zzbdhjid })
            .then(res => {
                if (res) {
                    if (res.data) {
                        this.setState({
                            isHjLinked: res.data
                        });
                    } else {
                        this.setState({
                            isHjLinked: false
                        })
                    }
                } else {
                    this.setState({
                        isHjLinked: false
                    })
                }
            });
    };

    //确认知悉
    handleConfirmSubmit = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        let zzbdhjid = this.getQueryString('pkid');
        let params = { xsid: xsid, zzbdhjid: zzbdhjid };
            NHFetch('/zzbdCommon/commonSaveLinkTransactionStatus', 'POST', params)
            .then(res => {
                if (res) {
                    if (res.data === 1) {
                            this.checkIsZzbdHjLinked();
                            this.reloadMenu();
                        }
                    } else {
                        message.error('确认失败，请稍后重试！');
                    }
            });
    };

    //重新加载菜单
    reloadMenu = () => {
        this.refs.siderMenu.reloadMenuList()
    }

    render() {
        const { loading, data, isHjLinked,zxjfInfo } = this.state;
        const columns = [
            {
                title: '序号',
                dataIndex: 'XH'
            },
            {
                title: '收费项目',
                dataIndex: 'SFXMMC',
            },
            {
                title: '应收金额（元）',
                dataIndex: 'YSJE',
            },
            {
                title: '实收金额（元）',
                dataIndex: 'SSJE',
                render: (text, record) => {
                    return <span className="text-primary">{text}</span>;
                }
            },
            {
                title: '缓交金额（元）',
                dataIndex: 'JMJE',
                render: (text, record) => {
                    return <span className="text-primary">{text}</span>;
                }
            },
            {
                title: '欠费金额（元）',
                dataIndex: 'QFJE',
                render: (text, record) => {
                    if (text > 0) {
                        return <span className="text-info-red">{text}</span>
                    } else {
                        return text
                    }
                }
            }
        ];



        return (
            <div styleName="page_pay">
                {this.state.operable ?
                    <Flex className="w1180 mt-20" align="start">
                        <SiderMenu
                            ref={"siderMenu"}
                            setMenuList={this.setMenuList}
                            title="自助报到"
                            /*active={7}*/
                            mkbz={this.state.mkbz}
                            menuType='自助报到'
                        />
                        <Flex.Item className="page_con_r">
                            <Breadcrumb
                                noLine
                                list={[
                                    { name: '首页', url: '/index' },
                                    { name: '自助报到', url: '/register' },
                                    { name: this.state.hjmc }
                                ]}
                            />
                            <div className="box">
                                <Hint />
                                <TitleTop title={this.state.hjmc} extra={false} />
                                <div className="p-sw mt-20">
                                    <Table
                                        bordered
                                        size="middle"
                                        pagination={false}
                                        columns={columns}
                                        dataSource={data}
                                    />
                                    <div className="text-center mt-50">
                                    {/* {
                                    this.state.flage==true?<Button
                                    type="primary"
                                    onClick={this.showModal}
                                    loading={loading}
                                >
                                    网上支付
                                </Button>:null
                                } */}
                                        <div className="text-center mt-50">
                                            {/* {!isHjLinked && <Button onClick={() => this.handleConfirmSubmit()}
                                                type="primary"
                                                htmlType="submit">确认知悉</Button>} */}

                                        {this.state.flage==true?<Button onClick={() => this.showModal()}
                                                type="primary"
                                                htmlType="submit">网上支付</Button>:null}
                                            <NextButton nextUrl={this.state.nextUrl} search={this.state.search} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Flex.Item>
                    </Flex>
                    : <CommonTip hjmc={"网上缴费"} key={'GD011'} mkbz='GD011' nonOperationalData={this.state.nonOperationalData} />}
                    <Modal
                        title="支付详情信息"
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={[
                            <Button key="submit" type="primary" onClick={this.handleOk}>
                                关闭  
                            </Button>,         
                        ]}>

                       <Order zxjfInfo={zxjfInfo} ddh={zxjfInfo.DDH} sfxmInfo={data}/>     
                    </Modal>
            </div>
        );
    }
}
