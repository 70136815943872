import React from 'react';
import { Link } from 'react-router-dom';
import { Flex } from '@components';
import { Row, Col, Form, DatePicker, Input, InputNumber, Button, Select, Table, Icon, TimePicker, Upload, Spin, Modal } from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import NHFetch from "@utils/NHFetch";
import { message } from "antd/lib/index";
import moment from 'moment';
import NHSelect from "@components/common/NHSelect";
import { createUuid, getLoginUser } from '@utils/NHCore';
import NextButton from '@pages/SelfHelp/common/nextIndex'
// import 'moment/src/locale/zh-cn';
import createHistory from 'history/createHashHistory';
import CommonTip from "../common";
import { mkbz } from "@pages/Assembly/siderMenu/menuObj";
import Hint from "@pages/SelfHelp/common/hint";
const Dragger = Upload.Dragger;
import NHUpload from '../../../components/common/NHUpload';
import TextArea from 'antd/lib/input/TextArea';

const history = createHistory();
const format = 'HH:mm';


const basicUrl = "/zzbd";
const commonUrl = '/zzbdCommon';

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
};

@Form.create()
export default class Report extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mkbz: 'GD004_9',
            hjmc: '',
            editable: true,
            update: false,
            initPOvalue: {
                nd: '',
                xsid: '',
                sfasbd: '',
                basbdyy: '',
                jtgj: '',
                ddz: '',
                dxsj: '',
                bc: '',
                ptrs: 0
            },
            value: null,
            lxjtgjmcList: {},
            lxjtgjmc: '',
            ddz: {},
            options: [],
            dqddz: '',
            nextUrl: '',//左侧菜单加载完后传进来
            search: '',
            operable: true,//是否显示环节
            nonOperationalData: {},//不可操作的数据
            isHide: true,
            lxjtgjbc: '来校交通工具',
            lxrqbc: '来校日期',
            isShow: false,//来校日期是否显示
            zjxxShow: false,//自驾信息是否显示
            fasbdShow: '0', //放弃报到标志
            fahbdqk: '',
            ycbdyy: '',// 延迟报到原因
            fqbdyy: '',// 放弃报到原因
            visibleEwm: false, //上传附件
            fjid: '',//说明书id
            lxrqfw: ['1900-01-01', '2100-12-31'] // 来校日期范围
        };
    }


    componentWillMount() {
        this.getDmkMc("YXWZ_LXJTGJ");

    }

    //渲染前获取信息
    componentDidMount() {
        this.queryQztjAndYlhj();
        this.getCssz("YXXT_ZZBD_BDQK_ZDBC_LXJTGJ")
        this.getCssz("YXXT_ZZBD_BDQK_ZDBC_LXRQ")
        this.getCssz("WYDX_ZZBD_BDQKDJ_LXRQFW")
        this.getDdz();
        this.getQkpo();
    }

    //查询前置条件和依赖环节
    queryQztjAndYlhj = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (!xsid) {
            this.setState({
                nonOperationalData: { nologin: true },
                operable: false,
            });
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', { xsid: xsid, zzbdhjid: zzbdhjid })
            .then(res => {
                if (res && res.data) {
                    let kfsj = res.data['kfsj'];
                    let ylhj = res.data['ylhj'];
                    let qzhj = res.data['qzhj'];
                    let nohj = res.data['nohj'];
                    this.setState({ nonOperationalData: res.data });
                    if (kfsj && kfsj['YXSJ'] != '1') {
                        this.setState({ operable: false });
                    }

                    if (ylhj && ylhj.length > 0) {
                        this.setState({ operable: false });
                    }

                    if (qzhj && qzhj.length > 0) {
                        this.setState({ operable: false });
                    }

                    if (nohj) {
                        this.setState({ operable: false });
                    }
                }
            });
    }

    getCssz = (csbz, ) => {
        NHFetch('/zzbdCommon/getCssz', "GET", { csbz: csbz })
        .then(res => {
            if (res) {
                if (res && res.code === 200) {
                    switch (csbz) {
                        case 'YXXT_ZZBD_BDQK_ZDBC_LXJTGJ':
                            this.setState({ lxjtgjbc: res.data });
                            break;
                        case 'YXXT_ZZBD_BDQK_ZDBC_LXRQ':
                            this.setState({ lxrqbc: res.data });
                            break;  
                        case 'WYDX_ZZBD_BDQKDJ_LXRQFW':
                            console.info("11111", res.data)
                            // lxrqfw = res.data.split(',')
                            this.setState({ lxrqfw: res.data.split(',') });
                            break;
                        default:

                    }  
                }
            }
        });
    }


    getQueryString = (name) => {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    }

    setMenuList = (menuList) => {
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem, index) => {
            if (elem.HJDM == bz) {
                let hjmc = elem.HJMC;
                this.setState({ hjmc: hjmc })
                nextCount = index + 1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if (menu) {
            let hjdm = menu.HJDM;
            console.log('hjdm: ' + hjdm)
            if (hjdm.indexOf('GD') == 0) {
                let gdUrl = mkbz[hjdm];
                console.log('gdurl:' + gdUrl)
                this.setState({
                    nextUrl: gdUrl,
                    search: 'pkid=' + menu.PKID,
                })
            } else if (hjdm.indexOf('ZDY') == 0) {
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl: zdyUrl,
                    search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
                })
            }
        } else {
            this.setState({
                nextUrl: '',
                search: '',
            })
        }
    }

    getQkpo() {
        const xsid = getLoginUser().userId;
        let lxjtgjmc = '';
        NHFetch(basicUrl + "/getWybdqkpoByXsidAndNd", "GET", { xsid: xsid })
            .then(res => {
                if (res) {
                    if (res.data) {
                        lxjtgjmc = res.data.jtgjmc;
                        this.setState({ initPOvalue: res.data, editable: false, lxjtgjmc: lxjtgjmc });
                        let dqddz = this.state.initPOvalue.ddz;
                        this.onChange(this.state.initPOvalue.jtgj);
                        this.setState({
                            dqddz: dqddz,
                            isHide: res.data.sfasbd != 1,
                            fasbdShow: res.data.fasbdqk,
                            fjid: res.data.fjid,
                            ycbdyy: res.data.fasbdqk == '1' ? res.data.basbdyy : '',
                            fqbdyy: res.data.fasbdqk == '2' ? res.data.basbdyy : ''
                        });
                        console.log('ycbd:'+ this.state.ycbdyy)
                    }
                }
            });
    }


    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return;
            }
            // if (values['sfasbd'] == '0') {
            //     if (values['basbdyy'] == null || typeof (values['basbdyy']) == "undefined" || values['basbdyy'].trim().length === 0) {
            //         message.error('不按时到达请填写原因。');
            //         return
            //     }
            // }
            let sUrl = '/saveBdqkdjData';
            if (this.state.update) {
                sUrl = '/updateBdqkdjData';
            }
            //修改 可编辑状态，并可以update数据
            if (!this.state.editable) {
                this.setState({ update: true, editable: true });
                return
            }
            const { dxsj } = values;
            let dxsjVal = moment(dxsj).format("YYYY-MM-DD HH:mm");
            values.dxsj = dxsjVal;
            const xsid = getLoginUser().userId;
            const zzbdhjid = this.getUrlParams("pkid");
            let params = { xsid: xsid, zzbdhjid: zzbdhjid, formData: values };


            //请求保存或修改数据
            NHFetch(basicUrl + sUrl, 'POST', params)
                .then(res => {
                    if (res) {
                        if (res.data == '1') {
                            message.success('信息保存成功！');
                            this.reloadMenu();
                            this.getQkpo();
                        } else {
                            message.error('信息保存失败，请重试！');
                        }
                    }
                })
        });
    };

    //获取url中传递的参数
    getUrlParams = (param) => {
        return decodeURIComponent((new RegExp('[?|&]' + param + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    };


    //获取下拉列表value对应的名称
    getDmkMc = (dmbz) => {
        NHFetch(basicUrl + "/getDmkMc", "GET", { dmbz: dmbz })
            .then(res => {
                if (res) {
                    if (res.data) {
                        if (dmbz === "YXWZ_LXJTGJ") {
                            this.setState({ lxjtgjmcList: res.data });
                        }
                    }
                }
            });
    }
    //获取到达站下拉列表的名称
    getDdz = () => {
        NHFetch(basicUrl + "/getDdz", "GET")
            .then(res => {
                if (res) {
                    if (res.data) {
                        this.setState({ ddz: res.data });
                    }
                }
            });
    }

    //到达站级联
    DdzSeleOption = (value) => {

        let dqddz = '';
        let coptions = '';
        if (this.state.ddz.length > 0) {
            coptions = this.state.ddz.map((item, key) => {
                if (value === item.ZTLX) {
                    dqddz = item.ZTMC;
                    return <Option key={item.ZTMC}>{item.ZTMC}</Option>
                } else {
                    return null
                }
            });

        }
        this.props.form.setFieldsValue({ ddz: dqddz });
        this.setState({ options: coptions, dqddz: dqddz });

    }


    //下拉框发生变化检测
    onChange = (value) => {
        this.setState({ zjxxShow: value == '04' })
        this.DdzSeleOption(value);
    }

    //动态显示报到情况填写
    onSfasbdChange = (value) => {
        this.setState({
            isHide: value != '1',
            isShow: true,
            fasbdShow: '0'
        });
    }

    //动态显示非按时报到情况填写
    onFasbdqkChange = (value) => {
        this.setState({
            fasbdShow: value,
            isHide: true,
            isShow: true,
        });
    }

    //重新加载菜单
    reloadMenu = () => {
        this.refs.siderMenu.reloadMenuList()
    }

    //陪同人数不符合时提示
    numberChange = (value) => {
        if (value > 10 || value < 0) {
            message.warning('陪同人数请填写0-10之内的整数！');
        }
    }

    //disabledStartDate设置到校日期的时间选择器的可选日期区域
    disabledStartDate = startValue => {
        let { lxrqfw } = this.state;
        return startValue && (startValue < moment(lxrqfw[0], 'YYYY-MM-DD') || startValue > moment(lxrqfw[1], 'YYYY-MM-DD').add('1', 'days'));
        // return startValue && startValue < moment().subtract(1, "days");
    };

    //点击上传证明材料 
    handleBtnClick = () => {
        this.setState({
            visibleEwm: true
        });
    }

    // 关闭模态框
    handleCancel = () => {
        this.setState({
            visibleEwm: false
        });
    };

    handleImageSubmit = () => {
        NHFetch("api/zhxg-yxwz/dynamic/save", "POST", [this.state.fjid]).then((res) => {
            if (res && res.code === 200) {
                this.setState({
                    visibleEwm: false
                });
                message.success("自愿放弃入学资格情况说明书保存成功");
            } else {
                message.error("附件保存失败");
            }
        });

    }


    render() {
        let templateUrl = '/yxxt' + '/proData/getFileByName?fileRoot=pdfTemp&fileName=' + encodeURI(encodeURI('人脸识别对比报告模板.pdf'));
        const { getFieldDecorator } = this.props.form;
        const { editable, initPOvalue, lxjtgjmc, options, dqddz, isHide, lxjtgjbc, lxrqbc, isShow, zjxxShow, fasbdShow, ycbdyy, fqbdyy, fjid } = this.state;
        return (
            <div>{this.state.operable ?
                <Flex className="w1180 mt-20" align="start">
                    <SiderMenu
                        ref={"siderMenu"}
                        setMenuList={this.setMenuList}
                        title="自助报到"
                        /* active={3}*/
                        mkbz={this.state.mkbz}
                        menuType='自助报到'
                    />
                    <Flex.Item className="page_con_r" styleName="">
                        <Breadcrumb
                            noLine
                            list={[
                                { name: '首页', url: '/index' },
                                { name: '自助报到', url: '/register' },
                                { name: this.state.hjmc }
                            ]}
                        />
                        <div className="box">
                            <Hint />
                            <TitleTop title={this.state.hjmc} extra={false} />
                            <Form onSubmit={this.handleSubmit}>
                                <div className="p-sw">
                                    <Flex align="start" wrap="wrap">

                                        <div className="form_item">
                                            <FormItem
                                                {...formItemLayout}
                                                label="是否按时报到"
                                            >
                                                {getFieldDecorator('sfasbd', {
                                                    initialValue: initPOvalue.sfasbd, placeholder: '请选择',
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message:
                                                                '请选择是否按时报到'
                                                        }
                                                    ]
                                                })(
                                                    editable ?
                                                        <Select placeholder="请选择" onChange={this.onSfasbdChange}>
                                                            <Option value="1">
                                                                是
                                                            </Option>
                                                            <Option value="0">
                                                                否
                                                            </Option>
                                                        </Select> : <span>{initPOvalue.sfasbd == 1 ? '是' : '否'}</span>
                                                )}
                                            </FormItem>
                                        </div>
                                        {isHide &&
                                            <div className="form_item">
                                                <FormItem
                                                    {...formItemLayout}
                                                    label="非按时报到情况"
                                                >
                                                    {getFieldDecorator('fasbdqk', {
                                                        initialValue: initPOvalue.fasbdqk, placeholder: '请选择',
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message:
                                                                    '非按时报到情况未选择'
                                                            }
                                                        ]
                                                    })(
                                                        editable ?
                                                            <Select placeholder="请选择" onChange={this.onFasbdqkChange}>
                                                                <Option value="1">
                                                                    延迟报到
                                                                </Option>
                                                                <Option value="2">
                                                                    放弃报到
                                                                </Option>
                                                            </Select> : <span>{initPOvalue.fasbdqk == 1 ? '延迟报到' : '放弃报到'}</span>
                                                    )}
                                                </FormItem>
                                            </div>
                                        }
                                        {isHide && fasbdShow == '1' &&
                                            <div className="form_item">
                                                <FormItem
                                                    {...formItemLayout}
                                                    label="延迟报到原因"
                                                >
                                                    {getFieldDecorator('ycbdyy', {
                                                        initialValue: ycbdyy,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message:
                                                                    '请选择延迟原因'
                                                            }
                                                        ]
                                                    })(
                                                        editable ? <NHSelect sign={'dmk_yxxt_wbdyy'} style={{ width: '100%' }}
                                                        /> :
                                                            <span>{initPOvalue.fasbdqkmc}</span>
                                                    )}
                                                </FormItem>
                                            </div>
                                        }

                                        {isHide && fasbdShow == '2' &&
                                            <div className="form_item">
                                                <FormItem
                                                    {...formItemLayout}
                                                    label="放弃报到原因"
                                                >
                                                    {getFieldDecorator('fqbdyy', {
                                                        initialValue: fqbdyy,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message:
                                                                    '请填写放弃原因'
                                                            }
                                                        ]
                                                    })(
                                                        editable ? <TextArea rows={3} placeholder="请填写放弃原因" /> :
                                                            <span>{fqbdyy}</span>
                                                    )}
                                                </FormItem>
                                            </div>
                                        }
                                        {isHide && fasbdShow == '2' &&
                                            <div className="form_item">
                                            </div>
                                        }
                                        {isHide && fasbdShow == '2' &&
                                            <div className="form_item">
                                                <FormItem
                                                    {...formItemLayout}
                                                    label="上传情况说明书"
                                                //style={{ display: 'none' }}
                                                >
                                                    {getFieldDecorator('fjid', {
                                                        initialValue: fjid,
                                                        
                                                    })
                                                        (

                                                            <NHUpload
                                                                serviceMark={"ly-sm-yxwz-svc"}
                                                                moduleName={"自愿放弃入学资格情况说明书"}
                                                                value={fjid}              
                                                                isEdit={editable}   
                                                                defaultEffective={true}                  
                                                                fileTypeLimit={['.png', '.PNG', '.jpg', '.JPG', '.bpm', '.BPM', '.jpeg', '.JPEG']}
                                                                onChange={(value) => { 
                                                                    
                                                                    this.setState({ fjid: value }) 
                                                                }}
                                                            />
                                                        )}

                                                </FormItem>
                                            </div>
                                        }
                                        {!isHide &&
                                            <div className="form_item">
                                                <FormItem
                                                    {...formItemLayout}
                                                    label='请选择交通工具'
                                                >
                                                    {getFieldDecorator('jtgj', {
                                                        initialValue: initPOvalue.jtgj,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message:
                                                                    '请选择交通工具'
                                                            }
                                                        ]
                                                    })(
                                                        editable ?
                                                            <NHSelect sign={"zhxg_yxwz_lxjtgj_wydx"} onChange={(v) => {
                                                                this.onChange(v);
                                                            }} />

                                                            : <span>{lxjtgjmc}</span>
                                                    )}
                                                </FormItem>
                                            </div>
                                        }
                                        {!isHide && zjxxShow &&
                                            <div className="form_item">
                                                <FormItem
                                                    {...formItemLayout}
                                                    label="驾驶人姓名"
                                                >
                                                    {getFieldDecorator('jsrxm', {
                                                        initialValue: initPOvalue.jsrxm,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message:
                                                                    '请填写姓名'
                                                            }
                                                        ]
                                                    })(
                                                        editable ? <Input placeholder="请填写姓名" /> :
                                                            <span>{initPOvalue.jsrxm}</span>
                                                    )}
                                                </FormItem>
                                            </div>
                                        }
                                        {!isHide && zjxxShow &&
                                            <div className="form_item">
                                                <FormItem
                                                    {...formItemLayout}
                                                    label="车牌号"
                                                >
                                                    {getFieldDecorator('cph', {
                                                        initialValue: initPOvalue.cph,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message:
                                                                    '请填写车牌号'
                                                            }
                                                        ]
                                                    })(
                                                        editable ? <Input placeholder="请填写车牌号" /> :
                                                            <span>{initPOvalue.cph}</span>
                                                    )}
                                                </FormItem>
                                            </div>
                                        }
                                        {!isHide && zjxxShow &&
                                            <div className="form_item">
                                                <FormItem
                                                    {...formItemLayout}
                                                    label="驾驶人联系电话"
                                                >
                                                    {getFieldDecorator('lxdh', {
                                                        initialValue: initPOvalue.lxdh,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message:
                                                                    '请填写联系电话'
                                                            }
                                                        ]
                                                    })(
                                                        editable ? <Input placeholder="请填写联系电话" /> :
                                                            <span>{initPOvalue.lxdh}</span>
                                                    )}
                                                </FormItem>
                                            </div>
                                        }
                                        {!isHide &&
                                            <div className="form_item">
                                                <FormItem
                                                    {...formItemLayout}
                                                    label="到达站"
                                                >
                                                    {getFieldDecorator('ddz', {
                                                        initialValue: dqddz,
                                                        rules: [
                                                            {
                                                                message:
                                                                    '请选择到达站'
                                                            }
                                                        ]
                                                    })(
                                                        editable ?

                                                            <Select>
                                                                {options}
                                                            </Select>

                                                            : <span>{dqddz}</span>
                                                    )}
                                                </FormItem>
                                            </div>
                                        }
                                        {((isShow || initPOvalue.sfasbd == '0' || initPOvalue.sfasbd == '1') && fasbdShow != '2') &&
                                            <div className="form_item">
                                                <FormItem
                                                    {...formItemLayout}
                                                    label='预计来校日期'
                                                >
                                                    {getFieldDecorator('dxsj', {
                                                        initialValue: initPOvalue.dxsj == '' ? null : moment(initPOvalue.dxsj, 'YYYY-MM-DD HH:mm'),
                                                        rules: [
                                                            {
                                                                required: !isHide,
                                                                message:
                                                                    '请选择日期'
                                                            }
                                                        ]
                                                    })(
                                                        editable ?
                                                            <DatePicker
                                                                style={{ 'width': '100%' }}
                                                                showTime
                                                                format="YYYY-MM-DD HH:mm"
                                                                disabledDate={this.disabledStartDate}
                                                            // defaultValue={initPOvalue.dxsj==''?null:moment(initPOvalue.dxsj, 'YYYY-MM-DD HH:mm')} //如果时间发生错误，请删除此句defaultValue

                                                            />
                                                            : <span>{initPOvalue.dxsj === 'Invalid date' ? '' : initPOvalue.dxsj}</span>
                                                    )}
                                                </FormItem>
                                            </div>
                                        }

                                        {!isHide && !zjxxShow &&
                                            <div className="form_item">
                                                <FormItem
                                                    {...formItemLayout}
                                                    label="班次"
                                                >
                                                    {getFieldDecorator('bc', { initialValue: initPOvalue.bc })(
                                                        editable ?
                                                            <Input

                                                            /> : <span>{initPOvalue.bc}</span>
                                                    )}
                                                </FormItem>
                                            </div>
                                        }
                                        {!isHide &&
                                            <div className="form_item">
                                                <FormItem
                                                    {...formItemLayout}
                                                    label="陪同人数"
                                                >
                                                    {getFieldDecorator('ptrs', { initialValue: initPOvalue.ptrs })(
                                                        editable ?
                                                            <InputNumber
                                                                precision={0}
                                                                min={0}
                                                                max={10}
                                                                onChange={this.numberChange}
                                                            /> : <span>{initPOvalue.ptrs}</span>
                                                    )}&nbsp;&nbsp;<span>  (人) </span>
                                                </FormItem>
                                            </div>
                                        }
                                    </Flex>
                                    <div className="text-center mt-50">
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            {editable ? '保存' : '修改'}
                                        </Button>
                                        <NextButton nextUrl={this.state.nextUrl} search={this.state.search} />
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </Flex.Item>
                </Flex>
                :
                <CommonTip hjmc={"报到情况填写"} key={'GD004_9'} mkbz='GD004_9' nonOperationalData={this.state.nonOperationalData} />}
            </div>
        );
    }
}
