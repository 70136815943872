import React from 'react';
import { Link } from 'react-router-dom';
import { Flex } from '@components';
import { Button, Table, Icon, InputNumber, Pagination, message, Modal } from 'antd';
import SiderMenu from '@pages/Assembly/siderMenu';
import Breadcrumb from '@pages/Assembly/Breadcrumb';
import TitleTop from '@pages/Assembly/titleTop';
import NextButton from '@pages/SelfHelp/common/nextIndex'
import './index.css';
import NHFetch from "@utils/NHFetch";
import { getLoginUser } from "@utils/NHCore";
import createHistory from 'history/createHashHistory';
import CommonTip from "../common";
import { mkbz } from "@pages/Assembly/siderMenu/menuObj";
import Hint from "@pages/SelfHelp/common/hint";
import QrCode from './qrCode';
import { handle } from '../pay/paydisc/index';
const history = createHistory();
export default class Pay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mkbz: 'GD008_1',
            hjmc: '',
            data: [],
            loading: false,
            pageData: {//分页设置的是三个参数
                total: 0,
                pageSize: 10,//当前页默认多少条数据
                page: 1
            },
            nextUrl: '',//左侧菜单加载完后传进来
            search: '',
            operable: true,//是否显示环节
            nonOperationalData: {},//不可操作的数据
            hjje: 0,                          //缓交金额
            jfxxHz: {},                        //缴费信息汇总
            visible: false,
            flage: true,                        //是否存在需要缴费的项目
            jffaData: {},       //缴费方案信息
            jccsData: {},      //基础参数信息
            djcsData: {},     //缴费对接参数信息
            xsxxData: {},     //学生信息
            buttonLoading: false,
        }
    }

    componentDidMount() {
        this.queryStudentInfo();
        this.queryPayPlan();
    }

    //查询学生信息
    queryStudentInfo = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (xsid) {
            NHFetch('/jfxt/queryStudentInfo', 'GET').then(res => {
                if (res && res.data) {
                    this.setState({
                        xsxxData: res.data
                    })
                } else {
                    message.error("获取学生信息失败");
                }
            })
        }
    }

    //根据环节代码获取对应的缴费方案数据
    queryPayPlan = () => {
        const { mkbz } = this.state;
        NHFetch('/jfxt/queryPayPlan', 'GET', { hjdm: mkbz }).then(res => {
            if (res && res.data) {
                this.setState({
                    jffaData: res.data['jffaVO'],
                    jccsData: res.data['jfdjJccsVO']
                }, () => {
                    this.queryQztjAndYlhj((operable) => { if (operable) { } });
                    this.queryPayParams()
                })
            } else {
                message.error("该环节未设置缴费方案")
            }
        })
    }

    //查询前置条件和依赖环节
    queryQztjAndYlhj = (callback) => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        if (!xsid) {
            this.setState({
                nonOperationalData: { nologin: true },
                operable: false,
            });
            return;
        }
        let zzbdhjid = this.getQueryString('pkid');
        NHFetch("/zzbd/queryQztjAndYlhj", 'GET', { xsid: xsid, zzbdhjid: zzbdhjid })
            .then(res => {
                if (res && res.data) {
                    let kfsj = res.data['kfsj'];
                    let ylhj = res.data['ylhj'];
                    let qzhj = res.data['qzhj'];
                    let nohj = res.data['nohj'];
                    this.setState({ nonOperationalData: res.data });
                    if (kfsj && kfsj['YXSJ'] != '1') {
                        this.setState({ operable: false });
                    } else if (ylhj && ylhj.length > 0) {
                        this.setState({ operable: false });
                    } else if (qzhj && qzhj.length > 0) {
                        this.setState({ operable: false });
                    } else if (nohj) {
                        this.setState({ operable: false });
                    } else {
                        this.checkDataSource();
                    } if (callback) {
                        callback(this.state.operable);
                    }
                }
            });
    }

    //校验数据来源
    checkDataSource = () => {
        const { jfqkly } = this.state.jccsData;
        if (jfqkly === '0') {//0:中间库，1：第三方接口
            this.queryPayListByDataBase();
        } else if (jfqkly === '1') {
            this.queryPayInfoList();
        }
    }

    //中间库
    queryPayListByDataBase = () => {
        const { lstdyx } = this.state.jffaData;
        NHFetch('/jfxt/queryPayListByDataBase', 'GET')//所有的收费数据
            .then(resData => {
                if (resData && resData.data) {
                    // 获取缴费数据，根据已有的规则判断是否符合自动办理
                    // 财务欠费公式：(应收billAmount - 减免derateAmount - 实收（累计）payedAmount + 退款（累计）refundAmount) > 0；
                    // 业务欠费公式：(应收billAmount - 减免derateAmount - 实收（累计）payedAmount - 缓交postponeAmount + 退款（累计）refundAmount) > 0。
                    // 学生每一项缴费项目，只要是达到财务欠费结果或者业务欠费结果=0，就说明学生没有欠费，自动办理缴费环节
                    let jfxxList = resData.data;
                    if (jfxxList.length > 0) {
                        NHFetch('/jfxt/queryStatusByStuId', 'GET', { lstdyx: lstdyx }).then(resData => {
                            if (resData && resData.data) {
                                if (resData.data) {
                                    this.checkIsZzbdHjLinked();
                                }
                            }
                        });
                    }
                    this.setState({ data: resData.data }, () => {
                        let list = this.state.data.filter((item) => item.qfje > 0 ? true : false);
                        if (resData.data.length === 0) {
                            this.setState({
                                flage: false
                            })
                        } else {
                            if (list.length <= 0) {
                                this.setState({ flage: false })
                            }
                        }

                    });
                }
            });
    }

    //第三方接口
    queryPayInfoList = () => {
        const { mkbz } = this.state;
        NHFetch('/jfxt/queryPayInfoList', 'GET', { hjdm: mkbz }).then(res => {
            if (res && res.data['sfxxList']) {
                if (res.data['sfbl']) {
                    this.checkIsZzbdHjLinked();
                }
                this.setState({ data: res.data['sfxxList'] }, () => {
                    let list = this.state.data.filter((item) => item.qfje > 0 ? true : false);
                    if (res.data['sfxxList'].length === 0) {
                        this.setState({
                            flage: false
                        })
                    } else {
                        if (list.length <= 0) {
                            this.setState({ flage: false })
                        }
                    }
                });
            } else {
                this.setState({ flage: false })
                message.info("查询不到学生的缴费信息，请联系管理员处理")
            }
        })
    }

    //检查该环节是否已经办理
    checkIsZzbdHjLinked = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : '';
        let zzbdhjid = this.getQueryString('pkid');

        NHFetch("/zzbdCommon/checkIsZzbdHjLinked", 'GET', { xsid: xsid, zzbdhjid: zzbdhjid })
            .then(res => {
                if (res && res.code === 200) {
                    if (!res.data) {
                        this.commonSaveLinkTransactionStatus();
                    }
                } else {
                    message.error("查询环节办理状态失败，请稍后再试");
                }
            });
    };

    //自动办理缴费环节
    commonSaveLinkTransactionStatus = () => {
        let xsid = getLoginUser() ? getLoginUser().userId : "";
        let zzbdhjid = this.getQueryString("pkid");
        let params = { xsid: xsid, zzbdhjid: zzbdhjid };
        // this.toPayView();
        NHFetch("/zzbdCommon/commonSaveLinkTransactionStatus", "POST", params).then(
            res => {
                if (res) {
                    if (res.data === 1) {
                        this.reloadMenu();
                    }
                } else {
                    message.error("自动办理缴费环节失败，请稍后重试！");
                }
            }
        );
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    //查询缴费对接参数
    queryPayParams = () => {
        const { djfsid } = this.state.jffaData;
        NHFetch('/jfxt/queryPayParams', 'GET', { djfsid: djfsid }).then(res => {
            if (res && res.data) {
                this.setState({
                    djcsData: res.data,
                })
            } else {
                message.error("获取缴费对接参数失败")
            }
        })
    }

    onHandleCancel = () => {
        this.setState({
            visible: false,
        }, () => {
            this.clearRedisCache();
        });
    };

    getQueryString = (name) => {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    }

    setMenuList = (menuList) => {
        let nextCount = 0;
        let bz = this.state.mkbz;
        menuList.map((elem, index) => {
            if (elem.HJDM == bz) {
                let hjmc = elem.HJMC;
                this.setState({ hjmc: hjmc })
                nextCount = index + 1;
                return;
            }
        });
        let menu = menuList[nextCount];
        if (menu) {
            let hjdm = menu.HJDM;
            if (hjdm.indexOf('GD') == 0) {
                let gdUrl = mkbz[hjdm];
                this.setState({
                    nextUrl: gdUrl,
                    search: 'pkid=' + menu.PKID,
                })
            } else if (hjdm.indexOf('ZDY') == 0) {
                let zdyUrl = "/customLink";
                this.setState({
                    nextUrl: zdyUrl,
                    search: 'pkid=' + menu.PKID + '&mkbz=' + hjdm + '&lx=' + menu.LX,
                })
            }
        } else {
            this.setState({
                nextUrl: '',
                search: '',
            })
        }
    }

    onSave = () => {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({
                loading: false
            });
        }, 1000);
    };

    //重新加载菜单
    reloadMenu = () => {
        this.refs.siderMenu.reloadMenuList()
    }

    //跳转到收费平台
    toPayView = () => {
        const { djcsData, xsxxData, jccsData } = this.state;
        const { djfsid } = this.state.jffaData;
        let csxx = Object.assign(jccsData, djcsData);
        this.setButtonLoading(true);
        handle(djfsid, "", csxx, xsxxData, "", "", "", "", "", (buttonLoading) => { this.setButtonLoading(buttonLoading) });
    };

    setButtonLoading = (buttonLoading) => {
        this.setState({
            buttonLoading
        })
        setTimeout(() => {
            this.setState({
                buttonLoading: false
            });
        }, 10000);
    }

    //根据缴费方案加载对应的二维码页面
    toShowModal = () => {
        const { jccsData, djcsData } = this.state;
        switch (jccsData.zffs) {
            case '1':
                return <QrCode jccsData={jccsData} />
                break;
            case '2':
                return null;
                break;
            default:
                return null;
                break;
        }
    }

    clearRedisCache = () => {
        NHFetch('/jfxt/clearRedisData', 'GET').then(res => {
            if (res && res.code == 200) {
                this.checkDataSource();
                this.onSave();
            } else {
                message.error("清除缓存失败")
            }
        })

    }

    render() {
        const { data, jccsData, buttonLoading } = this.state;
        const columns = [
            {
                title: '序号',
                dataIndex: 'XH',
                render: (text, record, index) => {
                    return index + 1;
                }
            },
            {
                title: '收费项目',
                dataIndex: 'sfxmmc',
            },
            {
                title: '应收金额（元）',
                dataIndex: 'ysje',
            },
            {
                title: '实收金额（元）',
                dataIndex: 'ssje',
                render: (text, record) => {
                    return <span className="text-primary">{text}</span>;
                }
            },
            {
                title: '减免金额（元）',
                dataIndex: 'jmje',
                render: (text, record) => {
                    return <span className="text-primary">{text}</span>;
                }
            },
            {
                title: '缓交金额（元）',
                dataIndex: 'hjje',
                render: (text, record) => {
                    return <span className="text-primary">{text}</span>;
                }
            },

            {
                title: '欠费金额（元）',
                dataIndex: 'qfje',
                render: (text, record) => {
                    if (text > 0) {
                        return <span className="text-info-red">{text}</span>
                    } else {
                        return text
                    }
                }
            }
        ];



        return (
            <div styleName="page_pay">
                {this.state.operable ?
                    <Flex className="w1180 mt-20" align="start">
                        <SiderMenu
                            ref={"siderMenu"}
                            setMenuList={this.setMenuList}
                            title="自助报到"
                            /*active={7}*/
                            mkbz={this.state.mkbz}
                            menuType='自助报到'
                        />
                        <Flex.Item className="page_con_r">
                            <Breadcrumb
                                noLine
                                list={[
                                    { name: '首页', url: '/index' },
                                    { name: '自助报到', url: '/register' },
                                    { name: this.state.hjmc }
                                ]}
                            />
                            <div className="box">
                                <Hint />
                                <TitleTop title={this.state.hjmc} extra={false} />
                                <div className="p-sw mt-20">
                                    <Table
                                        bordered
                                        size="middle"
                                        pagination={false}
                                        columns={columns}
                                        dataSource={data}
                                        loading={this.state.loading}
                                    />
                                    <div className="text-center mt-50">
                                        <div className="text-center mt-50">
                                            {this.state.flage == true && jccsData.zfd != '0' ?
                                                jccsData.zffs == '0' || jccsData.zffs == '3' ?
                                                    <Button loading={buttonLoading} onClick={() => this.toPayView()} type="primary" htmlType="submit">网上支付</Button>
                                                    :
                                                    <Button onClick={() => this.showModal()} type="primary" htmlType="submit">打开缴费二维码</Button>
                                                : null}
                                            <Button onClick={() => this.clearRedisCache()} type="primary" htmlType="submit" style={{ marginLeft: 10 }}>刷新</Button>
                                            <NextButton nextUrl={this.state.nextUrl} search={this.state.search} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Flex.Item>
                    </Flex>
                    : <CommonTip hjmc={"网上缴费"} key={'GD008_1'} mkbz='GD008_1' nonOperationalData={this.state.nonOperationalData} />}
                <Modal
                    title="二维码信息"
                    visible={this.state.visible}
                    onCancel={this.onHandleCancel}
                    footer={[
                        <Button onClick={this.onHandleCancel}>
                            关闭
                        </Button>,
                    ]}>
                    {this.toShowModal()}
                </Modal>
            </div>
        );
    }
}
