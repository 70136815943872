import React from 'react'
import { DatePicker, Table, Input, Icon, Form, message } from 'antd'
import moment from 'moment'
import TitleTop from '@pages/Assembly/titleTop'
import { createUuid } from '@utils/NHCore'
import './index.css'
import { FormRules, regPhone } from '@utils/RegExp'
import { deepCopy } from '@utils/deepCopy'
const { RangePicker } = DatePicker
const dateFormat = 'YYYY/MM/DD '
const FormItem = Form.Item
class JyjlForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      jyjl: false,
      jyjlAdd: false,
      jyjlData: [],
      errorFiled: false,
      flag: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    if ('jyjlInitData' in nextProps && nextProps.jyjlInitData) {
      if (nextProps.jyjlInitData instanceof Array) {
        let temp = [...nextProps.jyjlInitData]
        if (!this.state.flag && temp.length > 0)
          this.setState({ jyjlData: temp, flag: true })
      }
    }
  }

  cacheJyjlData = cb => {
    this.props.form.validateFields((err, values) => {
      const { jyjlData, errorFiled } = this.state
      if (err || errorFiled) return cb(false)
      //判断数据是否全部填写
      let dataList = []
      if (jyjlData && jyjlData.length > 0) {
        for (let i = 0; i < jyjlData.length; i++) {
          let data = jyjlData[i]
          if (
            !data.kssj &&
            !data.jssj &&
            !data.xxjl &&
            !data.zmr &&
            !data.zmrlxfs
          ) {
            return message.error(`请完善第${i + 1}行信息再保存`)
          } else {
            dataList.push(data)
          }
        }
      }
      console.log('测试:', dataList)
      const { cacheFormData, getChildComponent } = this.props
      getChildComponent(4, true)
      cacheFormData('jyjl', dataList, true)
      cb(true)
      this.setState({
        jyjl: false,
        jyjlAdd: false,
      })
    })
  }

  //   validateFields = func => {
  //     let err = false
  //     let formData = this.state.jyjlData
  //     func(err, formData)
  //   }

  onjyjlAdd = () => {
    let { jyjlAdd, jyjlData = [] } = this.state

    if (!jyjlAdd) return message.error('操作逻辑异常!')
    jyjlData.push({
      key: createUuid(),
      qsny: '',
      xxjl: '',
      zw: '',
      zmr: '',
      zmrlxfs: '',
      operation: '',
      errorFiled: false,
    })
    this.setState({
      jyjlData,
    })
  }

  //   onjyjlSave = () => {
  //     let { jyjlData } = this.state
  //     jyjlData.push()
  //     this.setState({
  //       jyjlData: jyjlData,
  //       jyjlAdd: false,
  //     })
  //   }

  onjyjlClose = (e, index) => {
    e.preventDefault()
    let { jyjlData } = this.state
    jyjlData.splice(index, 1)
    this.setState({
      jyjlData: jyjlData,
      jyjlAdd: true,
    })
  }

  handleChange = (value, index, key) => {
    const errorFiled = key === 'zmrlxfs' && !regPhone(value)
    let jyjlData = this.state.jyjlData
    jyjlData[index][key] = value
    jyjlData[index]['errorFiled'] = errorFiled
    this.setState({ jyjlData, errorFiled })
  }

  handleDateChange = (value, index) => {
    let jyjlData = this.state.jyjlData
    let kssj = value[0] ? moment(value[0]).format(dateFormat) : undefined
    let jssj = value[1] ? moment(value[1]).format(dateFormat) : undefined
    jyjlData[index]['kssj'] = kssj
    jyjlData[index]['jssj'] = jssj
    this.setState({ jyjlData })
  }

  disabledDate(current) {
    // Can not select days after today and today
    return current && current > moment().endOf('day')
  }

  //   deepCopy = obj => {
  //     if (typeof obj != 'object') {
  //       return obj
  //     }

  //     if (this.isArrayFn(obj)) {
  //       let newobj = []
  //       for (let i = 0; i < obj.length; i++) {
  //         newobj.push(this.deepCopy(obj[i]))
  //       }
  //       return newobj
  //     } else {
  //       let newobj = {}
  //       for (var attr in obj) {
  //         newobj[attr] = this.deepCopy(obj[attr])
  //       }
  //       return newobj
  //     }
  //   }

  //   isArrayFn = value => {
  //     if (typeof Array.isArray === 'function') {
  //       return Array.isArray(value)
  //     } else {
  //       return Object.prototype.toString.call(value) === '[object Array]'
  //     }
  //   }

  render() {
    const { getChildComponent, form } = this.props
    const { jyjl, jyjlData, jyjlAdd } = this.state
    const jyjlColumns = [
      {
        title: '起始年月',
        dataIndex: 'qsny',
        render: (text, record, index) => {
          let kssj = record.kssj
          let jssj = record.jssj
          return jyjl ? (
            <RangePicker
              value={
                kssj && jssj
                  ? [moment(kssj, dateFormat), moment(jssj, dateFormat)]
                  : undefined
              }
              format={dateFormat}
              onChange={value => {
                this.handleDateChange(value, index)
              }}
            />
          ) : (
            (kssj ? kssj : '') + ' ~ ' + (jssj ? jssj : '')
          )
        },
      },
      {
        title: '在何地何学校',
        dataIndex: 'xxjl',
        render: (text, record, index) => {
          return jyjl ? (
            <Input
              value={text}
              onChange={e => {
                this.handleChange(e.target.value, index, 'xxjl')
              }}
              style={{ width: 140 }}
              placeholder='请填写学校'
            />
          ) : (
            text
          )
        },
      },
      // {
      //     title: '任何职',
      //     dataIndex: 'zw',
      //     render: (text, record,index) => {
      //         return jyjl ? (
      //             <Input
      //                 value={text}
      //                 onChange={(e) =>{this.handleChange(e.target.value,index,'zw')}}
      //                 style={{ width: 100 }}
      //                 placeholder="请填写职位"
      //             />
      //         ) : (
      //             text
      //         );
      //     }
      // },
      {
        title: '证明人',
        dataIndex: 'zmr',
        render: (text, record, index) => {
          return jyjl ? (
            <Input
              value={text}
              onChange={e => {
                this.handleChange(e.target.value, index, 'zmr')
              }}
              style={{ width: 110 }}
              placeholder='请填写证明人'
            />
          ) : (
            text
          )
        },
      },
      {
        title: '证明人联系电话',
        dataIndex: 'zmrlxfs',
        render: (text, record, index) => {
          return jyjl ? (
            <FormItem
              form={form}
              style={{ marginBottom: '0px', width: '140px' }}
            >
              <Input
                value={text}
                onChange={e => {
                  this.handleChange(e.target.value, index, 'zmrlxfs')
                }}
                style={{ width: 110 }}
                placeholder='请填写电话'
              />
              <div
                style={{
                  display: record.errorFiled ? 'block' : 'none',
                  color: '#ff4d4f',
                }}
              >
                {text ? FormRules.MOBILE_PHONE_NUMBER.message : '请填写电话'}
              </div>
            </FormItem>
          ) : (
            text
          )
        },
      },
      {
        title: '删除',
        dataIndex: 'operation',
        width: 80,
        render: (text, record, index) => {
          if (jyjl) {
            return (
              <div className='editable-row-operations'>
                <a href='#' onClick={e => this.onjyjlClose(e, index)}>
                  <Icon type='close' style={{ color: 'red' }} />
                </a>
              </div>
            )
          }
          return (
            <a href='#' onClick={e => this.onjyjlClose(e, index)}>
              <Icon type='delete' style={{ color: 'red' }} />
            </a>
          )
        },
      },
    ]

    if (!jyjl) {
      jyjlColumns.pop()
    }
    return (
      <div>
        <TitleTop
          title='教育经历信息'
          onEdit={() => {
            this.setState({
              jyjl: true,
              jyjlAdd: true,
              cacheData: deepCopy(jyjlData),
            })
            getChildComponent(4, false)
          }}
          onSave={cb => {
            this.cacheJyjlData(cb)
          }}
          onClose={() => {
            this.setState({
              errorFiled: false,
              jyjl: false,
              jyjlAdd: false,
              jyjlData: deepCopy(this.state.cacheData),
            })
          }}
        />
        <div className='p-sw'>
          <Table
            bordered
            size='middle'
            pagination={false}
            dataSource={jyjlData}
            columns={jyjlColumns}
          />
          {jyjlAdd && (
            <div className='mt-10' styleName='btn_add' onClick={this.onjyjlAdd}>
              添加教育经历
            </div>
          )}
        </div>
      </div>
    )
  }
}
export default Form.create()(JyjlForm)
